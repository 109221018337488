import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import { FeatureListDotElement } from "./ProjectIndividualPage";

const SetupList = ({ setupList, textColor }) => {
  return (
    <div className="container-1440 container-content border-bottom-faded position-relative">
      <SetupListContainer>
        {setupList?.map((item, index) => (
          <List key={index}>
            <h3>{item.listTitle}</h3>
            <ListElements>
              {item.listItems.map((element, index) => (
                <FeatureListDotElement
                  key={index}
                  text={element}
                  textColor={textColor}
                />
              ))}
            </ListElements>
          </List>
        ))}
      </SetupListContainer>
    </div>
  );
};

export default SetupList;
const SetupListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 35px 50px;
  background: ${color.primaryDark};
  border-radius: 8px;

  row-gap: 50px;
  justify-content: space-between;

  @media (max-width: 990px) {
    padding: 35px 30px;
  }
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 245px;
  h3 {
    color: ${color.primaryLight};
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
  }
  @media (max-width: 425px) {
    min-width: unset;
  }
`;
const ListElements = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
