import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getPostsByPage } from "../API/Blog/getPostsByPage";
import BlogPost from "../components/Blog/BlogPost";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../assets/icons/searchIcon.svg";
import { ReactComponent as XIcon } from "../assets/icons/x-icon.svg";
import SpinnerComponent from "../components/Generic/SpinnerComponent";
import MetaTagsHandler from "../Helper/MetaTagHandler";
import AnimatedTextButton from "../components/Generic/AnimatedTextButton";
import TrackingComponent from "../components/Tracking/TrackingComponent";

import { color } from "../assets/styles/colors";

import "../assets/styles/style.css";

const SearchMenu = ({ value, setValue, isOpen, setIsOpen, setCategory }) => {
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setValue("");
    setIsOpen(false);
  };

  const handleOnChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <MenuWrapper>
      {!isOpen && (
        <SearchMenuButton onClick={() => handleOpen()}>
          <SearchIcon />
        </SearchMenuButton>
      )}
      {isOpen && (
        <Search>
          <SearchIcon />
          <input
            value={value}
            onChange={handleOnChange}
            placeholder="Search"
          ></input>

          <CloseButton onClick={() => handleClose()}>
            <XIcon />
          </CloseButton>
        </Search>
      )}
    </MenuWrapper>
  );
};

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [maxPage, setMaxPage] = useState(9999);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");

  const category = [
    {
      text: "all",
      value: "",
    },
    {
      text: "startups",
      value: "startups",
    },
    {
      text: "technology",
      value: "technology",
    },
    {
      text: "review",
      value: "review",
    },
  ];

  const navigate = useNavigate();

  const handleSetlectCategory = (category) => {
    setSelectedCategory(category);
    setPage(1);
  };

  const handleNavigateToPost = (id, data) => {
    navigate(`/blog/${id}`, { state: data });
  };

  const handleGetArticles = (page, term, category) => {
    let data = {};

    if (page) {
      data.page = page;
    }
    if (term) {
      data.term = term;
    }
    if (category) {
      data.category = category;
    }
    setIsLoading(true);
    getPostsByPage(
      data,
      function(response) {
        window.scrollTo(0, 0);
        handleResponse(response.data);
      },
      function(error) {
        console.log(error);
      }
    );
  };

  const handleResponse = (response) => {
    setArticles(response.posts);
    setMaxPage(response.total_pages);
    setIsLoading(false);
    setPage(response.page);
  };
  const handleNext = () => {
    window.scrollTo(0, 0);
    setPage(page + 1);
  };

  const handlePrev = () => {
    window.scrollTo(0, 0);
    setPage(page - 1);
  };

  useEffect(() => {
    handleGetArticles(page, inputValue, selectedCategory);
  }, [page, selectedCategory]);

  useEffect(() => {
    if (inputValue.length !== 0) {
      let timer = setTimeout(() => {
        handleGetArticles(page, inputValue, null);
      }, 200);
      return () => clearTimeout(timer);
    } else {
      handleGetArticles(page, inputValue, selectedCategory);
    }
  }, [inputValue]);

  return (
    <Wrapper>
      <MetaTagsHandler
        title={"appssemble's blog"}
        description={"appssemble's blog posts"}
        type={"website"}
        url={"https://appssemble.com"}
      />

      <div className="container-1440 container-content position-relative">
        <Content>
          <FirstLine>
            <div>
              <a href="/blog" className="transparent-button">
                <h1>Take our word for it.</h1>
                <p className="showOnBig">
                  Find the latest industry trends, new appssemble app launches
                  and information to keep you at the top of your tech game.
                </p>
              </a>
            </div>
          </FirstLine>

          <SearchContainer>
            <SearchWrapper>
              {!isOpen &&
                category.map((item, index) => (
                  <CategoryElement
                    key={index}
                    selected={item.value === selectedCategory}
                  >
                    <p onClick={(e) => handleSetlectCategory(item.value)}>
                      {item.text}
                    </p>
                  </CategoryElement>
                ))}

              <SearchMenu
                value={inputValue}
                setValue={setInputValue}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setCategory={handleSetlectCategory}
              />
            </SearchWrapper>
          </SearchContainer>

          <div className="border-bottom-faded">
            <PostsWrapper>
              {!isLoading ? (
                <>
                  {articles.length > 0 ? (
                    articles?.map((item, index) => (
                      <BlogPost
                        onClick={() => handleNavigateToPost(item.id, item)}
                        key={item.id}
                        title={item.title}
                        subtitle={item.subtitle}
                        img={item.cover}
                        category={item.category}
                        readingTime={item.reading_time}
                      />
                    ))
                  ) : (
                    <h1>No articles</h1>
                  )}
                </>
              ) : (
                <SpinnerComponent />
              )}
            </PostsWrapper>
          </div>
          <PaginationInfo>
            <AnimatedTextButton
              text={"Newer entries"}
              onClick={() => handlePrev()}
              disabled={page === 1}
              reverseArrow={true}
            />
            <p>{page}</p>
            <AnimatedTextButton
              text={"Older entries"}
              onClick={() => handleNext()}
              disabled={page === maxPage}
            />
          </PaginationInfo>
        </Content>
      </div>
      <TrackingComponent />
    </Wrapper>
  );
};

export default Blog;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 800px;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: 0px;
`;

const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  h1 {
    font-size: 72px;
    line-height: 80px;
    font-weight: 400;
    margin-bottom: 40px;
    color: ${color.primaryDark};
  }
  p {
    font-size: 20px;
    max-width: 80%;
    opacity: 0.6;
    color: ${color.primaryDark};
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 48px;
      line-height: 52px;
    }
  }
`;
const PostsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2.9%;
  align-items: start;
  margin-top: 70px;
  margin-bottom: 20px;
  min-height: 500px;
  @media (max-width: 1440px) {
    justify-content: center;
  }
  @media (max-width: 990px) {
    flex-direction: column;
    gap: 50px;
  }
  @media (max-width: 768px) {
    gap: 30px;
  }
`;
const SearchWrapper = styled.div`
  postition: relative;
  display: flex;
  align-items: center;
  column-gap: 30px;
  @media (max-width: 768px) {
    column-gap: 15px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  max-width: 800px;
  width: 100%;
  justify-content: end;
  overflow: hidden;
`;
const SearchMenuButton = styled.button`
  border: none;
  background: none;
`;
const CloseButton = styled.button`
  border: none;
  background: none;
`;
const Search = styled.div`
  display: flex;
  align-items: end;

  animation-name: search-menu-animation;
  animation-duration: 400ms;
  animation-transition: ease-in;
  animation-timing-function: ease-out;
  input {
    width: 100%;
    background-color: transparent;
    border: 0 solid transparent;
    border-radius: 0;
    margin-bottom: 0;
    padding-left: 30px;
    padding-right: 20px;
    font-size: 14px;
    line-height: 20px;
    &:focus {
      outline: none;
    }
  }
`;
const PaginationInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 70px;
  column-gap: 15px;
  justify-content: space-between;

  p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: ${color.primaryDark};
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    cursor: pointer;

    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    svg {
      stroke: ${color.primaryDark};
    }
    &:disabled {
      opacity: 0.2;
    }
  }
  @media (max-width: 768px) {
    p {
      margin: 0;
      font-size: 16px;
      line-height: 21px;
    }
  }
`;
const CategoryElement = styled.div`
  border-bottom: ${(props) => (props.selected ? "1px solid black" : "")};
  transitions: 400ms;
  cursor: pointer;

  :hover {
    margin-bottom: -1px;
    border-bottom: 1px solid black;
  }

  p {
    text-transform: capitalize;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${color.primaryDark};
  }
`;
