import styled from "styled-components";

const OurStats = () => {
  return (
    <Card>
      <Segment>
        <Element>
          <Top>
            <h2>$50</h2>
            <h3>M+</h3>
          </Top>
          <h4>In funding received by our customers</h4>
        </Element>
        <Element>
          <Top>
            <h2>100</h2>
            <h3>+</h3>
          </Top>
          <h4>Products developed</h4>
        </Element>
      </Segment>
      <Segment>
        <Element>
          <Top>
            <h2>4.5</h2>
            <h3>M</h3>
          </Top>
          <h4>People use products we’ve developed</h4>
        </Element>
        <Element>
          <Top style={{ columnGap: "5px" }}>
            <h2>2.5</h2> <h3>months</h3>
          </Top>
          <h4>Average delivery time for new projects</h4>
        </Element>
      </Segment>
    </Card>
  );
};

export default OurStats;
const Card = styled.div`
  border-radius: 8px;
  background: #606054;
  padding: 45px 50px;
  display: flex;
  gap: 40px;
  justify-content: center;
  h2 {
    color: white;
    font-size: 71px;
    font-weight: 400;
    margin: 0;
  }
  h3 {
    color: white;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  h4 {
    color: white;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.496px;
    text-align: center;
    margin: 0;
  }

  @media (max-width: 990px) {
    padding: 21px 30px;
    h2 {
      font-size: 40px;
    }
    h3 {
      font-size: 25px;
      margin-bottom: 5px;
    }

    h4 {
      opacity: 0.6;
      font-weight: 350;
      line-height: 25px;
    }
  }
  @media (max-width: 750px) {
    flex-wrap: wrap;
  }
  @media (max-width: 550px) {
    padding: 20px 20px;
  }
`;
const Segment = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 40px;
  width: 100%;
  @media (max-width: 990px) {
    align-items: start;
  }
  @media (max-width: 375px) {
    gap: 20px;
  }
`;
const Element = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  h4 {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 990px) {
    min-width: 135px;
    max-width: 165px;
    width: 100%;
    h4 {
      text-align: start;
      margin-left: unset;
    }
  }
  @media (max-width: 750px) {
    justify-content: end;
  }
`;
const Top = styled.div`
  display: flex;
  align-items: end;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 990px) {
    margin-left: unset;
  }
`;
