import { POST } from "../API";


export const getRandomPostsForPost = (
    id,
    onSuccess,
    onError
) => {
    const data = { id: id };
    POST(`/blog/random_posts_for_post`, data, onSuccess, onError);
};
