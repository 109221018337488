import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import RuleDropdown from "./RuleDropdown";
import RuleAsset from "../../assets/images/rules-asset.webp";
const RulesFold = () => {
  return (
    <Wrapper>
      <div className="container-1440 container-content border-bottom-faded position-relative">
        <Content>
          <h1>Rules</h1>

          <div className="border-bottom-faded">
            <Text>
              <LeftSide>
                <p>
                  Good technicians (developers, designers, testers) don't like
                  meetings, and wasting time, they want to focus on the thing
                  they are good at and to be able to hone their skills without
                  other distractions.
                </p>
                <ImgContainer>
                  <img src={RuleAsset} alt="rule-asset" />
                </ImgContainer>
              </LeftSide>
              <RighSide>
                <p>
                  The average developer spends a third of his time (roughly 11
                  hours/week) in meetings. Additionally, they spend around 6
                  hours in fragmented time (between meetings).
                  <br></br>
                  <br></br>
                  That means for a{" "}
                  <span>
                    40-hour week, they spend around 24 hours focusing on the
                    tasks at hand (3 days/week instead of 5).
                  </span>
                  <br></br>
                  <br></br>
                  We prefer asynchronous communication by default and escalate
                  to real-time only when necessary.
                  <br></br>
                  <br></br>
                  We've chosen to do better, we follow a set of rules that
                  ensures our colleagues are working on the things that matter
                  and they deliver better results, faster.
                </p>
              </RighSide>
            </Text>
          </div>

          <RulesWrapper>
            <RuleDropdown
              rule={"1. Uninterrupted time"}
              description={[
                <div className="rule-paragraph">
                  Whether you're writing an article, developing a new feature,
                  or designing something, you'll produce much better results if
                  you're interrupted.
                </div>,
                <div className="rule-paragraph">
                  Tech teams are bombarded with meetings, messages, and urgent
                  emails each day, this makes people lose focus, decrease
                  quality and productivity, and become more stressed. By
                  allowing people to work without distractions, they can manage
                  their time better, and be more effective in completing their
                  tasks. Additionally, they become more immersed in their work,
                  producing better results, by being more creative and
                  innovative.
                </div>,
                <div className="rule-paragraph">
                  In the vast majority of the days, our team is focused on
                  delivering results, and doing their best work, they are not
                  bombarded by chats, emails, and useless meetings.
                </div>,
                <div className="rule-paragraph">
                  They can focus on their tasks, without being interrupted. This
                  increases our team's satisfaction -- they can focus on the
                  things they like to do and are good at, and they can improve
                  their skills, and be the owners and managers of their time.
                  Additionally, this produces better products, better code, and
                  increases the delivery time.
                </div>,
              ]}
            />
            <RuleDropdown
              rule={"2. No meetings"}
              description={[
                <div className="rule-paragraph">
                  We try to have as few meetings as possible and use async
                  communication mechanisms, and writing (IM, confluence pages,
                  emails). Since we're working in small teams, the majority of
                  communication happens between members of the same team so 2 -
                  3 people at maximum.
                </div>,
                <div className="rule-paragraph">
                  By using the async communication mechanism we achieve 2
                  things:
                </div>,
                <div className="rule-ol-container">
                  <ol>
                    <li className="rule-li">
                      We have a <b>history of the decisions made</b>, an easy
                      way to check or refresh the information on a specific
                      subject. That makes it easier to grasp new concepts and
                      share knowledge and increases accountability.
                    </li>
                    <li className="rule-li">
                      <b>Ensure uninterrupted times</b> -- team members can
                      respond and be updated on their schedule. Instead of
                      losing focus, join a meeting (where very often there is no
                      input needed from everyone) and get back to the task (by
                      spending more brain power to get back in the context),
                      team members can respond when they've finished what
                      they're currently working on.
                    </li>
                  </ol>
                </div>,
                <div className="rule-paragraph">
                  We still have meetings in cases where an urgent problem needs
                  to be solved, when we need to urgently convey something to our
                  team, or when discussing new projects, but these are isolated
                  cases.
                </div>,
              ]}
            />
            <RuleDropdown
              rule={"3. Devs and designers write their tasks"}
              description={[
                <div className="rule-paragraph">
                  A lot of time is wasted when PMs have to think about all the
                  development tasks needed, they don't necessarily have the
                  technical knowledge to write everything down, additionally,
                  very frequently, not all tasks are known until the work has
                  started.
                </div>,
                <div className="rule-paragraph">
                  The person who works on a feature (more on that later) is the
                  best person to write the tasks needed to complete.
                </div>,
                <div className="rule-paragraph">
                  You can always improve the code and make it better and more
                  performant, this is a never-ending cycle. This is why it is
                  important for devs to write their tasks so that they complete
                  the feature in the given timeframe.
                </div>,
              ]}
            />

            <RuleDropdown
              rule={"4. Devs and designers test their feature"}
              description={[
                <div className="rule-paragraph">
                  If the designers and developers are testing their work, they
                  need a better understanding of the product, which means a
                  better technical solution.
                </div>,
                <div className="rule-paragraph">
                  A lot of time is wasted in the dev -> QA loop, and most
                  outsourcing companies are accustomed to developers writing
                  code, they push the changes, QA switches context, verifies the
                  task, writes the issue, which goes back to the developer, and
                  takes him out of its current context to fix it.
                </div>,
                <div className="rule-paragraph">
                  Additionally, who's the best person to test something? Most
                  probably the ones that build that specific feature.
                </div>,
                <div className="rule-paragraph">
                  This results in earlier feedback and validation, better code,
                  and increased delivery speed.
                </div>,
              ]}
            />

            <RuleDropdown
              rule={"5. Own the issue after we ship"}
              description={[
                <div className="rule-paragraph">
                  We clean our mess if we make one. Bug fixes are free for all
                  the stuff we develop. Once we've shipped a future, we own it,
                  meaning the developer who worked on the future is responsible
                  for fixing all the upcoming bugs.
                </div>,
                <div className="rule-paragraph">
                  Ideally, a feature should be delivered with no bugs, but since
                  they appear, the best person to fix those is the person who
                  introduced it.
                </div>,
                <div className="rule-paragraph">
                  If you did the work, you have the context, and you should put
                  it straight if it’s crooked. This ensures we're fixing the bug
                  faster, everyone is accountable for the code they write and
                  they take full responsibility for its good function.
                </div>,
              ]}
            />
            <RuleDropdown
              rule={"6. We only hire good people"}
              description={[
                <div className="rule-paragraph">
                  We don't micro-manage, and we only hire good people, people
                  who are passionate about their work, about their skills, who
                  treat people right, and who have no interest in office
                  politics, or bullshit jobs. They have a passion for delivering
                  exceptionally good products, products they would use.
                </div>,
                <div className="rule-paragraph">
                  We have no interest in hiring people to increase our headcount
                  and selling them as "heads" to our clients. We are strong
                  believers that to build great things, fast, you need people
                  who are passionate about their work and work in small, nimble
                  teams.
                </div>,
                <div className="rule-paragraph">
                  We're proud and stand behind the work we're doing, and we're
                  contrarians to the old software outsourcing models (where you
                  would sell people for hourly rates), We don't sell people, we
                  sell the digital products we build.
                </div>,
              ]}
            />
          </RulesWrapper>
        </Content>
      </div>
    </Wrapper>
  );
};

export default RulesFold;

const Wrapper = styled.div`
  background-color: white;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  h1 {
    font-size: 48px;
    font-weight: 400;
    letter-spacing: -0.5px;
    margin: 0;
  }

  .rule-li {
    margin-bottom: 18px;
    font-size: 18px;
  }

  .rule-paragraph {
    font-size: 18px;
    margin-bottom: 18px;
    width: 80%;
  }

  @media (max-width: 990px) {
    row-gap: 30px;
    h1 {
      font-size: 30px;
      line-height: normal;
    }
    
    .rule-paragraph {
      width: 100%;
    }
  }

  
`;
const Text = styled.div`
  display: flex;
  column-gap: 60px;
  margin-bottom: 60px;
  @media (max-width: 990px) {
    flex-direction: column;
    row-gap: 30px;
  }
`;
const RulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftSide = styled.div`
display:flex;
flex-direction:column;
row-gap:30px;

min-width: 400px ;
max-width: 400px ;

p{
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #1f1f1f;
}
span{
  background:${color.highlightedColor};
  line-height:36px;
}
@media(max-width:990px){
  min-width:unset;
  max-width:unset;
 flex-direction:column;
 row-gap:30px;
  p{
      font-size: 18px
      line-height:27px;

  }
  span{
      line-height:27px;
  }


}

`;
const RighSide = styled.div`display:flex;
flex-direction:column;
row-gap:30px;

p{
  margin:0;
  color:#1F1F1F;
  font-size:20px;
  line-height:30px;
  font-weight:400;
}
span{
  background:${color.highlightedColor};
  line-height:36px;
  padding-left: 5px;
  padding-right: 5px;
}
@media(max-width:990px){
 flex-direction:column;
 row-gap:30px;
  p{
      font-size: 18px
      line-height:27px;

  }
  span{
      line-height:27px;
  }
}
`;
const ImgContainer = styled.div`
  display: flex;
  position: relative;
  object-fit: contain;
  margin-top: 40px;
  img {
    position: relative;
    object-fit: contain;
    max-width: 250px;
  }
`;
