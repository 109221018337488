import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/icons/appssemble-logo.svg";
import { ReactComponent as LogoWhite } from "../../assets/icons/appssemble-logo-white.svg";
import NavBarButton from "./NavBarButton";
import "../../assets/styles/style.css";
import MobileMenu from "./MobileMenu";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
export const links = [
  {
    text: "Our work",
    to: "/projects",
    type: "",
    highlighted: false,
  },
  {
    text: "Services",
    to: "/services",
    type: "",
    highlighted: false,
  },
  {
    text: "Approach",
    to: "/approach",
    type: "",
    highlighted: false,
  },
  {
    text: "Process",
    to: "/process",
    type: "",
    highlighted: false,
  },
  {
    text: "About",
    to: "/about",
    type: "",
    highlighted: false,
  },
  {
    text: "Blog",
    to: "/blog",
    type: "",
    highlighted: false,
  },
  {
    text: "Contact",
    to: `/contact`,
    type: "",
    highlighted: true,
  },
];

const NavBar = () => {
  const navigate = useNavigate();
  const [logo, setLogo] = useState(<Logo />)


  useState(() => {

    const handleScroll = (event) => {
      if (window.location.pathname === "/about") {
        $("#nav-bar").toggleClass("translucent-nav-bar", window.scrollY > 550);
        if (window.scrollY > 550) {
          setLogo(<Logo />)
        }
        else {
          setLogo(<LogoWhite />)
        }
      } else {
        setLogo(<Logo />)
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });


  useState(() => {
    if (window.location.pathname === "/about") {
      setLogo(<LogoWhite />)
    } else {
      setLogo(<Logo />)
    }

  }, [window.location.pathname])



  return (
    <StickyWrapper
      id="nav-bar"
      className={window.location.pathname === "/about" ? "green-nav-bar" : ""}
    >
      <div className="container-1440">
        <NavBarWrapper>
          <SiteLogo onClick={() => navigate("/")}>
            {logo}
            <h2>appssemble</h2>
          </SiteLogo>
          <LinkWrapper className="showOnBig">
            {links.map((link, index) => (
              <NavBarButton
                key={index}
                to={link.to}
                text={link.text}
                highlighted={link.highlighted}
              />
            ))}
          </LinkWrapper>
          <div className="showOnSmall">
            <MobileMenu />
          </div>
        </NavBarWrapper>
      </div>
    </StickyWrapper>
  );
};

export default NavBar;
const NavBarWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  margin-left: auto !important;
  margin-right: auto;
`;
const StickyWrapper = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  height: 70px;
  width: 100%;
  z-index: 900;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-bottom: 1px solid #3131310d;
  transition: 400ms;
`;
const SiteLogo = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  h2 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
  }
 
`;
const LinkWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;
