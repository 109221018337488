import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import LandingPage from "../pages/LandingPage";
import Blog from "../pages/BlogPage";
import ServicesPage from "../pages/ServicesPage";
import AboutUsPage from "../pages/AboutUsPage";
import BlogPostReader from "../components/Blog/BlogPostReader";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import CookiePolicyPage from "../pages/CookiePolicyPage";
import ProcessPage from "../pages/ProcessPage";
import ProcessPageNew from "../components/ProcessPageComponents/ProcessPageNew";
import ProcessPageOngoing from "../components/ProcessPageComponents/ProcessPageOngoing";
import ProcessPageExisting from "../components/ProcessPageComponents/ProcessPageExisting";
import ApproachPage from "../pages/ApproachPage";
import JobsPage from "../pages/JobsPage";
import ProjectsPage from "../pages/ProjectsPage";
import ProjectIndividualPage from "../components/Projects/ProjectIndividualPage";
import ContactPage from "../pages/ContactPage";
import CheckIndexedLinks from "../Helper/CheckIndexedLinks";

export const router = createBrowserRouter([
  {
    index: "",
    element: <App />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/services",
        element: <ServicesPage />,
      },
      {
        path: "/approach",
        element: <ApproachPage />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog/:id",
        element: <BlogPostReader />,
      },
      {
        path: "/about",
        element: <AboutUsPage />,
      },
      {
        path: "/process",
        element: <ProcessPage />,
        children: [
          {
            path: "/process",
            element: <ProcessPageNew />,
          },
          {
            path: "/process/existing",
            element: <ProcessPageExisting />,
          },
          {
            path: "/process/ongoing",
            element: <ProcessPageOngoing />,
          },
        ],
      },
      {
        path: "/jobs",
        element: <JobsPage />,
      },
      {
        path: "/projects",
        element: <ProjectsPage />,
      },
      {
        path: "/projects/:id",
        element: <ProjectIndividualPage />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "cookie-policy",
        element: <CookiePolicyPage />,
      },
    ],
  },
  {
    path: "*",
    element: <CheckIndexedLinks />
  }
]);
