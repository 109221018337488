import React from "react";
import styled from "styled-components";
import { ReactComponent as AppssembleLogo } from "../../assets/icons/appssemble-logo-big.svg";
import { ReactComponent as AppssembleLogoBg } from "../../assets/icons/logo-background.svg";
import AnimatedTextButton from "../Generic/AnimatedTextButton";
import { useNavigate } from "react-router-dom";

const ProcessFold1 = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Content>
        <div className="row">
          <div className="col-sm-12 col-md-8">
            <Text>
              <h1>A process that helps companies deliver on time.</h1>
              <p>
                Our process is tailored to help our customers focus on the
                things that truly matter - delivering good products to their
                customers. We cut all unnecessary distractions, and focus on our
                specialty, building digital solutions.
              </p>
            </Text>
            <div>
              <AnimatedTextButton
                onClick={(e) => navigate("/approach")}
                text="Read more about our approach"
              />
            </div>
          </div>
          <LogoContainerBig className="showOnBig col">
            <div className="col d-flex justify-content-center ">
              <AppssembleLogo />
            </div>
          </LogoContainerBig>
          <div className="showOnSmall">
            <SmallScreenBg>
              <AppssembleLogoBg />
            </SmallScreenBg>
          </div>
        </div>
      </Content>
    </Wrapper>
  );
};

export default ProcessFold1;

const Wrapper = styled.div`
  background: white;
  margin-bottom: 70px;
  @media(max-width:990px){
    margin-bottom:0;
  }
`;
const Content = styled.div`
  min-height: 250px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  row-gap: 50px;

  z-index: 10;

  h1 {
    margin: 0;
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    color: #1f1f1f;
  }
  p {
    margin: 0;
    color: rgba(31, 31, 31, 0.9);
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
  }

  @media (max-width: 990px) {
    max-width: unset;
    row-gap: 30px;
    h1 {
      font-size: 30px;
      line-height: 39px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

const SmallScreenBg = styled.div`
  position: absolute;
  right: 30px;
  top: 50px;
  bottom: 0;
  opacity: 0.2;
  magrgin-left: auto;
  max-height: 300px;
`;
const LogoContainerBig = styled.div`
  display: flex;
`;
