import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Dropdown } from "../../assets/icons/down-arrow-rules-dropdown.svg";
import { ReactComponent as SquarePoint } from "../../assets/icons/rectangle-green.svg";
import { color } from "../../assets/styles/colors";
import CareerAsset from "../../assets/images/career-asset.webp";

const SquareElement = ({ element }) => {
  return (
    <SquareElementWrapper>
      <SquarePoint /> <p>{element}</p>
    </SquareElementWrapper>
  );
};
const PositionElement = ({ position }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PositionWrapper>
      <Header onClick={() => handleOpen()}>
        <Info>
          <h1>{position.position}</h1>
          <p>
            {position.location} / {position.workTime}
          </p>
        </Info>
        <DropdownBtn>
          <Dropdown
            style={
              isOpen
                ? { transform: "scaleY(-1)", transition: "200ms ease" }
                : { transition: "200ms ease" }
            }
          />
        </DropdownBtn>
      </Header>
      {isOpen && (
        <ContentWrapper>
          <RulesWrapper>
            <h1>Required skills</h1>
            {position.requiredSkills.map((skill, index) => (
              <SquareElement key={index} element={skill} />
            ))}
          </RulesWrapper>

          <RulesWrapper>
            <h1>What you'll do?</h1>
            {position.do.map((skill, index) => (
              <SquareElement key={index} element={skill} />
            ))}
          </RulesWrapper>
          <ApplyButton>
            <a href="mailto: office@appssemble.com">Apply</a>
          </ApplyButton>
        </ContentWrapper>
      )}
    </PositionWrapper>
  );
};

const CareersOpenPosition = () => {
  const openPosition = [
    {
      position: "Mid/Senior Android Developer",
      location: "Cluj-Napoca (Remote)",
      workTime: "Full time",
      requiredSkills: [
        "Experience in building Android apps using Kotlin",
        "Understanding of Android development concepts (Architecture Components, LiveData, MVVM, Coroutines, Retrofit, etc.)",
        "Empathetic to the end-user experience",
        "Able to determine when to deliver architectural masterpieces versus quick ship solutions",
        "Experience working in an Agile/Scrum development process",
        "The ability to interpret and follow technical plans.",
      ],
      do: [
        "Develop native Android apps using Kotlin / Java",
        "Participate in project estimates and new project setups",
        "Optimize workflows and development tactics by implementing libraries and other assets",
        "Take responsibility for the efficiency and quality of the developed apps",
        "Keep up to date with new developments in the frameworks and technologies",
        "Perform code reviews for other team members",
      ],
    },
    {
      position: "UI/UX Designer",
      location: "Cluj-Napoca (Remote)",
      workTime: "Full time",
      requiredSkills: [
        "Design tools (Adobe Creative Suite, Sketch, InVision)",
        "Prototyping (eq. Framer, ProtoPie.io, Flinto)",
        "Comfortable using Atlassian tools (Jira, Confluence etc.)",
        "Experience delivering assets and designs directly to developers",
        "Have a proven track record of designing beautiful UIs that are thoroughly thought through, from empty states to iconography",
        "Have general knowledge of Abstract, Plant and/or other file version management",
        "Have worked in a start-up environment before",
      ],
      do: [
        "Create user personas and user journeys",
        "Organize content and features for intuitive navigation",
        "Design the visual elements of the user interface",
        "Conduct usability tests to gather user feedback.",
        "Build interactive prototypes to demonstrate design concepts.",
        "Stay up-to-date with industry trends and best practices in UX/UI design.",
        "Create design documentation and guidelines for developers.",
      ],
    },
  ];
  return (
    <Wrapper>
      <div className="container-1440 container-content border-bottom-faded position-relative">
        <Content>
          <TopCards>
            <Card>
              <h1>
                Empowering <span>Digital </span> <br></br>
                Experiences Together
              </h1>
            </Card>
            <Card>
              <img src={CareerAsset} alt="careers" />
            </Card>
          </TopCards>
          <h1>Open Positions</h1>
          <OpenPositionsWrapper>
            {openPosition.map((position, index) => (
              <PositionElement key={index} position={position} />
            ))}
          </OpenPositionsWrapper>

          <h2>
            Not seeing what you are looking for? We are always looking for
            awe-inspiring people, drop us a line{" "}
            <a href="mailto:office@appssemble.com">
              <b>office@appssemble.com</b>
            </a>
          </h2>
        </Content>
      </div>
    </Wrapper>
  );
};

export default CareersOpenPosition;

const Wrapper = styled.div`
  background: white;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 62px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  h2 {
    margin: 60px 0 0 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;

    a {
      text-decoration: none;
    }
  }
`;
const PositionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  padding: 30px 0;
  border-bottom: 1px solid #3131310d;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
    margin: 0;
  }
  p {
    font-size: 18px;
    font-weight: 350;
    line-height: 20px;
    margin: 0;
  }
`;
const DropdownBtn = styled.button`
  border: none;
  background: none;

  svg {
    transition: 200ms ease;
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
`;
const RulesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
    margin: 0 0 20px 0;
  }
`;
const SquareElementWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  svg {
    margin-top: 2px;
    height: 12px;
    width: 12px;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin: 0;
  }
`;
const ApplyButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 225px;
  border-radius: 4px;
  margin: none;
  background: #000000;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: ${color.primaryLight};
  a {
    color: ${color.primaryLight};
    text-decoration: none;
  }
`;
const OpenPositionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopCards = styled.div`
  display: flex;
  gap: 30px;

  justify-content: center;
  @media (max-width: 990px) {
    flex-wrap: wrap;
  }
`;
const Card = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #12251a;
  min-height: 316px;
  max-width: 586px;
  width: 100%;
  border-radius: 8px;
  h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: 62px;
    letter-spacing: -0.5px;
    text-align: left;
    color: ${color.primaryLight};
  }
  span {
    color: ${color.highlightedColor};
  }
  img {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;
