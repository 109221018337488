import React from "react";
import styled from "styled-components";
import WhySmallTeamsCard from "./WhySmallTeamsCard";

export const WorkPageFold3 = () => {
  return (
    <Wrapper>
      <div className="container-1440 container-content border-bottom-faded position-relative">
        <Content>
          <Cycles>
            <h1>Small teams</h1>
            <h2>
              You can build big things with small teams, but you can not move
              fast and build small things with big teams.
            </h2>
          </Cycles>
          <Text>
            <p>
              We work with small teams that focus on features and contain all
              the required knowledge in the team to implement that feature
              successfully.
              <br></br>
              <br></br>
              Instead of dividing our departments into technology, we prefer to
              divide our staff into teams that have all the knowledge needed to
              implement a certain feature (all the layers involved – design,
              frontend, backend). This ensures a higher cohesion on the team and
              allows us to build better products, faster.
            </p>
          </Text>
        </Content>
        <div className="small-teams">
          <WhySmallTeamsCard />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: white;

  span {
    background: #e4f222;
    padding-left: 5px;
    padding-right: 5px;
  }

  .small-teams {
    margin-top: 100px;
  }
`;
const Content = styled.div`
  display: flex;
  column-gap: 60px;
  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #1f1f1f;
  }
  h2 {
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #1f1f1f;
  }
  p {
    text-wrap: wrap;
    margin: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: #1f1f1f;
  }
  @media (max-width: 990px) {
    flex-direction: column;
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 18px;
    }
    p {
      margin-top: 30px;
      font-size: 18px;
    }
  }
`;
const Cycles = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  row-gap: 40px;

  @media (max-width: 990px) {
    min-width: unset;
    row-gap: 30px;
  }
`;
const Text = styled.div``;
