import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import CardWithSquareDots from "./CardWithSquareDots";
import ImplementationGraph from "../../assets/images/implementation-illustration.webp";
import AnimatedTextButton from "../Generic/AnimatedTextButton";
import ImplementationGraphMobile from "../../assets/images/implementation-ilustration-mobile.webp";
import { useNavigate } from "react-router-dom";
const ProcessExistingImplementation = () => {
  const handleGetInTouchButton = () => { };

  const DesignOutcome = [
    {
      title: "Outcome",
      items: [
        "Feature implementation (code, documentation & testing)",
        "Testing plan",
        "Product (web / mobile / backend) functionality",
      ],
    },
  ];
  const ClientOutcome = [
    {
      title: "Outcome",
      items: ["SoW Sign Off"],
    },
  ];

  const navigate = useNavigate();

  return (
    <Content className="border-bottom-faded">
      <Title>
        <h1>3. Implementation</h1>
      </Title>
      <div className="showOnSmall">
        <GraphContainerSmall>
          <img src={ImplementationGraphMobile} alt="implementation-graph" />
        </GraphContainerSmall>
      </div>
      <TextWrapper>
        <MaxSizeP>
          We split the entire work into features, and each feature will be
          assigned to a team. Depending on the feature, a team can have 2 or 3
          members, and teams can have one or more features to deliver during a
          statement of work.
        </MaxSizeP>
      </TextWrapper>
      <Section className="row">
        <LeftSide className="col">
          <CardsGroup>
            <HighlightedTextCard>
              <TextWrapper>
                <h3>Design & Development</h3>
                <h4>
                  A team can have one or more assigned features they need to
                  complete. Since our teams contain all the required layers for
                  implementing the feature, they can decide and sync internally,
                  what the best approach to implement it, and how to schedule
                  them. In cases where input is needed from the client (design
                  validation for instance), this is brought up with the project
                  manager who's responsible for the product and solved with the
                  client's team.
                  <br></br> <br></br>
                  After the features are assigned to a team, they are fully
                  autonomous, they decide what to work on, and in what order, so
                  that the solution is delivered on time.
                  <br></br> <br></br>
                  The team takes full responsibility for the code and features
                  they deliver, meaning they write the code and the
                  documentation, and test the features themselves (both unit
                  tests and manual testing).
                  <br></br> <br></br>
                  Once a feature is completed our QA team validates again the
                  features and highlights any issues, before presenting it to
                  the client. QA is for edge cases, designers and programmers
                  take responsibility for the basic quality of their work.
                </h4>
              </TextWrapper>
            </HighlightedTextCard>
            <CardWithSquareDots
              data={DesignOutcome}
              cardSstyle={{}}
              itemsStyle={{ flexDirection: "column" }}
            />
          </CardsGroup>

          <div className="showOnBig">
            <ButtonWrapper style={{ marginTop: "-30px" }}>
              <AnimatedTextButton
                text={"Read more about our approach"}
                onClick={() => navigate("/approach")}
              />
            </ButtonWrapper>
          </div>
          <CardsGroup>
            <HighlightedTextCard>
              <TextWrapper>
                <h3>Client validation</h3>
                <h4>
                  Once the work for a SoW is finished, our team will do a
                  showcase to demonstrate what was implemented and how it
                  functions.
                  <br></br>
                  <br></br>
                  If needed, the client can provide feedback and request
                  changes. Once the work has been validated, the SoW is signed
                  off and the next SoW begins, until the project is completed.
                </h4>
              </TextWrapper>
            </HighlightedTextCard>
            <CardWithSquareDots data={ClientOutcome} />
          </CardsGroup>
        </LeftSide>

        <RightSide className="col showOnBig">
          <GraphContainer>
            <img src={ImplementationGraph} alt="implementation-graph" />
          </GraphContainer>
        </RightSide>
      </Section>

      {window.location.pathname === "/process/ongoing" && (
        <GetInTouchButton onClick={(e) => handleGetInTouchButton()}>
          Get in touch
        </GetInTouchButton>
      )}
    </Content>
  );
};

export default ProcessExistingImplementation;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  padding: 60px 0;
  height: fit-content;
  postiton: relative;
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  h1 {
    color: ${color.primaryDark};
    font-size: 48px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    color: ${color.primaryDark};
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    margin: 0;
  }
  @media (max-width: 990px) {
    row-gap: 20px;
    h1 {
      font-size: 30px;
      line-height: 33px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  min-width: 400px;
  @media (max-width: 650px) {
    min-width: unset;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    color: ${color.primaryDark};
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    margin: 0;
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
  }

  h3 {
    margin: 0 0 20px 0;
    color: ${color.primaryDark};
    font-size: 30px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.5px;
  }

  h4 {
    color: ${color.primaryDark};
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }
  @media (max-width: 990px) {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
    h3 {
      font-size: 30px;
      line-height: 27px;
    }
    h4 {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
const RightSide = styled.div`
  display: flex;
  width: 100%;
  min-width: 400px;
  justify-content: end;
  row-gap: 30px;
  min-width: 400px;

  @media (max-width: 916px) {
    margin-top: 30px;
  }
  @media (max-width: 375px) {
    min-width: unset;
  }
  @media (max-width: 990px) {
    justify-content: center;
  }
`;
const GraphContainer = styled.div`
  display: flex;
  justify-content: end;
     
  img {
    max-height: 1390px;
    height: 100%;

    min-height:95%;
  }
`;
const GraphContainerSmall = styled(GraphContainer)`
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: 30px;
  margin-right: 30px;
  img {
    height: unset;
    width: 100%;
  }
`;
const HighlightedTextCard = styled.div`
  background: #efefeb;
  padding: 35px;
  border-radius: 8px;

  @media (max-width: 990px) {
    padding: 30px 20px;
    min-height: 0px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: ${color.primaryDark};
    margin: 0;
  }
`;
const CardsGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;
const GetInTouchButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 11px 0;
  background: #e4f222;
  color: ${color.primaryDark};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;
  height: 100%;
  max-width: 225px;
  max-height: 46px;
  border: none;
`;

const MaxSizeP = styled.p`
  max-width: 900px !important;
`;
