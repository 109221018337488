import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import Dragos from "../../assets/images/dragos.webp";

const AboutUsFold2 = () => {
  return (
    <Wrapper>
      <div className="container-1440 container-content  position-relative">
        <Content>
          <p>Hey there -</p>
          <p>
            I'm Dragos Dobrean, one of the founders. I've been running
            appssemble since its beginning in 2016.
          </p>
          <h3>A little bit of history</h3>
          <p>
            We started as a mobile development agency. Initially, we worked as
            contractors and on team augmentation agreements on big projects then
            we moved into developing full products (web and mobile) for
            startups.{" "}
            <span>
              We're currently focusing on scale-ups and small and medium
              companies.
            </span>
          </p>
          <p>
            During this time, we've become contrarian to the way software is
            developed and how software outsourcing is done.
          </p>
          <p>
            <b>
              Software development agencies that work on a time and resources
              approach (price/hour/developer) or sell headcounts work don't have
              the client's interests at heart and are especially bad for small
              and medium-sized companies.
            </b>
          </p>
          <p>
            Those types of companies make money by selling time and resources,
            it is in their interest to increase the headcount, deliver slower,
            and create overly complex systems so they can justify the headcount
            increase and increase the workload. The team management is passed to
            the client, so they are in no way accountable for the success of the
            project.
          </p>
          <p>
            Additionally, it is also bad for the people they hire, talent is
            wasted, lots of developers sit "on the bench" waiting for the next
            project, and team morale and especially accountability are low.
          </p>
          <h3>Where are we at?</h3>
          <p>
            <b>
              Seeing all this, and experiencing it firsthand, we've decided to
              do better.
            </b>
          </p>
          <p>
            Instead of selling headcounts and hours, we've decided to sell
            digital solutions and{" "}
            <span>take full responsibility for everything we deliver.</span> We
            focus on creating digital solutions that are resilient, scalable,
            and as simple as possible (technically), we don't add complexity for
            the sake of it (the best code written is no code at all).
          </p>
          <h3>We're a small team that delivers good work, fast. </h3>

          <p>
            We're not super-humans nor do we work long hours or weekends, we're
            simply good at what we do and follow a set of rules:
          </p>

          <p>
            <ul>
              <li> Uninterrupted time</li>
              <li> No meetings (almost)</li>
              <li> Own the solution after we ship</li>
              <li> Developers test their code </li>
              <li>Developers / Designers / QAs write their tasks</li>
              <li> We only hire good people</li>
            </ul>
          </p>

          <p>
            We have almost no meetings, we work in small, self-sufficient teams
            that contain all the required layers for implementing a certain
            feature or solution (design, frontend, backend), and we are
            accountable for everything we develop.{" "}
            <span>
              If we mess up, we own our mistake and we fix it, as it should be.
            </span>
          </p>

          <p>
            By transferring the accountability for the solution from the
            customer to our team, we need to create technically sound products
            and increase our expertise.
          </p>

          <p>
            Working on small teams creates better cohesion between our team
            members, improves communication, increases accountability, and helps
            us deliver software much faster than our competitors. Our rules and
            slimmed-down processes allow us to focus on the things that truly
            matter and that we love to do -- building good digital products.
          </p>

          <p>
            Since we're not making money by selling headcounts, our goal is not
            to increase the team size. We don't micro-manage, and we only hire
            good people, people who are passionate about their work, about their
            skills, who treat people right, and who have no interest in office
            politics, or bullshit jobs. They have a passion for delivering
            exceptionally good products, products they would use.
          </p>
          <p>
            <b>
              This allows us to deliver solutions that are in sync with our
              customer's needs and the product roadmap.
            </b>
          </p>
          <p>
            Our services and ways of working are not suited for everyone. If
            you're looking for a team that delivers good digital solutions fast
            and takes full responsibility for what they deliver, we'd love to
            hear from you.
          </p>
          <p>
            Thanks for reading! You can always contact me directly at{" "}
            <a href="mailto:dragos@appssemble.com">dragos@appssemble.com</a> if
            you have any questions. I look forward to hearing from you.
          </p>

          <Contact>
            <div className="image-container">
              <img src={Dragos} alt="Dragos"></img>
            </div>
            <div className="name-container">
              <p>
                <a href="http://linkedin.com/in/dobreandl/">
                  <b>Dragos Dobrean</b>
                </a>
              </p>
              <p>Co-founder & CEO</p>
            </div>
          </Contact>
        </Content>
      </div>
    </Wrapper>
  );
};

export default AboutUsFold2;
const Wrapper = styled.div``;
const Contact = styled.div`
  margin-top: 70px;

  img {
    width: 100px;
    height: 100px;
  }

  .image-container {
    float: left;
    padding-right: 30px;
  }
  .name-container {
    margin: auto;
    padding-top: 15px;
  }
`;
const Content = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 80px 80px;
  span {
    line-height: 40px;
  }
  b {
    font-weight: 700;
  }

  h3 {
    font-size: 26px;
    line-height: 39px;
    font-weight: 700;
    margin-top: 26px;
  }

  p {
    color: ${color.primaryDark};
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    font-weight: 400;
  }
  li {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    font-weight: 400;
  }

  @media (max-width: 990px) {
    row-gap: 20px;
    padding: 20px;
    p {
      font-size: 18px;
      line-height: 27px;
    }
    li {
      font-size: 18px;
      line-height: 27px;
    }
    span {
      line-height: 27px;
    }
  }
`;
