import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import CardWithSquareDots from "./CardWithSquareDots";
import discoveryWorkshop from "../../assets/images/discovery-workshop.webp";
import solutinDiagram from "../../assets/images/solution-overview-illustration.webp";
const ProcessPageNewDiscovery = () => {


  const OutcomeCard = [
    {
      title: "Outcome",
      items: ["Product Specifications", "Constraints"],
    },
  ];
  const PeopleInvitedCard = [
    {
      title: "People involved",
      items: ["Product Manager", "UX/UI Designer", "Software Architect"],
    },
  ];
  const Outcome1b = [
    {
      title: "Outcome",
      items: ["Solution overview document"],
    },
  ];

  return (
    <Content className="border-bottom-faded">
      <Title>
        <h1>1. Discovery</h1>
        <div className="showOnSmall">
          <RightSide>
            <ImgWrapperSmall>
              <img src={discoveryWorkshop} alt="discovery-workshop" />
            </ImgWrapperSmall>
          </RightSide>

        </div>
        <p>
          The first phase in our process is the discovery phase, this is needed
          for understanding what needs to be done, and what's the scope of the
          project.
        </p>
      </Title>

      <Section className="row">
        <LeftSide className="col">
          <TextWrapper>
            <h2>1.a Scoping</h2>
            <br></br>
            <p>
              We spend a few days with the client to understand what needs to be
              done, what are his goals, and pain points, what are they expecting
              to achieve with the digital solution and what are their
              constraints (time, budget, technology, scalability, etc.).
              <br></br>
              <br></br>
              The purpose of this is to collect all the required information for
              assessing the feasibility of the solution. Team members from all
              layers of product development are present (product, design,
              development).
            </p>
          </TextWrapper>
          <CardWrapper>
            <CardWithSquareDots data={OutcomeCard} />
          </CardWrapper>
        </LeftSide>

        <RightSide className="col">
          <div className="showOnBig" >
            <ImgWrapper>
              <img src={discoveryWorkshop} alt="discovery-workshop" />
            </ImgWrapper>
          </div>
        </RightSide>
      </Section>

      <Section className="row">
        <LeftSide className="col">
          <TextWrapper>
            <h2>1.b Solution Overview</h2>
            <br></br>
            <p>
              Once the scoping is completed, we process the information and
              build a solution overview document.
            </p>

            <div className="showOnSmall">
              <RightSide className="col ">
                <ImgWrapper>
                  <img src={solutinDiagram} alt="discovery-workshop2" />
                </ImgWrapper>
              </RightSide>
            </div>
          </TextWrapper>

          <CardWithSquareDots
            data={PeopleInvitedCard}
            cardSstyle={{ background: "#12251A" }}
          />
          <CardWithSquareDots data={Outcome1b} />
        </LeftSide>

        <RightSide className="col showOnBig">
          <ImgWrapper>
            <img src={solutinDiagram} alt="discovery-workshop2" />
          </ImgWrapper>
        </RightSide>
      </Section>
    </Content>
  );
};

export default ProcessPageNewDiscovery;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  padding: 60px 0;
  @media (max-width: 990px) {
    row-gap: 40px;
  }
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  h1 {
    color: ${color.primaryDark};
    font-size: 48px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    margin: 0;
    max-width: 900px;
  }

  @media (max-width: 990px) {
    row-gap: 20px;
    h1 {
      font-size: 30px;
      line-height: 33px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

const Section = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: 990px) {
    flex-direction: column-reverse;
  }
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  min-width: 400px;
  @media (max-width: 650px) {
    min-width: unset;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    color: ${color.primaryDark};
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: -0.5px;
    margin: 0;
    margin-bottom: 16px;
  }
  p {
    margin: 0;
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
  }
  @media (max-width: 990px) {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;
const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:end;
  row-gap: 60px;
  width: 100%;
  min-width: 400px;
  @media (max-width: 375px) {
    min-width: unset;
  }
  @media(max-width:990px){
      justify-content:center;
  }
`;
const ImgWrapper = styled.div`
display:flex;
justify-content:end;
  img {
    object-fit: contain;
    width: 80%;
    max-height: 362px;
    min-width: 100px;
  }
   @media(max-width:990px){
      justify-content:center;
  }
`;
const ImgWrapperSmall = styled.div`
  display: flex;
  width: 40%;
  justify-content:center;
  margin: 20px;
  img {
    object-fit: contain;
    max-height: 362px;
    min-width: 50px;

  }
`;
const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;
