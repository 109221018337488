import HdrHeaderImg from "../assets/images/case-studies/hdr/hdrHeader.webp";
import CaptureFeatureAsset from "../assets/images/case-studies/hdr/feature-showcase-capture.webp";
import ImportFeatureAsset from "../assets/images/case-studies/hdr/feature-showcase-import.webp";
import EditFeatureAsset from "../assets/images/case-studies/hdr/feature-showcase-edit.webp";
import PresetsFeatureAsset from "../assets/images/case-studies/hdr/feature-showcase-presets.webp";
import HdrTakeAwayAsset from "../assets/images/taacImg.jpg";

import DailySaveAsset from "../assets/images/case-studies/oneDay/feature-showcase-capture.webp";
import LeaderboardsAsset from "../assets/images/case-studies/oneDay/feature-showcase-leaderboard.webp";
import PublicSquareAsset from "../assets/images/case-studies/oneDay/feature-showcase-public.webp";
import HumanAsset from "../assets/images/case-studies/oneDay/feature-showcase-human.webp";
import PersonalProgressAsset from "../assets/images/case-studies/oneDay/feature-showcase-personal.webp";
import OneSaveTakeAwayAsset from "../assets/images/case-studies/oneDay/taacImg.webp";
import OneSaveHeader from "../assets/images/case-studies/oneDay/oneDayHeader.webp";

import ArmercHeader from "../assets/images/case-studies/armec/armecHeader.webp";
import ArmercFeature1 from "../assets/images/case-studies/armec/feature-showcase-asset1.webp";
import ArmercFeature2 from "../assets/images/case-studies/armec/feature-showcase-asset2.webp";
import ArmercFeature3 from "../assets/images/case-studies/armec/feature-showcase-asset3.webp";
import ArmercTaac from "../assets/images/case-studies/armec/taacImg.webp";

import SempahrHeader from "../assets/images/case-studies/semaphr/semaphrHeader.webp";
import SempahrFeatureInsights from "../assets/images/case-studies/semaphr/feature-showcase-insights.webp";
import SempahrFeatureMessage from "../assets/images/case-studies/semaphr/feature-showcase-message.webp";
import SempahrFeaturePersonalize from "../assets/images/case-studies/semaphr/feature-showcase-personalize.webp";
import SempahrTaac from "../assets/images/case-studies/semaphr/taacImg.webp";

import DesignesyHeader from "../assets/images/case-studies/designesy/designesyHeader.webp";
import DesignesyShowcaseMeetings from "../assets/images/case-studies/designesy/feature-showcase-meetings.webp";
import DesignesyShowcaseControl from "../assets/images/case-studies/designesy/feature-showcase-control.webp";
import DesignesyShowcaseTeam from "../assets/images/case-studies/designesy/feature-showcase-team.webp";
import DesignesyShowcaseMembership from "../assets/images/case-studies/designesy/feature-showcase-benefits.webp";
import DesignesyTaac from "../assets/images/case-studies/designesy/taacImg.webp";

import TimeHeader from "../assets/images/case-studies/time/timeHeader.webp";
import TimeShowcasePersonalized from "../assets/images/case-studies/time/feature-showcase-personalize.webp";
import TimeShowcaseInteraction from "../assets/images/case-studies/time/feature-showcase-interactions.webp";
import TimeShowcaseExpert from "../assets/images/case-studies/time/feature-showcase-expert.webp";
import TimeShowcaseSupport from "../assets/images/case-studies/time/feature-showcase-support.webp";
import TimeTaac from "../assets/images/case-studies/time/taacImg.webp";

import DezignerHeader from "../assets/images/case-studies/dezigner/dezignerHeader.webp";
import DezignerSHowcaseSnap from "../assets/images/case-studies/dezigner/feature-showcase-snap.webp";
import DezignerSHowcaseEdit from "../assets/images/case-studies/dezigner/feature-showcase-edit.webp";
import DezignerSHowcaseMood from "../assets/images/case-studies/dezigner/feature-showcase-mood.webp";
import DezignerSHowcaseReality from "../assets/images/case-studies/dezigner/feature-showcase-augumented.webp";
import DezignerTaac from "../assets/images/case-studies/dezigner/dezignerTaac.webp";

import SnifferHeader from "../assets/images/case-studies/sniffer/snifferHeader.webp";
import SnifferTaac from "../assets/images/case-studies/sniffer/snifferTaac.webp";
import SnifferShowcaseScan from "../assets/images/case-studies/sniffer/feature-showcase-scan.webp";
import SnifferShowcaseFind from "../assets/images/case-studies/sniffer/feature-showcase-find.webp";
import SnifferShowcaseNever from "../assets/images/case-studies/sniffer/feature-showcase-never.webp";

import ParenthingHeader from "../assets/images/case-studies/parenthing/parenthingHeader.webp";
import ParenthingTaac from "../assets/images/case-studies/parenthing/parenthingTaac.webp";
import ParenthingShowcasePersonalized from "../assets/images/case-studies/parenthing/feature-showcase-personalized.webp";
import ParenthingShowcaseSearch from "../assets/images/case-studies/parenthing/feature-showcase-search.webp";
import ParenthingShowcaseTest from "../assets/images/case-studies/parenthing/feature-showcase-test.webp";

import VoicecalHeader from "../assets/images/case-studies/voicecal/voicecallHeader.webp";
import VoicecalShowcaseEnable from "../assets/images/case-studies/voicecal/feature-showcase-enable.webp";
import VoicecalShowcaseLanguages from "../assets/images/case-studies/voicecal/feature-showcase-multiple.webp";
import VoicecalShowcaseHistory from "../assets/images/case-studies/voicecal/feature-showcase-history.webp";
import VoicecalShowcaseDetailed from "../assets/images/case-studies/voicecal/feature-showcase-detailed.webp";
import VoicecalTaac from "../assets/images/case-studies/voicecal/voicecallTaac.webp";

import {
  armercCaseStudy,
  designesyCaseStudy,
  dezignerCaseStudy,
  hdrCaseStudy,
  oneSaveCaseStudy,
  parentingCaseStudy,
  semaphrCaseStudy,
  snifferCaseStudy,
  timeCaseStudy,
  voicecalCaseStudy,
} from "./ProjectsSmallCardsList";

export const ProjectsList = [
  //HDR Plus +
  {
    projectId: "hdr",
    projectHeader: {
      title: "HDR Plus+",
      description: (
        <p>
          HDR Plus+ with Machine Learning effortlessly enhances HDR <br></br>
          photos, delivering stunning results in any lighting.
        </p>
      ),
      image: HdrHeaderImg,
      buttons: [
        {
          type: "ios",
          variant: "light",
          action: "https://apps.apple.com/ro/app/hdr-plus/id1480692378",
        },
      ],
      dots: [
        "UX/UI",
        "iOS development",
        "ML development",
        "Multi-device manual testing",
      ],
    },
    headerAssetBg: "#EFEFEB",
    projectFeatures: {
      features: [
        "Auto - capture HDR photos using AI",
        "Manual adjust ISO and exposure",
        "RAW capture",
        "EV Presets",
        "Control ISO, exposure, and white balance",
      ],
      launch: ["Marketing materials"],
    },
    featuresShowcase: [
      {
        title: "Capture",
        text:
          "Capture ultra-realistic, noiseless RAW photos using all the power in your iPhone. Shoot a burst of up to 8 RAW photos, align and merge them to achieve a DSLR-like look and feel.",
        img: CaptureFeatureAsset,
      },
      {
        title: "Import",
        text:
          "Import your favorite photos or HDR brackets captured with other apps or devices, and merge and edit them in HDR Plus+. By doing so you can leverage the power of the ML algorithms on any of your photos.",
        img: ImportFeatureAsset,
      },
      {
        title: "Edit",
        text:
          "Edit the photos like a professional, the app allows its users to merge styles, and modify the colors, vibrance, BW, and depth control together with skin smoothing and de-ghosting. Additionally, it allows users to tweak any of the normal, pro-level values such as brightness, contrast saturation, exposure, gamma, hue, etc.",
        img: EditFeatureAsset,
      },
      {
        title: "Presets",
        text:
          "For fast editing, users can select from already existing presets or create their own. In this way, inexperienced users can easily use their own style consistently on all their photos.",
        img: PresetsFeatureAsset,
      },
    ],
    setupList: [
      {
        listTitle: "Process",
        listItems: ["Agile", "2 weeks sprints", "Daily standups"],
      },
      {
        listTitle: "Team",
        listItems: ["1 iOS", "1 ML", "1 QA", "1 Designer", "1 PO/Scrum master"],
      },
      {
        listTitle: "Technologies",
        listItems: ["iOS (Swift)", "Ml (Phyton)", "Figma"],
      },
      {
        listTitle: "Dates",
        listItems: ["October 2021 - Ongoing"],
      },
    ],
    takeAway: {
      takeAwayAsset: HdrTakeAwayAsset,
      takeAwayList: [
        {
          number: "1",
          text:
            "Learning more about photography was one of the best things about this project, our team is now proficient in pro-level photography slang.",
        },
        {
          number: "2",
          text:
            "The Machine Learning models were the most challenging and fun parts of the project, we got to play around with different approaches and build our very own models for solving different challenges in the app.",
        },
        {
          number: "3",
          text:
            "This project showed us that ML can be applied successfully in a lot of areas in which we thought it was impossible. It pushed our AI team further and enhanced our skillsets to new levels.",
        },
      ],
    },
  },
  //OneDay/Save
  {
    projectId: "onesave",
    projectHeader: {
      title: "OneSave/Day",
      description: (
        <p>
          Through daily simple actions, app users advocate for environmental
          change, adopting a more responsible lifestyle.
        </p>
      ),
      image: OneSaveHeader,

      buttons: [
        {
          type: "ios",
          variant: "light",
          action: "https://apps.apple.com/us/app/onesave-day/id1473060985",
        },
        {
          type: "android",
          variant: "light",
          action:
            "https://play.google.com/store/apps/details?id=com.appssemble.environmentawareness&hl=en&gl=US",
        },
        {
          type: "web",
          variant: "light",
          action: "https://www.onesave.org",
        },
      ],
      dots: [
        "UX/UI",
        "iOS development",
        "ML development",
        "Multi-device manual testing",
      ],
    },
    headerAssetBg: "#EFEFEB",
    projectFeatures: {
      features: [
        "Background removal (camera and library)",
        "Object collection",
        "Mood boards",
        "AR placing",
        "Full offline functionality",
      ],
      launch: [
        "Created marketing materials",
        "SEO for AppStore",
        "Analytics and link attributions",
      ],
    },
    featuresShowcase: [
      {
        title: "Daily Saves",
        text:
          "Each day a new daily challenge is being published and executed by thousands of people from around the world. In addition to this users are allowed to thank and interact with other savers using video messages.",
        img: DailySaveAsset,
      },
      {
        title: "ImpoLeaderboards & Recapsrt",
        text:
          "Compete with your friends and see where you rank globally or in your home country. Monthly recaps give the users an overview of their achievements of the last month.",
        img: LeaderboardsAsset,
      },
      {
        title: "Public Square",
        text:
          "Users can remain on top of environmental news and can discuss with other members of the community about things they can do to fight climate change and environmental problems.",
        img: PublicSquareAsset,
      },
      {
        title: "Humane Technology",
        text:
          "Every feature is built in a way that empowers society, creates valuable interactions, and leads to conscious choices. Minimize your attention on the app to maximize your real-world experience.",
        img: HumanAsset,
      },
      {
        title: "Personal Progress",
        text:
          "The app tracks your personal progress and visualizes your improvements by categorizing the results to make your impact tangible and giving you monthly recaps to keep you motivated over time.",
        img: PersonalProgressAsset,
      },
    ],
    setupList: [
      {
        listTitle: "Process",
        listItems: ["Agile", "2 weeks sprints", "Daily standups"],
      },
      {
        listTitle: "Team",
        listItems: ["1 iOS", "1 ML", "1 QA", "1 Designer", "1 PO/Scrum master"],
      },
      {
        listTitle: "Technologies",
        listItems: ["iOS (Swift)", "Figma", "Tenserflow (Python)"],
      },
      {
        listTitle: "Dates",
        listItems: ["October 2021 January 2022"],
      },
    ],
    takeAway: {
      takeAwayAsset: OneSaveTakeAwayAsset,
      takeAwayList: [
        {
          number: "1",
          text:
            "Designing the application was a fun experience as it was challenging, but also extremely entertaining. As always, coming up with a design solution that is up-to-date and appealing to young people but also a design that drives environmental change, wasn't an easy task at all.",
        },
        {
          number: "2",
          text:
            "We learned a lot about environmental awareness, stopping climate change, and what we as individuals can do to make our blue planet a safer and better place. The most important lesson is that it all starts with our small habits, and if we want to change something, we need to start with ourselves.",
        },
        {
          number: "3",
          text:
            "The main takeaway from this project was indeed the apprehension of the belief that every single one on this planet is responsible for the environment. Even Leo Tolstoy affirmed that “One of the first conditions of happiness is that the link between man and nature shall not be broken”. So do your part and take care of the planet as much as you can!",
        },
      ],
    },
  },
  //Designesy
  {
    projectId: "designesy",
    changedHeader: true,
    projectHeader: {
      title: "Designesy",
      description: (
        <p>
          Subscription-based design service including a UX/UI design management
          system, payment integration, user roles, client-facing apps, and
          designer back office (task tracking and client management).
        </p>
      ),
      image: DesignesyHeader,

      buttons: [
        {
          type: "web",
          variant: "dark",
          action: "https://designesy.com",
        },
      ],
      dots: [
        "UX/UI",
        "Frontend development ",
        "Backend development ",
        "Multi-device manual testing",
      ],
    },
    projectFeatures: {
      features: [
        "Client facing platform",
        "Design facing platform",
        "UX/UI design process management platform",
        "Task tracking, automatic mailing system",
      ],
      launch: [
        "Marketing materials",
        "Analytics and link attributions",
        "Deployment",
        "Autoscaling",
        "Monitoring",
      ],
    },
    featuresShowcase: [
      {
        title: "No meetings",
        text:
          "Say goodbye to the endless time wasted in meetings and embrace an asynchronous approach, where everything happens on your schedule.",
        img: DesignesyShowcaseMeetings,
      },
      {
        title: "Complete control",
        text:
          "Prioritize your design tasks, view their progress in real-time, and revise & comment on every pixel of the produced designs.",
        img: DesignesyShowcaseControl,
      },
      {
        title: "Working in a team?",
        text:
          "You can invite more people to your board so anyone can submit requests and track progress.",
        img: DesignesyShowcaseTeam,
      },
      {
        title: "Membership benefits",
        text:
          "High-quality designs, tailored to your needs and preferences, with fast turnaround times and affordable pricing that you can pause or cancel anytime. ",
        img: DesignesyShowcaseMembership,
      },
    ],
    setupList: [
      {
        listTitle: "Process",
        listItems: [
          "Shape-up",
          "4 weeks cycles",
          "Daily team syncs",
          "Weekly status report",
        ],
      },
      {
        listTitle: "Team",
        listItems: [
          "2 Backend",
          "2 Frontend",
          "2 QA",
          "2 UX/UI",
          "1 Project Owner",
        ],
      },
      {
        listTitle: "Technologies",
        listItems: [
          "Figma",
          "React",
          "TypeScript",
          "Ruby On Rails",
          "PostgreSQL",
          "Google Cloud",
          "WebSockets",
        ],
      },
      {
        listTitle: "Dates",
        listItems: ["April 2023 - June 2023"],
      },
    ],
    takeAway: {
      takeAwayAsset: DesignesyTaac,
      takeAwayList: [
        {
          number: "1",
          text:
            "The main challenge of this project was creating a platform that helps the team at Designesy streamline their design processes and manage their client work in an async way.",
        },
        {
          number: "2",
          text:
            "From a technology point of view, it was fun playing with technical concepts such as WebSockets and Load Balancers to create something that facilitates the creative process.",
        },
        {
          number: "3",
          text:
            "We've learned a lot about the process of digital design, all the members involved in the project understand the job of a UX/UI designer better and the entire process of going from idea to assets.",
        },
      ],
    },
  },
  //armerc
  {
    projectId: "armerc",
    projectHeader: {
      title: "armerc",
      description: (
        <p>
          Armerc is a platform that allows users to create high-quality 3D
          models by using 2D photos. Those 3D models can be viewed with
          AR-enabled devices, and their popularity is monitored. By using the
          iOS app, users can snap high-quality photos for their 3D models and
          combine them with depth and orientation data.
        </p>
      ),
      image: ArmercHeader,

      buttons: [
        {
          type: "ios",
          variant: "light",
          action: "https://apps.apple.com/app/id6470124593",
        },
        {
          type: "web",
          variant: "light",
          action: "https://armerc.com",
        },
      ],
      dots: [
        "UX/UI",
        "Backend development",
        "ML development",
        "iOS Development",
        "Multi-device manual testing",
      ],
    },
    headerAssetBg: "#EFEFEB",
    projectFeatures: {
      features: [
        "3D model building from 2D photos",
        "All platforms model view (Web, Mobile, AR-enabled devices)",
        "Model performance analysis",
        "Spatial-enhanced photo capture",
      ],
      launch: [
        "Marketing materials",
        "Analytics and link attributions",
        "Deployment",
        "Monitoring",
      ],
    },
    featuresShowcase: [
      {
        title: "Guided process",
        text:
          "Using the iOS app, users can follow a process of scanning 3D models that results in high-quality 3D model objects. The scanning process aggregates image data with spatial data (orientation and depth) and sends it to the processing pipeline in a seamless way.",
        img: ArmercFeature1,
      },
      {
        title: "All about 3D models",
        text:
          "User-friendly and organized models overview. Users can easily see their scanned models, add new ones, and filter them based on names, dates, and most importantly popularity.",
        img: ArmercFeature2,
      },
      {
        title: "Model overview",
        text:
          "The overview allows users to add new pictures to improve their model, generate web links for viewing those on Web or AR-enabled devices, and measure their performance (number of clicks).",
        img: ArmercFeature3,
      },
    ],
    setupList: [
      {
        listTitle: "Process",
        listItems: ["Shape-up", "4 weeks cycles", "Daily syncs"],
      },
      {
        listTitle: "Team",
        listItems: [
          "2 Backend",
          "1 Frontend",
          "2 iOS",
          "2 QA",
          "2 UX/UI",
          "1 Project Owner",
        ],
      },
      {
        listTitle: "Technologies",
        listItems: [
          "Figma",
          "React",
          "Ruby on Rails",
          "PostgreSQL",
          "Machine Learning (TensorFlow)",
          "ARKit",
          "Swift",
        ],
      },
      {
        listTitle: "Dates",
        listItems: ["September 2023 - November 2023"],
      },
    ],
    takeAway: {
      takeAwayAsset: ArmercTaac,
      takeAwayList: [
        {
          number: "1",
          text:
            "The challenge of this project was creating high-quality 3D models from 2D images. The project involved a lot of R&D and ML development.",
        },
        {
          number: "2",
          text:
            "Another important aspect was designing a user-friendly process for capturing high-quality model images that would result in an outstanding 3D model.",
        },
        {
          number: "3",
          text:
            "This project touched down on our sweet spot, technologically challenging, playing with cutting-edge technologies that combine new tech with vast domains such as photo processing. We've had a lot of fun building this.",
        },
      ],
    },
  },
  //semaphr
  {
    changedHeader: true,
    projectId: "semaphr",
    projectHeader: {
      title: "semaphr",
      description: (
        <p>
          Semaphr allows project managers and app developers to force update
          their mobile apps. It delivers instantly app version-wide system
          messages, blocks versions, or urges users to update their apps,
          through the mobile (iOS and Android) SDKs.
        </p>
      ),
      image: SempahrHeader,

      buttons: [
        {
          type: "web",
          variant: "dark",
          action: "https://semaphr.com",
        },
      ],
      dots: [
        "UI/UX",
        "Backend development",
        "Frontend development",
        "iOS Development",
        "Android Development",
        "Multi-device manual testing",
      ],
    },
    projectFeatures: {
      features: [
        "Mobile apps kill-switch",
        "iOS and Android SDKs",
        "Instant version-wide in-app messaging",
        "Messages management platform",
      ],
      launch: [
        "Marketing materials",
        "Analytics and link attributions",
        "Deployment",
        "Monitoring",
      ],
    },
    featuresShowcase: [
      {
        title: "App insights and user analytics with semaphr",
        text:
          "With semaphr, you can gain valuable insights into your app's performance and user behavior. The app provides detailed graphs and statistics that can help you make informed decisions about how to improve your app's performance and user experience.",
        img: SempahrFeatureInsights,
      },
      {
        title: "A message system tailored to your needs",
        text:
          "With three message types to choose from, including block messages, updates, and notifications, semaphr can help you respond to a wide range of needs and keep your users up-to-date with any changes or issues that may arise.",
        img: SempahrFeatureMessage,
      },
      {
        title: "Create personalized messages for your app with ease",
        text:
          "With our user-friendly interface, creating a new message is a breeze. You can customize the message text, and icon, select the message type, schedule the delivery date and time, and even preview how your message will look before it's sent.",
        img: SempahrFeaturePersonalize,
      },
    ],
    setupList: [
      {
        listTitle: "Process",
        listItems: ["Shape-up", "4 weeks cycles", "Daily syncs"],
      },
      {
        listTitle: "Team",
        listItems: [
          "1 Backend",
          "1 Frontend",
          "1 iOS",
          "1 Android",
          "1 QA",
          "1 UX/UI",
          "1 PO",
        ],
      },
      {
        listTitle: "Technologies",
        listItems: [
          "Figma",
          "React",
          "Ruby on Rails",
          "PostgreSQL",
          "Swift",
          "Kotilin",
        ],
      },
      {
        listTitle: "Dates",
        listItems: ["June 2023 - July 2023"],
      },
    ],
    takeAway: {
      takeAwayAsset: SempahrTaac,
      takeAwayList: [
        {
          number: "1",
          text:
            "There are rare cases where projects we develop make our jobs easier, semaphr is one of those rare, almost unique cases where we use the product we've developed as a part of our development process.",
        },
        {
          number: "2",
          text:
            "Forcing an upgrade to a mobile app is something that's not possible without a platform like semaphr, we've solved this problem hundreds of times before, but it always was a different process based on the client we work with. We're glad we've solved it once and for all and all the kill switch and upgrade mechanisms live under a single, unified platform.",
        },
        {
          number: "3",
          text:
            "We've enjoyed solving the kill-switch and version notification problem for the last time :D!",
        },
      ],
    },
  },
  //time
  {
    changedHeader: true,
    projectId: "time",
    projectHeader: {
      title: "TIME",
      description: (
        <p>
          With the TIME app, users can monetize their experience and time
          through video interactions.
        </p>
      ),
      image: TimeHeader,

      buttons: [
        {
          type: "ios",
          variant: "dark",
          action:
            "https://apps.apple.com/sn/app/time-get-inspired/id1607803576",
        },
        {
          type: "android",
          variant: "dark",
          action:
            "https://play.google.com/store/apps/details?id=com.appssemble.time_android",
        },
      ],
      dots: [
        "UX/UI",
        "iOS development",
        "Android developement",
        "Backend developement",
        "Multi-device manual testing",
      ],
    },
    projectFeatures: {
      features: [
        "Personalized video feed",
        "1 on 1 paid video interactions",
        "Tips",
        "Video content",
        "Topics of interest & advanced search",
      ],
      launch: ["Marketing materials", "Analytics and link attributions"],
    },
    featuresShowcase: [
      {
        title: "Personalized Video Feed",
        text:
          "The users of the app can stay up to date with the latest content from their favorite creators and never miss a video. Only new content is being displayed to avoid spending too much time in the app.",
        img: TimeShowcasePersonalized,
      },
      {
        title: "Video Interactions",
        text: `By using the "request" feature, users can ask for advice and information from top experts around the world (scientists, artists, sportsmen, entrepreneurs, etc.).`,
        img: TimeShowcaseInteraction,
      },
      {
        title: "Anyone Can Be An Expert",
        text:
          "Any user can register on the app and start earning money for their advice. All they need to do is create an account and set up their price/minute, they will be paid m",
        img: TimeShowcaseExpert,
      },
      {
        title: "Support Your Favorite Cause",
        text:
          "Users can pick a charity that will receive a (configurable) percentage of all their earnings on the platform. In this way, users can help their favorite organizations by donating their time instead of actual money.",
        img: TimeShowcaseSupport,
      },
    ],
    setupList: [
      {
        listTitle: "Process",
        listItems: ["Agile", "2 weeks sprints", "Daily standups"],
      },
      {
        listTitle: "Team",
        listItems: [
          "1 iOS",
          "1 Android",
          "1 Backedn",
          "1 QA",
          "1 Designer",
          "1 PO/Scrum Master",
        ],
      },
      {
        listTitle: "Technologies",
        listItems: [
          "iOS (Swift)",
          "Android (Kotlin)",
          "Backend (Ruby on Rails)",
          "Figma",
          "Amazon Web Services",
        ],
      },
      {
        listTitle: "Dates",
        listItems: ["June 2021 - Ongoing"],
      },
    ],
    takeAway: {
      takeAwayAsset: TimeTaac,
      takeAwayList: [
        {
          number: "1",
          text:
            "The main challenge of this project was coming up with a payment solution that would allow the client to pay its users on a monthly basis and comply with the app store's rules.",
        },
        {
          number: "2",
          text:
            "Designing the application was a lot of fun, we worked alongside motivated founders and built a system that helps them validate their hypotheses and find the product-market-fit.",
        },
        {
          number: "3",
          text:
            "This project showed us again the importance of collecting qualitative feedback as early and as soon as possible. In addition to this, it made the team stronger and more connected as we had multiple times to combine different departments (tech, design, and legal) to come up with a solution that is suitable for the purpose of the product.",
        },
      ],
    },
  },
  //dezigner
  {
    projectId: "dezigner",
    projectHeader: {
      title: "Dezigner",
      description: (
        <p>
          Dezigner allows users to snap pictures of their favorite pieces of art
          or furniture, collect them and place them in new spaces or mood
          boards.
        </p>
      ),
      image: DezignerHeader,

      buttons: [
        {
          type: "ios",
          variant: "light",
          action:
            "https://apps.apple.com/ro/app/dezigner-artist-companion/id1605136827",
        },
      ],
      dots: [
        "UX/UI",
        "iOS development",
        "Machine Learning models development",
        "Multi-device manual testing",
      ],
    },
    headerAssetBg: "#EFEFEB",
    projectFeatures: {
      features: [
        "Background removal (camera and library)",
        "Object collection",
        "Mood boards",
        "AR placing",
        "Full offline functionality",
      ],
      launch: [
        "Marketing materials",
        "SEO for AppStore",
        "Analytics and link attributions",
      ],
    },
    featuresShowcase: [
      {
        title: "Snap, upload, and remove",
        text:
          "The users can easily snap or upload from their gallery, pictures of their favorite design items, then they can remove the background and save them for later. The background removal process works in-app, and also offline, without the need for an internet connection.",
        img: DezignerSHowcaseSnap,
      },
      {
        title: "Edit and export",
        text:
          "Artists are creative people that have a lot of ideas and they usually like to try out things, that's why the app allows them to edit the assets, by changing the backgrounds of the collected items and trying out their ideas to find the perfect aesthetic. In addition to this, the assets can be easily exported and used as PNGs in other apps.",
        img: DezignerSHowcaseEdit,
      },
      {
        title: "Mood boards",
        text:
          "Collecting is in our human nature, the app allows its users to collect objects in different mood boards to aid their creative process and see which items go well together. The mood boards can also change colors so it's easy to try out different combinations.",
        img: DezignerSHowcaseMood,
      },
      {
        title: "Augmented Reality",
        text:
          "Most of us are visualizers, we need to see things with our own eyes so we can better comprehend their entity, and we also need this process to be quick and accessible. So it's always better to visualize objects in their real spaces instead of relying on computer designs and renderings, that's why with Dezigner, any interior designers and artists can see their collection of snaps in real spaces using AR.",
        img: DezignerSHowcaseReality,
      },
    ],
    setupList: [
      {
        listTitle: "Process",
        listItems: ["Agile", "2 weeks sprints", "Daily standups"],
      },
      {
        listTitle: "Team",
        listItems: [
          "1 iOS",
          "1 Machine learning",
          "1 QA",
          "1 Designer",
          "1 PO/Scrum Master",
        ],
      },
      {
        listTitle: "Technologies",
        listItems: ["iOS (Swift)", "Tenserflow", "Figma"],
      },
      {
        listTitle: "Dates",
        listItems: ["December 2021-January 2022"],
      },
    ],
    takeAway: {
      takeAwayAsset: DezignerTaac,
      takeAwayList: [
        {
          number: "1",
          text:
            "The main challenge of this project was to offer all the functionalities offline. Also developing in-house Machine Learning models for background removal was fun and a provoking experience. To create an ML model, our developers had to dive deep into the domain of image processing to understand the problem, collect training data, train the model, and make it work on mobile apps.",
        },
        {
          number: "2",
          text:
            "With this project, we've combined our love for technology with our love for the creative fields. Our designers were cheerful in creating an app that was meant especially for creative people like themselves. On the other hand, our technical team enjoyed the puzzling challenge that this app brought and loved solving a hard problem with cutting-edge technologies.",
        },
        {
          number: "3",
          text:
            "The main takeaway from this project is that we got to work with Machine Learning models, which can easily identify trends and patterns, is in continuous improvement, has a wide application and can handle multi-dimensional and multi-variety data. All these features open up the world to a new and vast field of possibilities in the realm of mobile applications, reinforcing our belief that we should employ the most advanced and finest available technology to better all parts of our everyday lives.",
        },
      ],
    },
  },
  //sniffer
  {
    projectId: "sniffer",
    projectHeader: {
      title: "Sniffer",
      description: (
        <p>
          Sniffer - helps users find their lost Bluetooth devices, by giving
          directions to where their signal comes from.{" "}
        </p>
      ),
      image: SnifferHeader,

      buttons: [
        {
          type: "ios",
          variant: "light",
          action:
            "https://apps.apple.com/ro/app/bluetooth-devices-finder-2/id1613533045",
        },
      ],
      dots: [
        "UX/UI",
        "iOS development",
        "Bluetooth Technologies development",
        "Multi-device manual testing",
      ],
    },
    headerAssetBg: "#919085",
    projectFeatures: {
      features: [
        "Bluetooth device tracking",
        "Bluetooth device strength monitor",
        "Device left behind notifications",
      ],
      launch: [
        "Created Marketing materials",
        "SEO for AppStore",
        "Analytics and link attributions",
      ],
    },
    featuresShowcase: [
      {
        title: "Scan and add devices",
        text:
          "The app allows its user to scan and add tracking for all their Bluetooth devices -- AirPods or other Bluetooth headphones, keyboards, mouses, Fitbits, iPads, or any other Bluetooth-enabled device.",
        img: SnifferShowcaseScan,
      },
      {
        title: "Find lost devices",
        text:
          "With the built-in Bluetooth signal strength monitor, users can easily find their lost devices, by moving in the direction that increases the signal strength.",
        img: SnifferShowcaseFind,
      },
      {
        title: "Never leave the devices behind",
        text:
          "Sniffers issues notifications every time one of the user's Bluetooth devices are left behind, in this way, users won't ever leave a place and forget their headphones or other Bluetooth-enabled devices.",
        img: SnifferShowcaseNever,
      },
    ],
    setupList: [
      {
        listTitle: "Process",
        listItems: ["Agile", "2 weeks sprints", "Daily standups"],
      },
      {
        listTitle: "Team",
        listItems: ["1 iOS", "1 QA", "1 Designer", "1 PO/Scrum Master"],
      },
      {
        listTitle: "Technologies",
        listItems: ["iOS (Swift)", "CoreBluetooth", "Figma"],
      },
      {
        listTitle: "Dates",
        listItems: ["February 2022-March 2022"],
      },
    ],
    takeAway: {
      takeAwayAsset: SnifferTaac,
      takeAwayList: [
        {
          number: "1",
          text:
            "The main takeaway of this project was to successfully build an app that would help many people with different Bluetooth devices by showing them how close they are to their devices and lead to their location. It was challenging but also fun since Bluetooth technology is fickle so it was rewarding to be able to build something that can help people on a daily basis with such contemporary common issues.",
        },
        {
          number: "2",
          text:
            "When it comes to design, the most fun and challenging part was to implement a good finding tool that would bring back great results, and after a complex analysis we came up with a fun solution, we chose a bright and friendly theme that everybody would find pleasing, junior or senior.",
        },
        {
          number: "3",
          text:
            "Following the design, one other fun takeaway of this project was creating the symbolic dog character, Rex the Sniffer. Integrating Rex in the UI of the app was an entertaining experience as it was challenging, but also extremely pleasing because we got the chance to play the artist and be craftsmen.",
        },
      ],
    },
  },
  //prenthing
  {
    changedHeader: true,
    projectId: "parenthing",
    projectHeader: {
      title: "Parenthing",
      description: (
        <p>
          Elevate your child's potential with personalized guidance and
          resources.{" "}
        </p>
      ),
      image: ParenthingHeader,

      buttons: [
        {
          type: "ios",
          variant: "dark",
          action:
            "https://apps.apple.com/us/app/parenthing-parenting-helpmate/id1527726584",
        },
        {
          type: "web",
          variant: "dark",
          action: "https://parenthing.co/",
        },
      ],
      dots: [
        "UX/UI",
        "Illustrations",
        "iOS development",
        "Landing page development",
        "Multi-device manual testing",
      ],
    },
    projectFeatures: {
      features: [
        "Multiple language support",
        "Personalized articles",
        "Personalized tests",
        "Personalized exercises",
        "Printable content",
      ],
      launch: [
        "Landing page",
        "SEO for AppStore",
        "Analytics and link attributions",
        "Conducted advertising campaign on the AppStore",
      ],
    },
    featuresShowcase: [
      {
        title: "Personalized screening and content",
        text:
          "Everything starts with a personalized screening, all the data provided to the app does not leave the device and based on that the app recommends the best topics for your child.",
        img: ParenthingShowcasePersonalized,
      },
      {
        title: "Search and articles",
        text:
          "All the parenting information at your fingertip, all you need to search is a topic or a problem and dive into the subject with world-class summaries build by experimented psychologists.",
        img: ParenthingShowcaseSearch,
      },
      {
        title: "Test and exercises",
        text:
          "Test your child in specific areas with built-in, personalized tests. All the information provided does not leave the device, and everything works beautifully both online and offline. Improve the skills of your child with personalized, domain base exercises. Some of them even come with downloadable resources that aid the process and create fun and educational activities for you and your child.",
        img: ParenthingShowcaseTest,
      },
    ],
    setupList: [
      {
        listTitle: "Process",
        listItems: [
          "Agile",
          "2 weeks sprints",
          "Daily standups",
          "Weekly status updates",
        ],
      },
      {
        listTitle: "Team",
        listItems: ["1 iOS", "1 QA", "1 Designer", "1 PO/Scrum Master"],
      },
      {
        listTitle: "Technologies",
        listItems: ["INVision", "iOS ", "Firebase", "JavaScript"],
      },
      {
        listTitle: "Dates",
        listItems: ["March - September 2020"],
      },
    ],
    takeAway: {
      takeAwayAsset: ParenthingTaac,
      takeAwayList: [
        {
          number: "1",
          text:
            "The most challenging part of this project was coming up with a design that would be friendly and easily accessible for parents to find solutions to their parenting challenges. We’ve solved this impediment by building clear, obvious topic-based UI.",
        },
        {
          number: "2",
          text:
            "Besides the layout of the application, choosing illustrations that best convey the learnings of the topics was another thing that kept us busy. We’ve created consistent experience using color codes and illustrations that reflect the idea of the content.",
        },
        {
          number: "3",
          text:
            "All the members of our team who worked on this product enjoyed building it. Some of them learned some tips and tricks that they’ve successfully applied with their children, the others understood better what it means to be a parent and what are some of the challenges that the job comes with.",
        },
      ],
    },
  },
  //voicecal
  {
    projectId: "voicecal",
    projectHeader: {
      title: "voicecal",
      description: (
        <p>
          voicecal is an AI-enabled application that uses Natural Language
          processing for accurately keeping track of the foods you ate.
        </p>
      ),
      image: VoicecalHeader,

      buttons: [
        {
          type: "ios",
          variant: "light",
          action:
            "https://apps.apple.com/us/app/voicecal-calories-counter/id1521692537",
        },
      ],
      dots: [
        "UX/UI",
        "iOS development",
        "Backend development",
        "Multi-device manual testing",
      ],
    },
    headerAssetBg: "#919085",
    projectFeatures: {
      features: [
        "Nutritional intake counter",
        "Nutritional graph",
        "Multiple language support",
        "Detailed summary",
      ],
      launch: [
        "Created marketing materials",
        "Presentation video",
        "SEO for AppStore",
        "Analytics and link attributions",
      ],
    },
    featuresShowcase: [
      {
        title: "Voice-enabled",
        text:
          "Monitor your nutritional values using a simple input mechanism. Just like having Jarvis from Iron Man in your pocket, voicecal allows fast input of the foods you ate together with their sizes or your favorite snacks.",
        img: VoicecalShowcaseEnable,
      },
      {
        title: "Multiple languages",
        text:
          "Use your native language or the one you are planning to learn. We have built-in support for 63 languages and accents. This allows users from a wide geographical range to use the app, fast and accurate.",
        img: VoicecalShowcaseLanguages,
      },
      {
        title: "History graph",
        text:
          "Visualize all your history in a graph-based approach. You can visually check your progress towards your nutritional goal without going into too many details. In addition to this, the daily overview provides a summary of your day.",
        img: VoicecalShowcaseHistory,
      },
      {
        title: "Detailed history",
        text:
          "Check the full history of your nutritional intake -- with daily overviews or daily details. In addition to the number of specific nutrients, we are also providing details regarding the foods you've snapped and their importance in your diet.",
        img: VoicecalShowcaseDetailed,
      },
    ],
    setupList: [
      {
        listTitle: "Process",
        listItems: [
          "Agile",
          "2 weeks sprints",
          "Daily standups",
          "Weekly status updates",
        ],
      },
      {
        listTitle: "Team",
        listItems: ["1 iOS", "1 QA", "1 Designer", "1 PO"],
      },
      {
        listTitle: "Technologies",
        listItems: ["Sketch", "INVision", "iOS ", "Firebase"],
      },
      {
        listTitle: "Dates",
        listItems: ["March 2020 - June 2020"],
      },
    ],
    takeAway: {
      takeAwayAsset: VoicecalTaac,
      takeAwayList: [
        {
          number: "1",
          text:
            "The Natural Language processing part of this project excited us the most. Driving into the uncharted territory and building something which did not previously exist is something we excel at. Not only that we have done it, we have done it in 63 languages.",
        },
        {
          number: "2",
          text: `We wanted to design something easy to use, recognizable, and friendly. The great words of Leonardo DaVinci - "Simplicity is the ultimate sophistication." were our motto for this project. Guided by our process we believe we have achieved that.`,
        },
      ],
    },
  },
];

export const SmallCardsProjectList = [
  hdrCaseStudy,
  designesyCaseStudy,
  armercCaseStudy,
  semaphrCaseStudy,
  dezignerCaseStudy,
  snifferCaseStudy,
  parentingCaseStudy,
  voicecalCaseStudy,
  timeCaseStudy,
  oneSaveCaseStudy,
];
