import React from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "../../assets/icons/cluthch-logo-stars.svg";
import AnimatedTextButton from "./AnimatedTextButton";

const ClutchLogoComponent = ({ onClick }) => {
    return (
        <ClutchWrapper onClick={onClick}>
            <Icon />
            <Btn>
                <AnimatedTextButton text={"reviews"} />
            </Btn>
        </ClutchWrapper>
    );
};

export default ClutchLogoComponent;

const ClutchWrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
`;
const Btn = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  left:80px;

  p {
    font-size: 14px !important;
  }

  @media (max-width: 450px) {

    p {
      font-size: 12px!important;
    }
    svg{
        width:12px !important;
      }
  }

  @media (max-width: 375px) {
left:63%;
  }


`;
