import styled from "styled-components";
import { color } from "../../../assets/styles/colors";
import OurStats from "./OurStats";
import AnimatedTextButton from "../../Generic/AnimatedTextButton";
import { useNavigate } from "react-router-dom";

const Fold5 = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div className="container-1440 container-content border-bottom-faded">
        <Content>
          <TextWrapper>
            <div className="col">
              <TitleWrapper>
                <h1>
                  <span>Our approach</span> to digital product development
                </h1>
              </TitleWrapper>

              <ButtonWrapper className="showOnBig">
                <AnimatedTextButton
                  text={"See more details about our mindset and how we work."}
                  onClick={() => navigate("/approach")}
                />
              </ButtonWrapper>
            </div>
            <div className="col">
              <DetailsWrapper>
                <p>
                  You can build big things with small teams, but you can not
                  move fast and build small things with big teams.
                  <br></br>
                  <br></br>
                  Our approach is simple, we work in small teams that are
                  self-sufficient, have very few meetings and no overhead, and
                  take full responsibility for everything we deliver.
                  <br></br>
                  <br></br>
                  This strategy enhances communication, speeds up
                  decision-making, enforces accountability, and keeps costs
                  down. It allows us to focus on what we do best and love,
                  building digital products and not getting bogged down by
                  lengthy procedures or unproductive meetings. The best part is
                  this approach allows us to deliver products and solutions at
                  an exceptional speed.
                  <br></br>
                  <br></br>
                  We deliver products that are aligned with our customer's
                  roadmap and goals, nothing less, nothing more. We take full
                  responsibility and own what we ship, we clean our mess if we
                  make one.
                </p>
              </DetailsWrapper>
            </div>
          </TextWrapper>
          <div className="row">
            <div className="col p-0">
              <OurStats />
            </div>
          </div>
        </Content>
      </div>
    </Wrapper>
  );
};

export default Fold5;
const Wrapper = styled.div`
  background: white;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 80px;
`;
const TextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  @media (max-width: 990px) {
    flex-direction: column;
  }

  span {
    padding-right: 5px;
    background: ${color.highlightedColor};
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 40px;
`;
const TitleWrapper = styled.div`
  h1 {
    color: ${color.primaryDark};
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.504px;
    margin: 0;
  }
  @media (max-width: 990px) {
    h1 {
      font-size: 30px;
      min-width: 300px;
    }
  }
  @media(max-width:320px){
    min-width: unset;
  }
`;
const DetailsWrapper = styled.div`
  p {
    color: ${color.primaryDark};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  @media (max-width: 990px) {
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;
