import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as DropdownIcon } from "../../assets/icons/down-arrow-rules-dropdown.svg"
import ProcessPageNew from './ProcessPageNew'
import ProcessPageOngoing from './ProcessPageOngoing'
import { useNavigate } from 'react-router-dom'
import ProcessPageExisting from './ProcessPageExisting'

const Dropdown = ({ title, isOpen, goTo, setIsOpen, content }) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        if (!isOpen) {
            navigate(goTo);
        }

        setIsOpen(!isOpen);
    };

    return (
        <DropdownWrapper>
            <DropdownEleement onClick={(e) => handleOnClick()}>
                <h1>{title}</h1>
                <DropdownIcon style={
                    isOpen
                        ? { transform: "scaleY(-1)", transition: "200ms ease" }
                        : { transition: "200ms ease" }
                } />
            </DropdownEleement>
            {isOpen &&
                <DropdownContent>
                    {content}
                </DropdownContent>
            }
        </DropdownWrapper>
    )
};

const ProcessMobileView = () => {
    const [newOpen, setNewOpen] = useState(false);
    const [existingOpen, setExistingOpen] = useState(false);
    const [ongoingOpen, setOngoingOpen] = useState(false);



    let newProducts = (
        <ProcessPageNew />
    );
    let existingProducs = (
        <ProcessPageExisting />
    );
    let ongoingProducs = (
        <ProcessPageOngoing />
    );


    return (
        <Wrapper>
            <Dropdown
                title={"New Products"}
                isOpen={newOpen}
                setIsOpen={setNewOpen}
                content={newProducts}
                goTo={"/process"}
            />
            <Dropdown
                title={"Existing Products"}
                isOpen={existingOpen}
                setIsOpen={setExistingOpen}
                content={existingProducs}
                goTo={"/process/existing"}
            />
            <Dropdown
                title={"Ongoing Products"}
                isOpen={ongoingOpen}
                setIsOpen={setOngoingOpen}
                content={ongoingProducs}
                goTo={"/process/ongoing"}
            />



        </Wrapper>
    )
}

export default ProcessMobileView

const Wrapper = styled.div`
display:flex;
flex-direction:column;
`
const DropdownWrapper = styled.div`
display:flex;
flex-direction:column;
`
const DropdownEleement = styled.div`
cursor:pointer;
display:flex;
justify-content:space-between;
align-items:center;
padding:15px 0;
z-index:2;
border-bottom:1px solid #3131310D;

h1{
font-size: 18px;
font-weight: 700;
line-height: 20px;

}
`
const DropdownContent = styled.div`

`