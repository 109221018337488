import React from "react";
import styled from "styled-components";

const BlogPost = ({ category, title, subtitle, img, onClick, readingTime }) => {
  var categoryToDisplay = "";
  if (category != null) {
    categoryToDisplay = categoryToDisplay.toUpperCase();
  }

  return (
    <Container onClick={onClick}>
      <BlogCard>
        <img src={img} alt="img" />
      </BlogCard>

      <Text>
        <HeaderSection>
          {category ? category.toUpperCase() : ""} - {readingTime} MINUTES READ
        </HeaderSection>
        {categoryToDisplay}
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </Text>
    </Container>
  );
};

export default BlogPost;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  row-gap: 12px;
  width: 100%;
  max-width: 400px;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 50px;
  transition: 400ms all ease-out;

  @media (max-width: 1440px) {
    max-width: 320px;
  }

  @media (max-width: 1165px) {
    max-width: 260px;
  }

  @media (max-width: 990px) {
    max-width: unset;
    margin-bottom: 0;
  }

  &:hover {
    -webkit-box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    -moz-box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    img {
        transform: scale(1.07);
    }
  }


`;

const HeaderSection = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 12px;
  color: rgba(31, 31, 31, 0.6);
  @media (max-width: 1440px) {
    max-width: 320px;
  }

  @media (max-width: 1165px) {
    max-width: 260px;
  }
  @media (max-width: 990px) {
    max-width: unset;
  }
`;
const Text = styled.div`
  margin-top: 10px;
  padding: 0 20px 20px 20px;

  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;

  }
  h2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(31, 31, 31, 0.6);

  }
  @media(max-width:768px){
    h1{
 font-size: 21px;
    }
    h2{
       font-size: 16px;
    }
  }
`;
const BlogCard = styled.div`
  display: flex;
  width: 100%;
  padding:20px 20px 0 20px;

  img {
    transition:400ms all ease-out;
    object-fit: cover;
    border-radius: 15px;
    width: 100%;
  }

  @media (max-width: 550px) {
    min-width: unset;
    width: 100%;
  }

`;
