import React from "react";
import styled from "styled-components";
import { ReactComponent as SquareDot } from "../../assets/icons/rectangle-green.svg";
import { color } from "../../assets/styles/colors";

const CardWithSquareDots = ({ data, cardSstyle, itemsStyle }) => {
  return (
    <Card style={cardSstyle}>
      {data.map((section) => (
        <Section key={section.title}>
          <h1>{section.title}</h1>
          <ItemsWrapper style={itemsStyle}>
            {section.items.map((item) => (
              <Item key={item}>
                <SquareDot />
                <p>{item}</p>
              </Item>
            ))}
          </ItemsWrapper>
        </Section>
      ))}
    </Card>
  );
};

export default CardWithSquareDots;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  background: #78786a;
  padding: 18px 25px;
  border-radius: 8px;
  width: 100%;
  @media (max-width: 990px) {
    padding: 20px;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: -0.5px;
    margin: 0;
    color: ${color.primaryLight};
  }

  @media (max-width: 990px) {
    row-gap: 15px;
    h1 {
      font-size: 18px;
      line-height: 20px;
    }
  }
`;
const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
const Item = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;

  p {
    margin: 0;
    color: ${color.primaryLight};
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
  }
  svg {
    margin-top: 7px;
    min-height: 12px;
    min-width: 12px;
  }

  @media (max-width: 990px) {
    p {
      color: ${color.primaryLight};
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
