import React from "react";
import styled from "styled-components";
import { ReactComponent as Rectangle } from "../../assets/icons/rectangle-green.svg";
const WhySmallTeamsCard = () => {
  return (
    <Card>
      <Title>
        <h1>Why small teams?</h1>
        <p>
          Smaller teams move faster, are more accountable, communicate better,
          and deliver better solutions.
        </p>
      </Title>

      <Details>
        <ElementWrapper>
          <RectangleWrapper>
            <Rectangle />
          </RectangleWrapper>
          <p>Fast decision making</p>
        </ElementWrapper>
        <ElementWrapper>
          <RectangleWrapper>
            <Rectangle />
          </RectangleWrapper>
          <p>Flexibility</p>
        </ElementWrapper>
        <ElementWrapper>
          <RectangleWrapper>
            <Rectangle />
          </RectangleWrapper>
          <p>Improved communication</p>
        </ElementWrapper>
        <ElementWrapper>
          <RectangleWrapper>
            <Rectangle />
          </RectangleWrapper>
          <p>Reduce overhead ( core compecenties)</p>
        </ElementWrapper>
        <ElementWrapper>
          <RectangleWrapper>
            <Rectangle />
          </RectangleWrapper>
          <p>Increased accountability</p>
        </ElementWrapper>
        <ElementWrapper>
          <RectangleWrapper>
            <Rectangle />
          </RectangleWrapper>
          <p>Lower costs</p>
        </ElementWrapper>
        <ElementWrapper>
          <RectangleWrapper>
            <Rectangle />
          </RectangleWrapper>
          <p>Assigned to features, not tasks</p>
        </ElementWrapper>
      </Details>
    </Card>
  );
};

export default WhySmallTeamsCard;
const Card = styled.div`
  background: #1f1f1f;
  padding: 88px 65px;
  border-radius: 8px;
  display: flex;
  @media (max-width: 990px) {
    flex-direction: column;
    padding: 30px;
  }
`;

const Title = styled.div`
display:flex;
flex-direction:column;
row-gap:50px;
min-width: 400px;

@media( max-width: 990px){
    row-gap:25px;
    min-width: unset;
  }

h1 {
    font-size:30px;
    line-height:39px;
    margin:0;
    color:white;
    font-weight:700;
}

}
p{
    font-size:20px;
    line-height:26px;
    margin:0;
        color:white;
        font-weight:400;
}


`;

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-left: 50px;

  @media (max-width: 990px) {
    margin-top: 25px;
    margin-left: 0;
  }
`;

const ElementWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: start;
  padding-left: 10px;
  min-width: 280px;
  svg {
    min-height: 12px;
    min-width: 12px;
  }
  span {
    color: rgba(255, 255, 255, 0.4);
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
  }
  @media(max-width:990px){
    min-width: unset;
  }
`;
const RectangleWrapper = styled.div`
  margin-top: 2px;
`;
