import React from "react";
import styled from "styled-components";
import ProcessGrapg from "../../assets/images/our-process-graph.webp";
import ProcessGraphMobile from "../../assets/images/our-process-graph-mobile.webp";
import AnimatedTextButton from "../Generic/AnimatedTextButton";
import { useNavigate } from "react-router-dom";

const OurProcess = () => {
  const navigate = useNavigate();
  const navigateToProcess = () => {
    navigate("/process");
  };
  return (
    <Wrapper>
      <div className="container-1440 container-content border-bottom-faded">
        <Content>
          <Text>
            <h1>Our process</h1>
            <p className="showOnBig">
              We work side-by-side with ambitious companies, brands, and
              founders to solve problems and create dazzling user-centric
              digital experiences.
            </p>
          </Text>
          <Diagram className="showOnBig">
            <ImgContainer>
              <img src={ProcessGrapg} alt="process-graph" />
            </ImgContainer>
          </Diagram>
          <div className="showOnSmall">
            <Diagram>
              <ImgContainer>
                <img
                  src={ProcessGraphMobile}
                  alt="process-graph"
                  className="image-on-small"
                />
              </ImgContainer>
            </Diagram>
          </div>
          <AnimatedTextButton
            text={"See more about our process "}
            onClick={() => navigateToProcess()}
          />
        </Content>
      </div>
    </Wrapper>
  );
};

export default OurProcess;

const Wrapper = styled.div`
  background: white;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 80px;
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  max-width: 800px;
  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 400;
    line-height: normal;
  }
  p {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
  @media(max-width:990px){
     h1 {
    font-size: 30px;

  }
  }
`;
const Diagram = styled.div`
  display: flex;
  justify-content: center;
`;

const ImgContainer = styled.div`
  display: flex;
  object-fit: contain;
  img {
    width: 70%;
    margin-left: 15%;
  }
`;
