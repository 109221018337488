import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";

const ChallengeItem = ({ number, text }) => {
  return (
    <ChallengeItemWrapper>
      <NumberWrapper>
        <h1>{number}</h1>
      </NumberWrapper>
      <DetailsWrapper>
        <p>{text}</p>
      </DetailsWrapper>
    </ChallengeItemWrapper>
  );
};
const TakeAway = ({ items, image }) => {
  return (
    <div className="container-1440 container-content border-bottom-faded position-relative">
      <TakeAwayContainer>
        <TakeAwayTextContainer>
          <h1>Take away and challenges</h1>
          <ItemsContainer>
            {items?.map((item, index) => (
              <ChallengeItem
                key={index}
                number={item.number}
                text={item.text}
              />
            ))}
          </ItemsContainer>
        </TakeAwayTextContainer>
        <div className="showOnBig">
          <TakeAwayImageContainer>
            <TaacImg>
              <img src={image} alt="taacImg"></img>
            </TaacImg>
          </TakeAwayImageContainer>
        </div>
      </TakeAwayContainer>
    </div>
  );
};

export default TakeAway;
const TakeAwayContainer = styled.div`
  display: flex;
  column-gap: 150px;
  row-gap: 50px;
  @media (max-width: 990px) {
    flex-direction: column;
  }
`;

const TakeAwayTextContainer = styled.div`
  h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 30px;
    margin-bottom: 70px;
  }
  @media (max-width: 990px) {
    h1 {
      font-size: 30px;
      line-height: 45px;
      margin-bottom: 30px;
    }
  }
`;
const TakeAwayImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;
const ChallengeItemWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 30px;
`;
const NumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #12251a;
  border-radius: 10px;
  min-height: 71px;
  min-width: 71px;
  h1 {
    margin: 0;
    font-size: 46px;
    font-weight: 400;
    line-height: 68px;
    letter-spacing: 0px;
    text-align: left;
    color: #ffffffb2;
  }
  @media (max-width: 990px) {
    min-height: 49px;
    min-width: 49px;
    h1 {
      font-size: 32px;
      line-height: 47px;
    }
  }
`;
const DetailsWrapper = styled.div`
  p {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    color: ${color.primaryDark};
  }
  @media (max-width: 990px) {
    p {
      font-size: 16px;

      line-height: 24px;
    }
  }
`;
const TaacImg = styled.div`
  display: flex;
  img {
    width: 100%;
    max-width: 360px;
    min-width: 360px;
  }
  @media (max-width: 450px) {
    img {
      min-width: unset;
    }
  }
`;
