import React from 'react'
import styled from 'styled-components'

import ProcessPageNewCommercial from './ProcessPageNewCommercial'
import ProcessPageNewSupportMaintenance from './ProcessPageNewSupportMaintenance'
import ProcessPageNewImplementation from './ProessPageNewImplementation'
import ProcessPageNewDiscovery from './ProcessPageNewDiscovery'
import { animated, useSpring } from 'react-spring'


const ProcessPageNew = () => {
    const animation = useSpring({
        from: {
            transform: "scaleY(1)",
            transformOrigin: "0% 100%",
            background: "white",
            willChange: "transform",
            transformStyle: "preserve-3d",
        },

        to: {
            transform: "scaleY(0)",
            transformOrigin: "0% 100%",
            willChange: "transform",
            transformStyle: "preserve-3d",
        },
        config: {

            duration: "2000",
        },

    });


    return (
        <>
            <AnimatedEl style={animation} />
            <Content>
                <ProcessPageNewDiscovery />
                <ProcessPageNewCommercial />
                <ProcessPageNewImplementation />
                <ProcessPageNewSupportMaintenance />
            </Content>
        </>
    )
}

export default ProcessPageNew
const Content = styled(animated.div)`
display:flex;
flex-direction:column;
overflow:hidden;
`
const AnimatedEl = styled(animated.div)`
position:absolute;
display:flex;
flex-direction:column-reverse;
width:100%;
height:100%;
z-index:3;

`