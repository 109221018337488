
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {

    const { pathname } = useLocation();
    const path = pathname.split("/");
    const path1 = path[1];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [path1]);

    return null;
}

export default ScrollToTop;

