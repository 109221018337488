import styled from "styled-components";

const SocialMediaButton = ({ icon, to, id, children }) => {
  const navigateFunction = (to) => {
    window.open(to, "_blank");
  };

  return (
    <SocialMediaBtn
      aria-label={"social-" + id.toString()}
      id={"social-" + id.toString()}
      onClick={() => navigateFunction(to)}
    >
      {icon}
      {children}
    </SocialMediaBtn>
  );
};

export default SocialMediaButton;
const SocialMediaBtn = styled.button`
  border: none;
  background: none;
  height: 38px;
  width: 38px;
`;
