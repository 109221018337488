
import styled from 'styled-components'
import { ReactComponent as Rectangle } from "../../../assets/icons/rectangle-green.svg"

const industries = [
    "Audio / Video streaming", 'AI & ML', "Transportation", "IoT", "Sports", "Betting", "SaaS", "Finance / Crypto", "Insurance", "Education", "Environmental", "Social", "GPS & Navigation", "CMS"
]
const Industries = () => {

    return (
        <Card>
            <Title>
                <h1>Industries</h1>
            </Title>
            <Elements>
                {industries.map((item) => (
                    <ElementWrapper key={item}>
                        <RectangleWrapper>
                            <Rectangle />
                        </RectangleWrapper>
                        <p>{item}</p>
                    </ElementWrapper>
                ))}

            </Elements>

        </Card>
    )
}

export default Industries

const Card = styled.div`
padding:60px 68px;
border-radius: 8px;
background: #1F1F1F;
display:flex;
flex-wrap:wrap;
column-gap:20px;
p{
    color: white;
   font-size: 18px;
font-style: normal;
font-weight: 400;
line-height:24px;
margin:0;
}

@media(max-width:990px){
    padding: 33px 35px;

    p{
        font-size: 16px;
        line-height:21px;
    }
}
@media(max-width:375px){
    min-width:unset;
    padding: 25px;
}
`
const Title = styled.div`
margin-right:100px;
min-width:200px;
h1{
    color:white;
    font-size: 32px;
font-weight: 700;
line-height: 42px;
}
@media(max-width:990px){
    h1{
        font-size: 20px;
    }
}
`
const Elements = styled.div`
display:flex;
flex-direction:column;
flex-wrap:wrap;
column-gap:30px;
max-height:200px;
@media(max-width: 990px){
    flex-direction:row;
flex-wrap:wrap;
    margin-top:30px;
    max-height:unset;
}
`
const ElementWrapper = styled.div`
display:flex;

column-gap:12px;
margin-bottom:15px;
align-items:start;
min-width:230px;
svg{
    min-height:12px;
    min-width:12px;
}
@media(max-width: 990px){
    min-width:200px;
}

span{
    color:rgba(255, 255, 255, 0.40);
}
`
const RectangleWrapper = styled.div`
margin-top:2px;
`