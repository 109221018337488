import { GET } from "../API";


export const getPostForId = (
    id,
    onSuccess,
    onError
) => {

    GET(`/blog/post_for_id/${id}`, onSuccess, onError);
};
