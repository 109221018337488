import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import AnimatedTextButton from "../Generic/AnimatedTextButton";
import ArticleCard from "./ArticleCard";
import "swiper/css";
import "swiper/css/pagination";
import "../../assets/styles/style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { ReactComponent as CarouselArrow } from "../../assets/icons/carousel-arrow-right.svg";
import { getLatestBlogPosts } from "../../API/Blog/latestPosts";
import { useNavigate } from "react-router-dom";
import SpinnerComponent from "../Generic/SpinnerComponent";
const Articles = () => {
  const [article, setArticle] = useState([]);
  const navigate = useNavigate();

  const swipeNext = () => {
    let swiper = document.querySelector("#article-swipper").swiper;
    swiper.slideNext();
  };
  const swipeBack = () => {
    let swiper = document.querySelector("#article-swipper").swiper;
    swiper.slidePrev();
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slides, setSlides] = useState(2);
  const [backDisabled, setBackDisabled] = useState(true);
  const [nextDisabled, setNextkDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  const navigateToBlog = () => {
    navigate("/blog");
  };
  const handleNavigateToPost = (id, data) => {
    navigate(`/blog/${id}`, { state: data });
  };
  const handleSlideChange = (event) => {
    const { isBeginning, isEnd } = event;
    if (isBeginning) {
      setBackDisabled(true);
    } else {
      setBackDisabled(false);
    }
    if (isEnd) {
      setNextkDisabled(true);
    } else {
      setNextkDisabled(false);
    }
  };

  const handleGetLastestArticles = () => {
    setIsLoading(true);
    getLatestBlogPosts(
      function (response) {
        setArticle(response.data);
        setIsLoading(false);
      },
      function (error) {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    handleGetLastestArticles();

    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 990) {
      setSlides(3);
    }

    if (windowWidth < 990) {
      setSlides(2);
    }

    if (windowWidth < 600) {
      setSlides(1);
    }
  }, [windowWidth]);

  return (
    <Wrapper>
      <div className="container-1440 container-content ">
        <Content>
          <h1>Latest articles</h1>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>
              Find the latest industry trends, to keep you at the top of your
              tech game.
            </h2>
            <CarouselArrows>
              <button
                disabled={backDisabled}
                name="articleBack-btn"
                id="articleBack-btn"
                aria-label="articleBack-btn"
                onClick={() => swipeBack()}
              >
                <CarouselArrow style={{ rotate: "180deg" }} />
              </button>
              <button
                disabled={nextDisabled}
                name="articleNext-btn"
                id="articleNext-btn"
                aria-label="articleNext-btn"
                onClick={() => swipeNext()}
              >
                <CarouselArrow />
              </button>
            </CarouselArrows>
          </div>
          {isLoading ? (
            <SpinnerComponent />
          ) : (
            <ArticlesWrapper>
              <Swiper
                onSlideChange={(swiperCore) => handleSlideChange(swiperCore)}
                slidesPerView={slides}
                spaceBetween={30}
                centeredSlides={false}
                pagination={windowWidth < 990}
                modules={[Navigation, Pagination]}
                className="mySwiper pag"
                id="article-swipper"
                style={{ overflow: "visible" }}
              >
                {article?.map((item, index) => (
                  <SwiperSlide key={item.title}>
                    {" "}
                    <ArticleCard
                      category={item.category}
                      title={item.title}
                      readingTime={item.reading_time}
                      img={item.cover}
                      onClick={() => handleNavigateToPost(item.id, item)}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>

              <ReadMoreContainer>
                <AnimatedTextButton
                  text={"Read more on our blog"}
                  onClick={() => navigateToBlog()}
                />
              </ReadMoreContainer>
            </ArticlesWrapper>
          )}
        </Content>
      </div>
    </Wrapper>
  );
};

export default Articles;
const Wrapper = styled.div``;
const ReadMoreContainer = styled.div`
  margin-top: 10px;

`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    color: ${color.primaryDark};
    margin: 0;
  }
  h2 {
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    margin: 0;
  }

  @media (max-width: 990px) {
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }
    row-gap: 30px;
  }
`;
const ArticlesWrapper = styled.div`
  overflow: hidden;
  padding: 30px;
  margin: -30px;
  margin-left:-50px;

  @media (max-width: 990px) {
    margin-top: 0px;
    margin: -20px;
  margin-left:-30px;
  }

  @media (max-width: 425px) {
    margin-top: 0px;
    margin: -20px;
    padding: 20px;
  }

  @media (max-width: 320px) {
    padding: 0px;
    margin: 0px;
  }
`;
const CarouselArrows = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    width: 25px;
    height: 25px;

    svg {
      stroke: black;
    }
    &:disabled {
      opacity: 0.2;
    }
  }
  @media (max-width: 990px) {
    display: none;
  }
`;
