import styled from "styled-components";
import { ReactComponent as Rectangle } from "../../../assets/icons/rectangle-green.svg";

const TechScaleUps = () => {
  return (
    <Card>
      <h1>Technology scale-ups</h1>
      <ElementWrapper>
        <RectangleWrapper>
          <Rectangle />
        </RectangleWrapper>
        <p>Need products developed fast</p>
      </ElementWrapper>
      <ElementWrapper>
        <RectangleWrapper>
          <Rectangle />
        </RectangleWrapper>
        <p>
          Don't have in-house expertise or bandwidth to support their growth
        </p>
      </ElementWrapper>
      <ElementWrapper>
        <RectangleWrapper>
          <Rectangle />
        </RectangleWrapper>
        <p>
          Have a product that lacks stability{" "}
          <span>(requiring refactoring or redesign)</span> or doesn't align with
          their strategic roadmap{" "}
          <span>
            (needs domain experts to enhance it - increase robustness, add new
            features)
          </span>
          .
        </p>
      </ElementWrapper>
    </Card>
  );
};

export default TechScaleUps;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-radius: 8px;
  background: #606054;
  padding: 50px 45px;
  min-width: 390px;

  min-height: 412px;
  height: 100%;
  h1 {
    color: white;
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
    margin-bottom: 15px;
  }
  p {
    color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    font-style: normal;
    line-height: 26px;
    font-weight: 400;
    margin: 0;
  }
  @media (max-width: 990px) {
    padding: 33px 35px;
    min-height: 357px;
    min-width: 300px;
    h1 {
      font-size: 16px;
    }
    p {
      font-size: 16px;
      line-height: 21px;
    }
  }
  @media (max-width: 375px) {
    padding: 25px;
    min-width: unset;
  }
`;
const ElementWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: start;
  padding-left: 10px;
  max-width: 450px;
  svg {
    min-height: 12px;
    min-width: 12px;
  }
  span {
    color: rgba(255, 255, 255, 0.4);
  }
`;
const RectangleWrapper = styled.div`
  margin-top: 2px;
`;
