import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as SquareDot } from "../../assets/icons/rectangle-customColor.svg";
import { ReactComponent as AppStoreDownload } from "../../assets/icons/appStore-caseStudy-icon.svg";
import { ReactComponent as AppStoreDownloadDark } from "../../assets/icons/appStore-dark-caseStudy-icon.svg";
import { ReactComponent as GooglePlayDownload } from "../../assets/icons/googlePlay-caseStudy-icon.svg";
import { ReactComponent as GooglePlayDownloadDark } from "../../assets/icons/googlePlay-dark-caseStudy-icon.svg";
import { ReactComponent as WebsiteDownload } from "../../assets/icons/website-caseStudy-icon.svg";
import { ReactComponent as WebsiteDarkDownload } from "../../assets/icons/website-dark-caseStudy-icon.svg";

import { color } from "../../assets/styles/colors";
import FeatureShowcaseElement from "./FeatureShowcaseElement";
import SetupList from "./SetupList";
import TakeAway from "./TakeAway";
import NextProjects from "./NextProjects";

import { ProjectsList } from "../../Helper/ProjectsList";
import { useNavigate, useParams } from "react-router-dom";

export const DotElement = ({ text }) => {
  return (
    <DotElementContainer>
      <SquareDot /> <p> {text}</p>
    </DotElementContainer>
  );
};

export const FeatureListDotElement = ({ type, text, textColor }) => {
  return (
    <FeatureListDotElementContainer type={type} textColor={textColor}>
      <SquareDot /> <p>{text}</p>
    </FeatureListDotElementContainer>
  );
};

export const ButtonIcon = ({ type, variant }) => {
  if (type === "ios") {
    if (variant === "dark") {
      return <AppStoreDownloadDark />
    } else {
      return <AppStoreDownload />
    }
  }
  if (type === "android") {
    if (variant === "dark") {
      return <GooglePlayDownloadDark />
    } else {
      return <GooglePlayDownload />
    }
  }
  if (type === "web") {
    if (variant === "dark") {
      return <WebsiteDarkDownload />
    } else {
      return <WebsiteDownload />
    }
  }

}
const ProjectIndividualPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [project, setProject] = useState(null);
  const handleProjectLink = (link) => {
    window.open(link)
  }
  useEffect(() => {
    let found = ProjectsList.find((item) => item.projectId === params.id);
    if (found) {
      setProject(found);
    } else {
      navigate("/projects");
    }
  }, [params]);

  return (
    <Wrapper>
      <Header>
        <HeaderLeft
          changedheader={project?.changedHeader}
          assetbg={project?.headerAssetBg}
        >
          <HeaderContent>
            <ImgWrapper>
              <img src={project?.projectHeader?.image} alt="headerImg" />
            </ImgWrapper>
          </HeaderContent>
        </HeaderLeft>
        <HeaderRight changedheader={project?.changedHeader}>
          <HeaderContentRight changedheader={project?.changedHeader}>
            <HeaderDetails changedheader={project?.changedHeader}>
              <h1>{project?.projectHeader.title}</h1>
              {project?.projectHeader.description}
              <DotsWrapper>
                {project?.projectHeader?.dots?.map((dot, index) => (
                  <FeatureListDotElement
                    key={index}
                    text={dot}
                    textColor={
                      project?.changedHeader
                        ? color.primaryDark
                        : color.primaryLight
                    }
                  />
                ))}
              </DotsWrapper>
              <br></br>

              <StoreButtonWrapper className="showOnBig">
                {project?.projectHeader?.buttons?.map((button, index) => (
                  <DownloadButton key={index} onClick={() => handleProjectLink(button.action)}>
                    <ButtonIcon type={button.type} variant={button.variant} />
                  </DownloadButton>
                ))}
              </StoreButtonWrapper>
            </HeaderDetails>
          </HeaderContentRight>
        </HeaderRight>
        <div className="showOnSmall">
          <StoreButtonWrapper>
            {project?.projectHeader?.buttons?.map((button, index) => (
              <DownloadButton key={index} onClick={() => handleProjectLink(button.action)} >
                <ButtonIcon type={button.type} variant={"dark"} />
              </DownloadButton>
            ))}
          </StoreButtonWrapper>
        </div>

      </Header>

      <div className="container-1440 container-content position-relative">
        <FeatureSection>
          <FeatureList>
            <FeaturesGroup>
              <h2>Features</h2>
              {project?.projectFeatures?.features?.map(
                (featureListItem, index) => (
                  <FeatureListDotElement
                    key={index}
                    type={"dark"}
                    text={featureListItem}
                  />
                )
              )}
            </FeaturesGroup>
          </FeatureList>
          <FeatureList>
            <FeaturesGroup>
              <h2>Launch & Maintenance</h2>
              {project?.projectFeatures?.launch?.map(
                (featureListItem, index) => (
                  <FeatureListDotElement
                    key={index}
                    type={"highlighted"}
                    text={featureListItem}
                  />
                )
              )}
            </FeaturesGroup>
          </FeatureList>
        </FeatureSection>
      </div>
      <div className="container-1440 container-content border-bottom-faded position-relativ no-padding-top no-padding-bottom">
        <FeatureShowCaseContent>
          <h1>Features showcase</h1>
          {project?.featuresShowcase?.map((item, index) => (
            <FeatureShowcaseElement
              key={index}
              title={item.title}
              text={item.text}
              img={item.img}
              reverse={index % 2 === 0}
            />
          ))}
        </FeatureShowCaseContent>
      </div>

      <SetupList
        setupList={project?.setupList}
        textColor={color.primaryLight}
      />
      <TakeAway
        items={project?.takeAway.takeAwayList}
        image={project?.takeAway.takeAwayAsset}
      />
      <NextProjects currentProjectId={project?.projectId} />
    </Wrapper>
  );
};

export default ProjectIndividualPage;
const Wrapper = styled.div`
  background: white;
padding-top:100px;
@media (max-width: 990px) {
    padding-top: 0;
  }
scroll-be
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  @media (max-width: 990px) {
    flex-direction: column;
    row-gap: 30px;
    padding: 0 20px;
  }
`;
const HeaderLeft = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  overflow: hidden;
  background: ${(props) =>
    props.changedheader ? color.darkGreen : props.assetbg};
  position: relative;
  @media (max-width: 990px) {
    justify-content: center;
    border-radius: 8px;
  }
`;
const HeaderContent = styled.div`
  display: flex;
  max-width: 688px;
  width: 100%;
`;
const HeaderContentRight = styled(HeaderContent)`
  display: flex;
  align-items: center;
  padding: 50px;
  background: ${(props) => (props.changedheader ? "#EFEFEB" : color.darkGreen)};
  height: 100%;
  @media (max-width: 990px) {
    padding: 25px;
  }
`;
const HeaderRight = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  background: ${(props) => (props.changedheader ? "#EFEFEB" : color.darkGreen)};
  @media (max-width: 990px) {
    justify-content: center;
    border-radius: 8px;
  }
`;

const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  h1 {
    color: ${(props) =>
    props.changedheader ? color.primaryDark : color.primaryLight};
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
  }

  p {
    color: ${(props) =>
    props.changedheader ? color.primaryDark : color.primaryLight};
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
  }
`;
const DotElementContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;

  p {
    color: ${color.primaryLight};
    margin: 0;
    font-size: 20px;
    font-weight: 350;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
  }

  svg {
    margin-top: 8px;
    fill: #636263;
    height: 11px;
    width: 11px;
  }
`;
const DotsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
`;
const ImgWrapper = styled.div`
  position: relative;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;
const FeatureSection = styled.div`
  display: flex;
  column-gap: 150px;
  row-gap: 50px;
  @media (max-width: 990px) {
    flex-direction: column;
    row-gap: 30px;
  }
`;
const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  padding: 50px 45px;
  border-radius: 8px;
  width: 100%;

  @media (max-width: 990px) {
    padding: 0;
  }
`;
const FeatureListDotElementContainer = styled(DotElementContainer)`
  p {
    font-weight: 400;
    color: ${(props) =>
    props.textColor ? props.textColor : color.primaryDark};
  }

  svg {
    fill: ${(props) =>
    props.type === "dark" ? color.primaryDark : color.highlightedColor};
  }
  @media (max-width: 990px) {
    p {
      font-size: 16px;
      line-height: 21px;
    }
  }
`;

const FeaturesGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  h2 {
    color: ${color.primaryDark};
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.5px;
    text-align: left;
    margin: 0 0 10px 0;
  }

  @media (max-width: 990px) {
    h2 {
      font-size: 20px;
      line-height: 26px;
    }
  }
`;

const FeatureShowCaseContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0px;
    text-align: left;
    color: ${color.primaryDark};
    margin: 0;
    margin-bottom: 70px;
  }
  @media (max-width: 990px) {
    h1 {
      font-size: 36px;
      line-height: 55px;
    }
    margin-bottom: 20px;
  }
`;
const StoreButtonWrapper = styled.div`
  display: flex;
  flex-wrap:wrap;
  gap: 20px;

`;
const DownloadButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;

`;
