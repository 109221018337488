import HdrCaseStudyAsset from "../assets/images/hdr-case-study.webp";
import DesignesyCaseStudyAsset from "../assets/images/designesy-case-study.webp";
import SemaphrCaseStudyAsset from "../assets/images/semaphr-case-study.webp";
import DezignerCaseStudyAsset from "../assets/images/dezigner-case-study.webp";
import SnifferCaseStudyAsset from "../assets/images/sniffer-case-study.webp";
import ParentingCaseStudyAsset from "../assets/images/parenthing-case-study.webp";
import TimeCaseStudyAsset from "../assets/images/time-case-study.webp";
import OneSaveCaseStudyAsset from "../assets/images/oneSave-case-study.webp";
import VoiceCallStudyAsset from "../assets/images/voiceCall-case-study.webp";
import ArmercStudyAsset from "../assets/images/armec-case-study.webp";

export const hdrCaseStudy = {
  projectId: "hdr",
  title: "HDR Plus+",
  description: (
    <p>
      Having trouble finding the right exposure or do you simply want to capture
      more out of your scene? HDR Plus+ helps you by automatically choosing the
      right EV values for detailed HDR photography. If you're an expert, you can
      tweak all your camera settings manually for more control.
    </p>
  ),
  smallDescription: "Professional HDR photos.",
  img: HdrCaseStudyAsset,
  steps: [
    "UX/UI",
    "iOS development",
    "ML development",
    "Multi-device manual testing",
  ],
  reverse: "reverse",
  navigateTo: "/projects/hdr",
};

export const designesyCaseStudy = {
  projectId: "designesy",
  title: "Designesy",
  description: (
    <p>
      Subscription-based design services - Fast, Flat-Fee, No Meetings! Take
      charge with complete control: easy payments, prioritize tasks, monitor
      real-time progress, and provide detailed feedback. All in one easy-to-use,
      user friendly-platform.
    </p>
  ),
  smallDescription: "Streamlined design services.",
  img: DesignesyCaseStudyAsset,
  steps: [
    "UX/UI",
    "Frontend development ",
    "Backend development ",
    "Multi-device manual testing",
  ],
  reverse: "no",
  navigateTo: "/projects/designesy",
};
export const armercCaseStudy = {
  projectId: "armerc",
  title: "armerc",
  description: (
    <p>
      Armerc helps users and merchants create high-quality 3D models by using 2D
      photos. With armerc, users can take pictures of their models, generate 3D
      objects, view them in AR, and share them on the web. Additionally, they
      can monitor the performance of their models.
    </p>
  ),
  smallDescription: "Incredibly detailed 3D models.",
  img: ArmercStudyAsset,
  steps: [
    "UX/UI",
    "Backend development",
    "ML development",
    "iOS Development",
    "Multi-device manual testing",
  ],
  reverse: "reverse",
  navigateTo: "/projects/armerc",
};
export const semaphrCaseStudy = {
  projectId: "semaphr",
  title: "semaphr",
  description: (
    <p>
      Semaphr is a SasS Web app that implements flexible messaging and kill
      switch for mobile applications (iOS and Android). In one platform the
      users can manage all their mobile versions, block access, notify users of
      any issues, or force them to update.
    </p>
  ),
  smallDescription: "Kill switch for your mobile apps.",
  img: SemaphrCaseStudyAsset,
  steps: [
    "UX/UI",
    "iOS development",
    "ML development",
    "Multi-device manual testing",
  ],
  reverse: "no",
  navigateTo: "/projects/semaphr",
};
export const dezignerCaseStudy = {
  projectId: "dezigner",
  title: "Dezigner",
  description: (
    <p>
      Dezigner allows users to snap pictures of their favorite pieces of art or
      furniture, collect them and place them in new spaces or mood boards. The
      app uses a combination of app-embedded Machine Learning models and
      Augmented Reality features.
    </p>
  ),
  smallDescription: "Snap, collect & place objects.",
  img: DezignerCaseStudyAsset,
  steps: [
    "UX/UI",
    "iOS development",
    "Machine Learning models development",
    "Multi-device manual testing",
  ],
  reverse: "reverse",
  navigateTo: "/projects/dezigner",
};
export const snifferCaseStudy = {
  projectId: "sniffer",
  title: "Sniffer",
  description: (
    <p>
      Sniffer is a tool that assists individuals in locating their misplaced
      Bluetooth devices by guiding them to the source of their signals.
      Additionally, this application alerts users when they have accidentally
      left a device behind.
    </p>
  ),
  smallDescription: "Bluetooth device tracker",
  img: SnifferCaseStudyAsset,
  steps: [
    "UX/UI",
    "iOS development",
    "Bluetooth Technologies",
    "Multi-device manual testing",
  ],
  reverse: "no",
  navigateTo: "/projects/sniffer",
};
export const parentingCaseStudy = {
  projectId: "parenthing",
  title: "Parenthing",
  description: (
    <p>
      The app becomes a parenting companion and a great aid in the process of
      raising children. The app advises on improving your child's skills and
      well-being, offering skill-based exercises, articles, and tests for
      children.
    </p>
  ),
  smallDescription: "Parenthing made easy.",
  img: ParentingCaseStudyAsset,
  steps: [
    "UX/UI",
    "iOS development",
    "Illustrations",
    "Backend development",
    "Landing page development",
    "Multi-device manual testing",
  ],
  reverse: "no",
  navigateTo: "/projects/parenthing",
};
export const voicecalCaseStudy = {
  projectId: "voicecal",
  title: "voicecal",
  description: (
    <p>
      With voicecal the users can track their nutritional intake using the power
      of their voice, the app works in over 60 languages and accents and makes
      calorie counting a breeze. It’s an AI-enabled application that uses
      Natural Language processing to accurately keep track of the foods you eat.
      <br></br>
    </p>
  ),
  smallDescription: "Voice-enabled intake counter.",
  img: VoiceCallStudyAsset,
  steps: [
    "UX/UI",
    "iOS development",
    "Backend development",
    "Multi-device manual testing",
  ],
  reverse: "reverse",
  navigateTo: "/projects/voicecal",
};

export const timeCaseStudy = {
  projectId: "time",
  title: "TIME",
  description: (
    <p>
      With the TIME app, users can monetize their experience and time through
      video interactions. Establish your per-minute rate, determine your
      availability, and start interacting. Engage in real video interactions.
      Watch and connect with experts, creators, and celebrities.
    </p>
  ),
  smallDescription: "Monetized video interactions ",
  img: TimeCaseStudyAsset,

  steps: [
    "UX/UI",
    "iOS development",
    "Android development",
    "Backend development",
    "Multi-device manual testing",
  ],
  reverse: "no",
  navigateTo: "/projects/time",
};
export const oneSaveCaseStudy = {
  projectId: "onesave",
  title: "OneSave/Day",
  description: (
    <p>
      By using the app, through daily simple actions, users advocate for
      environmental change, adopting a more responsible lifestyle. These actions
      include minimizing water usage in cooking and making mindful purchases.
    </p>
  ),
  img: OneSaveCaseStudyAsset,
  smallDescription: "Individual Action. Global Impact.",
  steps: [
    "UX/UI",
    "iOS development",
    "ML models development",
    "Multi-device manual testing",
  ],
  reverse: "no",
  navigateTo: "/projects/onesave",
};
