import React from "react";
import styled from "styled-components";
import "../../assets/styles/style.css";

import Domains from "../LandingPageComponents/WeWorkWith/Domains";
import TechScaleUps from "../LandingPageComponents/WeWorkWith/TechScaleUps";
import NonTechComp from "../LandingPageComponents/WeWorkWith/NonTechComp";
import Industries from "../LandingPageComponents/WeWorkWith/Industries";

const ServicesWeWorkWith = () => {
    return (
        <Wrapper>
            <div className="container-1440 container-content border-bottom-faded">
                <Content>
                    <Title>
                        <h1>We work with</h1>
                    </Title>

                    <div className="row gap-30">
                        <div className="col">
                            <TechScaleUps />
                        </div>
                        <div className="col">
                            <NonTechComp />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Domains />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Industries />

                        </div>
                    </div>
                </Content>
            </div>
        </Wrapper>
    );
};

export default ServicesWeWorkWith;

const Wrapper = styled.div`
  background: white;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;
const Title = styled.div`
margin-bottom:30px;
  h1 {
    font-size: 48px;
    font-weight: 400;
    margin: 0;
  }
  @media(max-width:990px){
    h1{
        font-size:30px;
        
    }
  }
`;
