import React from "react";
import styled from "styled-components";
import ProcessFold1 from "../components/ProcessPageComponents/ProcessFold1";
import { Outlet } from "react-router-dom";
import ProcessPageSelector from "../components/ProcessPageComponents/ProcessPageSelector";
import ProcessMobileView from "../components/ProcessPageComponents/ProcessMobileView";
import TrackingComponent from "../components/Tracking/TrackingComponent";

const ProcessPage = () => {
  return (
    <Wrapper>
      <div className="container-1440 container-content position-relative">
        <Content>
          <ProcessFold1 />
          <div className="showOnBig">
            <ProcessPageSelector />
            <Outlet />
          </div>

          <div className="showOnSmall">
            <ProcessMobileView />
          </div>
        </Content>
      </div>
      <TrackingComponent />
    </Wrapper>
  );
};

export default ProcessPage;

const Wrapper = styled.div`
  min-height: 100vh;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
`;
