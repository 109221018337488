import React from "react";
import styled from "styled-components";
import { ReactComponent as BoschLogo } from "../../assets/icons/trusted-by-bosch-logo.svg";
import { ReactComponent as HondaLogo } from "../../assets/icons/trusted-by-honda-log.svg";
import { ReactComponent as WikiLogo } from "../../assets/icons/trusted-by-wikipedia-logo.svg";
import { ReactComponent as DeloitteLogo } from "../../assets/icons/trusted-by-deloitte-logo.svg";
import { ReactComponent as AppStoreLogo } from "../../assets/icons/appStore-logo.svg";
import { ReactComponent as GooglePlayLogo } from "../../assets/icons/googlePlay-logo.svg";

import ClutchLogoComponent from "./ClutchLogoComponent";

const TrustedByCompanies = () => {

  const navigateToClutch = (e) => {
    window.open("https://clutch.co/profile/appssemble#reviews");
  };



  return (
    <>
      <div className="showOnBig">
        <Bottom>
          <TrustedBy>
            <p>Trusted by</p>

            <Group>
              <BoschLogo />
              <HondaLogo />
              <WikiLogo />
              <DeloitteLogo />
            </Group>
          </TrustedBy>
          <TopCompany>
            <p>Top development company</p>
            <Group>
              <ClutchLogoComponent onClick={navigateToClutch} />
            </Group>
          </TopCompany>
          <Featured>
            <p>Featured</p>
            <Group>
              <AppStoreLogo />
              <GooglePlayLogo />
            </Group>
          </Featured>
        </Bottom>
      </div>
    </>
  );
};

export default TrustedByCompanies;

const Bottom = styled.div`
  margin-top: 100px;
  border-top: 1px solid #3131310d;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
`;
const TrustedBy = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-right: 1px solid #3131310d;
  padding-right: 20px;
  p {
    font-size: 14px;
    color: #1e1e1eb2;
    margin: 0;
  }
`;
const Group = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  gap: 60px;
  align-items: center;

  @media (max-width: 990px) {
    gap: 20px;
  }
  @media (max-width: 990px) {
    margin-top: 10px;
  }
`;
const TopCompany = styled(TrustedBy)``;
const Featured = styled(TrustedBy)`
  border-right: none;
`;
