import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import CardWithSquareDots from "./CardWithSquareDots";
import ImplementationGraph from "../../assets/images/implementation-illustration.webp";
import ImplementationGraphMobile from "../../assets/images/implementation-ilustration-mobile.webp";
import AnimatedTextButton from "../Generic/AnimatedTextButton";
import { useNavigate } from "react-router-dom";

const ProcessPageNewImplementation = () => {
  const navigate = useNavigate();
  const DesignOutcome = [
    {
      title: "Outcome",
      items: ["UX / UI designs for the core functionality", "Prototype"],
    },
  ];
  const ClientOutcome = [
    {
      title: "Outcome",
      items: ["SoW Sign Off"],
    },
  ];
  const DevelopmentOutcome = [
    {
      title: "Outcome",
      items: [
        "Feature implementation (code, documentation & testing)",
        "Testing plan",
        "Product (web / mobile / backend) functionality",
      ],
    },
  ];

  return (
    <Content className="border-bottom-faded">
      <Title>
        <h1>3. Implementation</h1>
      </Title>

      <Section className="row">
        <div className="showOnSmall">
          <GraphContainerSmall>
            <img src={ImplementationGraphMobile} alt="implementation-graph" />
          </GraphContainerSmall>
        </div>

        <TextWrapper>
          <MaxSizeP>
            We split the entire work into features, and each feature will be
            assigned to a team. Depending on the feature, a team can have 2 or 3
            members, and teams can have one or more features to deliver during a
            statement of work.
          </MaxSizeP>
        </TextWrapper>
      </Section>

      <Section className="row">
        <LeftSide className="col">
          <CardsGroup>
            <HighlightedTextCard>
              <TextWrapper>
                <h3>Design</h3>
                <h4>
                  In the case of new products, we start with a short design
                  phase where the general look and feel of the product's core is
                  designed.
                  <br></br>
                  <br></br>
                  Prototypes are also built to test the approach with real users
                  and collect feedback. This is an iterative process that
                  incorporates feedback from stakeholders and the end users
                  until the right approach is found.
                  <br></br>
                  <br></br>
                  After this step, design and development happen in parallel.
                </h4>
              </TextWrapper>
            </HighlightedTextCard>
            <CardWithSquareDots data={DesignOutcome} />
          </CardsGroup>

          <CardsGroup>
            <HighlightedTextCard>
              <TextWrapper>
                <h3>Development</h3>
                <h4>
                  A team can have one or more assigned features they need to
                  complete. Since our teams contain all the required layers for
                  implementing the feature, they can decide and sync internally,
                  what the best approach to implement it, and how to schedule
                  them. In cases where input is needed from the client (design
                  validation for instance), this is brought up with the project
                  manager who's responsible for the product and solved with the
                  client's team.
                </h4>
              </TextWrapper>
            </HighlightedTextCard>
            <CardWithSquareDots data={DevelopmentOutcome} cardSstyle={{}} />
            <ButtonWrapper style={{ margin: "0" }} className="showOnBig">
              <AnimatedTextButton
                text={"Read more about our approach"}
                onClick={() => {
                  navigate("/approach");
                }}
              />
            </ButtonWrapper>
          </CardsGroup>
          <CardsGroup>
            <HighlightedTextCard>
              <TextWrapper>
                <h3>Client validation</h3>
                <h4>
                  Once the work for a SoW is finished, our team will do a
                  showcase to demonstrate what was implemented and how it
                  functions.
                  <br></br>
                  <br></br>
                  If needed, the client can provide feedback and request
                  changes. Once the work has been validated, the SoW is signed
                  off and the next SoW begins, until the project is completed.
                  <br></br>
                  <br></br>
                </h4>
              </TextWrapper>
            </HighlightedTextCard>
            <CardWithSquareDots data={ClientOutcome} />
          </CardsGroup>
        </LeftSide>

        <RightSide className="col showOnBig">
          <GraphContainer>
            <img src={ImplementationGraph} alt="implementation-graph" />
          </GraphContainer>
        </RightSide>
      </Section>
    </Content>
  );
};

export default ProcessPageNewImplementation;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  padding: 60px 0;
  @media (max-width: 990px) {
    row-gap: 40px;
  }
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  h1 {
    color: ${color.primaryDark};
    font-size: 48px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    margin: 0;
  }
  @media (max-width: 990px) {
    row-gap: 20px;
    h1 {
      font-size: 30px;
      line-height: 33px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  gap: 30px;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  min-width: 400px;
  @media (max-width: 650px) {
    min-width: unset;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    color: ${color.primaryDark};
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    margin: 0;
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
  }

  h3 {
    margin: 0 0 20px 0;
    color: ${color.primaryDark};
    font-size: 30px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.5px;
  }

  h4 {
    color: ${color.primaryDark};
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }

  @media (max-width: 990px) {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
    h3 {
      font-size: 30px;
      line-height: 27px;
    }
    h4 {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
const RightSide = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  row-gap: 30px;

  @media (max-width: 916px) {
    margin-top: 30px;
  }
  @media (max-width: 375px) {
    min-width: unset;
  }

  @media (max-width: 990px) {
    justify-content: center;
  }
`;
const GraphContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: end;
  height: 100%;

  img {
    position: relative;
    min-height: 95%;
    max-height: 1630px;
  }

  @media (max-width: 990px) {
    justify-content: center;
  }
`;
const GraphContainerSmall = styled(GraphContainer)`
  padding-top: 20px;
  padding-bottom: 40px;

  img {
    width: 80%;
  }
`;
const HighlightedTextCard = styled.div`
  background: #efefeb;
  padding: 35px;
  border-radius: 8px;
  // min-height: 394px;
  @media (max-width: 990px) {
    padding: 30px 20px;
    min-height: 0px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: ${color.primaryDark};
    margin: 0;
  }
`;
const CardsGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;
const MaxSizeP = styled.p`
  max-width: 900px !important;
`;
