import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import CardWithSquareDots from "./CardWithSquareDots";

const ProcessOngoingCommercial = () => {
  const OutcomeCard = [
    {
      title: "Questions",
      items: [
        "Is this a must-have?",
        "Could we ship without this?",
        "What happens if we don’t do this?",
        "Is it a new problem, or a pre-existing one that customers already live with?",
        "How likely is this case or condition to occur?",
        "When this case occurs, which customers see it? Is it a core feature, or an edge case?",
        "What’s the actual impact of this case/condition if it occurs?",
        "When something doesn’t work well for a particular use case, how aligned is that use case with our intended audience?",
      ],
    },
  ];

  return (
    <Content className="border-bottom-faded">
      <Title>
        <h1>2. Commercials and scope hammering</h1>
      </Title>

      <Section className="row">
        <LeftSide className="col  align-items-center justify-content-center">
          <TextWrapper>
            <p>
              At the beginning of a new SoW, the client (stakeholders) decide on
              what features/proposals to work on. This will keep the scope fixed
              for the next 4 to 6 weeks cycle but allows flexibility for feature
              items.
            </p>
          </TextWrapper>
        </LeftSide>

        <RightSide className="col">
          <CardWithSquareDots
            data={OutcomeCard}
            itemsStyle={{ flexDirection: "column" }}
          />
        </RightSide>
      </Section>
    </Content>
  );
};

export default ProcessOngoingCommercial;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  padding: 60px 0;
  @media (max-width: 990px) {
    row-gap: 40px;
  }
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  h1 {
    color: ${color.primaryDark};
    font-size: 48px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    color: ${color.primaryDark};
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    margin: 0;
  }
  @media (max-width: 990px) {
    row-gap: 20px;
    h1 {
      font-size: 30px;
      line-height: 33px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

const Section = styled.div`
  display: flex;
  column-gap: 30px;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;

row-gap:30px;
min-width:400px;
@media(max-width:500px){
    min-width:unset;
}
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    color: ${color.primaryDark};
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    margin: 0;
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
  }
  @media (max-width: 990px) {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;
const RightSide = styled.div`
  display: flex;
  width: 100%;
  min-width: 400px;
  padding: 30px 0;
  img {
    object-fit: contain;
    width: 100%;
    max-height: 362px;
    min-width: 100px;
  }

  @media (max-width: 375px) {
    min-width: unset;
  }
`;
