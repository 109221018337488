import React from "react";
import styled from "styled-components";

import { color } from "../../assets/styles/colors";
const FeatureShowcaseElement = ({ reverse, title, text, img }) => {
  return (
    <Container reverse={reverse}>
      <TextContainer>
        <h1>{title}</h1>
        <p>{text}</p>
      </TextContainer>
      <ImgContainer reverse={reverse}>
        <img src={img} alt="caseStudy" />
      </ImgContainer>
    </Container>
  );
};

export default FeatureShowcaseElement;

const Container = styled.div`
  display: flex;

  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  gap: 50px;
  padding: 0 0 80px 0;
  @media (max-width: 990px) {
    flex-direction: column-reverse;
    padding: 0 0 20px 0;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  max-width: 660px;
  justify-content: center;

  h1 {
    width: fit-content;
    font-size: 30px !important;
    font-weight: 700;
    line-height: 45px !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
    padding: 0 0 10px 0;
    border-bottom: 1px solid ${color.highlightedColor};
  }
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    color: ${color.primaryDark};
  }

  @media (max-width: 990px) {
    min-width: unset;
    max-width: unset;
    h1 {
      font-size: 24px;
      line-height: 36px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
const ImgContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(props) => (props.reverse ? "start" : "end")};
  object-fit: cover;
  img {
    max-width: 435px;

  }

  @media (max-width: 990px) {
    justify-content: center;
  }
   @media (max-width: 550px) {
    img {
      width:100%;
    }
  }
`;
