import React, { useEffect, useState } from "react";
import styled from "styled-components";

import "swiper/css";
import "swiper/css/pagination";
import "../../../assets/styles/style.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import HDRCard from "../../../assets/images/hdr-plus-asset.webp";
import HDRCardMobile from "../../../assets/images/hdr-plus-mobile-asset.webp";

import DesignesyCard from "../../../assets/images/designesy-asset.webp";
import DesignesyCardMobile from "../../../assets/images/designesy-mobile-asset.webp";

import Semaphr from "../../../assets/images/semaphr-asset.webp";
import SemaphrMobile from "../../../assets/images/semaphr-mobile-asset.webp";

import Time from "../../../assets/images/time-asset.webp";
import TimeMobile from "../../../assets/images/time-mobile-asset.webp";

import { ReactComponent as CarouselArrow } from "../../../assets/icons/carousel-arrow-right.svg";
import AnimatedTextButton from "../../Generic/AnimatedTextButton";
import { color } from "../../../assets/styles/colors";
import { useNavigate } from "react-router-dom";

let cards = [
  {
    img: HDRCard,
    mobile_img: HDRCardMobile,
    title: "HDR Plus+",
    description: "Professional HDR photos.",
    link: "/projects/hdr",
  },
  {
    img: DesignesyCard,
    mobile_img: DesignesyCardMobile,
    title: "Designesy",
    description: "Subscription-based design services (UX/UI & branding).",
    link: "/projects/designesy",
  },
  {
    img: Time,
    mobile_img: TimeMobile,
    title: "Time",
    description: "Genuine video interactions.",
    link: "/projects/time",
  },
  {
    img: Semaphr,
    mobile_img: SemaphrMobile,
    title: "Semaphr",
    description: "All-in-one kill switch for your mobile apps.",
    link: "/projects/semaphr",
  },
];

const Projectcard = ({
  img,
  mobile_img,
  title,
  description,
  windowWidth,
  link,
}) => {
  const navigate = useNavigate();

  return (
    <CardWrapper
      onClick={() => {
        navigate(link);
      }}
    >
      {windowWidth > 990 && (
        <div className="showOnBig">
          <ImgContainerBig>
            <img src={img} height={472} alt="fol2dimg"></img>
          </ImgContainerBig>
        </div>
      )}

      {windowWidth < 990 && (
        <div className="showOnSmall">
          <ImgContainer>
            <img src={mobile_img} alt="fol2dimg"></img>
          </ImgContainer>
        </div>
      )}

      <TextWrapperCard>
        <h1>{title}</h1>
        <p>{description}</p>
      </TextWrapperCard>
    </CardWrapper>
  );
};

const Fold2 = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slides, setSlides] = useState(2);
  const [backDisabled, setBackDisabled] = useState(true);
  const [nextDisabled, setNextkDisabled] = useState(false);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };
  const swipeNext = () => {
    let swiper = document.querySelector("#project-swiper").swiper;

    swiper.slideNext();
  };

  const swipeBack = () => {
    let swiper = document.querySelector("#project-swiper").swiper;
    swiper.slidePrev();
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  const handleSlideChange = (event) => {
    const { isBeginning, isEnd } = event;
    if (isBeginning) {
      setBackDisabled(true);
    } else {
      setBackDisabled(false);
    }
    if (isEnd) {
      setNextkDisabled(true);
    } else {
      setNextkDisabled(false);
    }
  };

  useEffect(() => {
    if (windowWidth > 990) {
      setSlides(2);
    }

    if (windowWidth < 990) {
      setSlides(1);
    }
  }, [windowWidth]);

  return (
    <Wrapper id="fold2">
      <div className="container-1440 container-content overflow-hidden">
        <Title>
          <h1>Previous projects</h1>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h2>Check some of our selected case studies.</h2>
            <CarouselArrows>
              <button
                disabled={backDisabled}
                id="f2back"
                aria-label="f2back"
                onClick={() => swipeBack()}
              >
                <CarouselArrow style={{ rotate: "180deg" }} />
              </button>
              <button
                disabled={nextDisabled}
                id="f2next"
                aria-label="f2next"
                onClick={() => swipeNext()}
              >
                <CarouselArrow />
              </button>
            </CarouselArrows>
          </div>
        </Title>
        <CarouselWrapper>
          <Swiper
            onSlideChange={(swiperCore) => handleSlideChange(swiperCore)}
            slidesPerView={slides}
            spaceBetween={32}
            centeredSlides={false}
            navigation={true}
            pagination={windowWidth < 990}
            modules={[Pagination, Navigation]}
            className="mySwiper pag project-swiper"
            id="project-swiper"
            style={{ overflow: "visible" }}
          >
            {cards.map((item, index) => (
              <SwiperSlide key={index} style={{ display: "flex" }}>
                <Projectcard
                  windowWidth={windowWidth}
                  img={item.img}
                  mobile_img={item.mobile_img}
                  title={item.title}
                  description={item.description}
                  link={item.link}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </CarouselWrapper>
        <MoreProjectBtnWrapper>
          <AnimatedTextButton
            text={"View more projects"}
            light={"light"}
            onClick={() => navigate("/projects")}
          />
        </MoreProjectBtnWrapper>
      </div>
    </Wrapper>
  );
};

export default Fold2;

const Wrapper = styled.div`
  background: ${color.fold2Bg};
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  h1 {
    font-size: 40px;
    font-weight: 400;
    line-height: normal;
    color: ${color.primaryLight};
    margin: 0;
  }
  h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    color: ${color.primaryLight};
    margin: 0;
  }
  @media (max-width: 990px) {
    row-gap: 30px;
    h1 {
      font-size: 30px;
      line-height: 33px;
    }
    h2 {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
const CarouselWrapper = styled.div`
  margin-top: 40px;
  min-height: 200px;
  overflow: clip;
  padding: 40px 30px 30px 30px;
  margin: 40px -30px 30px -30px;

  @media (max-width: 990px) {
    margin-top: 0px;
    margin-bottom: -30px;
  }
`;
const CardWrapper = styled.div`
  transition: 400ms all ease-out;
  border-radius: 8px;
  background: #727367;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  row-gap: 15px;
  min-width: 350px;
cursor:pointer;
  &:hover {
    -webkit-box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    -moz-box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
  }

  img {
    transition: 400ms all ease-out;
    &:hover {
      transform: scale(1.07);
    }
  }

  @media (max-width: 990px) {
    img {
      width: 100%;
    }
  }

  @media (max-width: 400px) {
    min-width: unset;
  }
`;
const CarouselArrows = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    width: 25px;
    height: 25px;
    svg {
      stroke: white;
    }
    &:disabled {
      opacity: 0.3;
    }
  }
  @media (max-width: 990px) {
    display: none;
  }
`;
const TextWrapperCard = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    color: ${color.primaryLight};
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.54px;
    margin: 0;
    color: ${color.primaryLight};
  }
  @media (max-width: 990px) {
    h1 {
      font-size: 20px;
      line-height: 22px;
    }
  }
`;
const ImgContainer = styled.div`
  overflow: clip;
  border-radius: 8px;
  img {
    max-height: 600px;
  }
`;
const ImgContainerBig = styled.div`
overflow:clip;
border-radius:12px;

img{    
max-height:472px;
}

@media (max-width:1300px){
img{
    height:450px;
}
@media (max-width:1200px){
    img{
        height:400px;
    }
}
`;
const MoreProjectBtnWrapper = styled.div`
  margin: 30px 0 10px 0;
`;
