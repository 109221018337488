import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AnimatedTextButton from "../Generic/AnimatedTextButton";
import { ReactComponent as SquareDot } from "../../assets/icons/rectangle-green.svg";
import { color } from "../../assets/styles/colors";
import "swiper/css";
import "swiper/css/pagination";
import "../../assets/styles/style.css";
import { useNavigate } from "react-router-dom";
import {
  armercCaseStudy,
  designesyCaseStudy,
  dezignerCaseStudy,
  hdrCaseStudy,
  oneSaveCaseStudy,
  parentingCaseStudy,
  semaphrCaseStudy,
  snifferCaseStudy,
  timeCaseStudy,
  voicecalCaseStudy,
} from "../../Helper/ProjectsSmallCardsList";

const SingleItem = ({
  reverse,
  title,
  description,
  img,
  column,
  steps,
  borderbottom = null,
  widthDimension,
  buttonAction,
}) => {
  return (
    <SingleItemWrapper
      reverse={reverse}
      column={column}
      borderbottom={borderbottom}
      onClick={buttonAction}
    >
      <TextContainer>
        <h1>{title}</h1>
        {widthDimension <= 990 && (
          <ImgContainer onClick={buttonAction} column={column}>
            <img src={img} alt="caseStudy" />
          </ImgContainer>
        )}
        {description}
        <DevStepsWrapper>
          {steps?.map((element, index) => (
            <Item key={index}>
              <SquareDot />
              <p>{element}</p>
            </Item>
          ))}
        </DevStepsWrapper>
        <AnimatedTextButton text={"See more"} onClick={buttonAction} />
      </TextContainer>
      {widthDimension > 990 && (
        <ImgContainer onClick={buttonAction}>
          <img src={img} alt="caseStudy" />
        </ImgContainer>
      )}
    </SingleItemWrapper>
  );
};

const StudiesFold = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  return (
    <Wrapper>
      <div className="container-1440 container-content border-bottom-faded position-relative">
        <ContentBigScreen>
          <SingleItem
            reverse={hdrCaseStudy.reverse}
            title={hdrCaseStudy.title}
            description={hdrCaseStudy.description}
            steps={hdrCaseStudy.steps}
            img={hdrCaseStudy.img}
            borderbottom={true}
            widthDimension={windowWidth}
            buttonAction={() => navigate(hdrCaseStudy.navigateTo)}
          />
          <SingleItem
            reverse={designesyCaseStudy.reverse}
            title={designesyCaseStudy.title}
            description={designesyCaseStudy.description}
            steps={designesyCaseStudy.steps}
            img={designesyCaseStudy.img}
            borderbottom={true}
            widthDimension={windowWidth}
            buttonAction={() => navigate(designesyCaseStudy.navigateTo)}
          />
          <SingleItem
            reverse={armercCaseStudy.reverse}
            title={armercCaseStudy.title}
            description={armercCaseStudy.description}
            steps={armercCaseStudy.steps}
            img={armercCaseStudy.img}
            borderbottom={true}
            widthDimension={windowWidth}
            buttonAction={() => navigate(armercCaseStudy.navigateTo)}
          />
          <SingleItem
            reverse={semaphrCaseStudy.reverse}
            title={semaphrCaseStudy.title}
            description={semaphrCaseStudy.description}
            steps={semaphrCaseStudy.steps}
            img={semaphrCaseStudy.img}
            borderbottom={true}
            widthDimension={windowWidth}
            buttonAction={() => navigate(semaphrCaseStudy.navigateTo)}
          />
          <DoubleItem borderbottom={true}>
            <SingleItem
              reverse={timeCaseStudy.reverse}
              title={timeCaseStudy.title}
              description={timeCaseStudy.description}
              img={timeCaseStudy.img}
              steps={timeCaseStudy.steps}
              column={"column"}
              widthDimension={windowWidth}
              buttonAction={() => navigate(timeCaseStudy.navigateTo)}
            />
            <SingleItem
              reverse={oneSaveCaseStudy.reverse}
              title={oneSaveCaseStudy.title}
              description={oneSaveCaseStudy.description}
              img={oneSaveCaseStudy.img}
              steps={oneSaveCaseStudy.steps}
              column={"column"}
              widthDimension={windowWidth}
              buttonAction={() => navigate(oneSaveCaseStudy.navigateTo)}
            />
          </DoubleItem>

          <SingleItem
            reverse={dezignerCaseStudy.reverse}
            title={dezignerCaseStudy.title}
            description={dezignerCaseStudy.description}
            steps={dezignerCaseStudy.steps}
            img={dezignerCaseStudy.img}
            borderbottom={true}
            widthDimension={windowWidth}
            buttonAction={() => navigate(dezignerCaseStudy.navigateTo)}
          />
          <SingleItem
            reverse={snifferCaseStudy.reverse}
            title={snifferCaseStudy.title}
            description={snifferCaseStudy.description}
            steps={snifferCaseStudy.steps}
            img={snifferCaseStudy.img}
            borderbottom={true}
            widthDimension={windowWidth}
            buttonAction={() => navigate(snifferCaseStudy.navigateTo)}
          />

          <DoubleItem borderbottom={true}>
            <SingleItem
              reverse={parentingCaseStudy.reverse}
              title={parentingCaseStudy.title}
              description={parentingCaseStudy.description}
              steps={parentingCaseStudy.steps}
              img={parentingCaseStudy.img}
              column={"column"}
              widthDimension={windowWidth}
              buttonAction={() => navigate(parentingCaseStudy.navigateTo)}
            />
            <SingleItem
              reverse={voicecalCaseStudy.reverse}
              title={voicecalCaseStudy.title}
              description={voicecalCaseStudy.description}
              img={voicecalCaseStudy.img}
              steps={voicecalCaseStudy.steps}
              column={"column"}
              widthDimension={windowWidth}
              buttonAction={() => navigate(voicecalCaseStudy.navigateTo)}
            />
          </DoubleItem>
        </ContentBigScreen>
      </div>
    </Wrapper>
  );
};

export default StudiesFold;

const Wrapper = styled.div`
  background: white;
`;
const ContentBigScreen = styled.div`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  @media (max-width: 990px) {
    row-gap: 30px;
  }
`;

const SingleItemWrapper = styled.div`
cursor:pointer;
border-radius:10px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${(props) => (props.column ? "613px" : "unset")};
  flex-direction: ${(props) =>
    props.column === "column"
      ? "column-reverse"
      : props.reverse === "reverse"
        ? "row"
        : "row-reverse"};

  gap: 50px;
  padding: 0 0 80px 0;
  border-bottom: ${(props) => props.borderbottom && "1px solid #F4F5F4"};
  transition: 400ms all ease-out;

  @media (max-width: 990px) {
    flex-direction: column-reverse;
    padding: 0 0 40px 0;
    max-width: unset;
  }

  @media(max-width:425px){
    &:hover {
      -webkit-box-shadow:none;
      -moz-box-shadow:none;
      box-shadow:none;
     padding:inherit;
    }
  }
   
   
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;

  h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 54px;
    margin: 0;
     color:${color.primaryDark};
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    color:${color.primaryDark};
  }
  @media(max-width:990px){
    h1{
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    }
   

`;
const ImgContainer = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
  border-radius: 14px;
  transition: 400ms all ease-out;

  overflow: hidden;

  img {
    max-height: 408px;
    width: 100%;
    border-radius: 8px;
    transition: 400ms all ease-out;
  }

  &:hover {
    -webkit-box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    -moz-box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    img {
      transform: scale(1.07);
    }
  }

  @media (max-width: 990px) {
    img {
      max-height: unset;
    }
  }
`;

const DoubleItem = styled.div`
  display: flex;
  gap: 30px;
  align-items: start;
  border-bottom: ${(props) => props.borderbottom && "1px solid #F4F5F4"};
  @media (max-width: 990px) {
    flex-direction: column;
  }
`;
const DevStepsWrapper = styled.div`
  background: #f4f5f4;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 20px;
  padding: 36px 20px;
`;
const Item = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;

  p {
    margin: 0;
    color: ${color.primaryDark};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  svg {
    margin-top: 7px;
    min-height: 12px;
    min-width: 12px;
  }
`;
