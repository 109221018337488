import React from "react";
import styled from "styled-components";

import AnimatedTextButton from "../../Generic/AnimatedTextButton";
import { color } from "../../../assets/styles/colors";
import { useNavigate } from "react-router-dom";

const Fold3 = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div className="container-1440 container-content border-bottom-faded">
        <div className="row column-gap-5">
          <div className="col">
            <TitleSide>
              <h1>
                Software and technology outsourcing is <span>broken</span>
              </h1>
              <p>
                Small and medium-sized companies are underserved, they need to
                move fast, have limited technical experience, have budget
                constraints, and need reliable digital solutions that can scale
                and are aligned with their roadmap.
              </p>
              <TextWithBackground>
                <p>
                  We understand the pains small and medium-sized companies have
                  to go through when working on digital products.
                </p>
              </TextWithBackground>
              <div className="showOnBig">
                <ButtonWrapper>
                  <AnimatedTextButton
                    onClick={() => navigate("/services")}
                    text={"Read more about our services"}
                    style={{ marginTop: "30px" }}
                  />
                </ButtonWrapper>
              </div>
            </TitleSide>
          </div>
          <div className="col">
            <DescriptionSide>
              <h2>
                Typical software companies sell head counts instead of digital
                solutions, and projects more frequently than not go over the
                deadlines and the agreed budgets. It's in the interest of
                outsourcing companies to increase the complexity of the product,
                add more people to the project and go over the deadlines. This
                results in missed deadlines, increased costs, and most
                importantly products that are not aligned with your company's
                goals, they are over engineered, hard to change, and inflexible.
                <br></br>
                <br></br>
                We know this is the norm, and have seen this happening countless
                times, so we decide to do better. We sell digital solutions
                instead of headcounts, take full responsibility for what we
                deliver, and deliver fast.
              </h2>
            </DescriptionSide>
            <div className="showOnSmall">
              <ButtonWrapper>
                <AnimatedTextButton
                  onClick={() => navigate("/services")}
                  text={"Read more about our services"}
                  style={{ marginTop: "30px" }}
                />
              </ButtonWrapper>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Fold3;
const Wrapper = styled.div`
  background: white;
`;
const TitleSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  min-width: 300px;

  h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    margin: 0 0 25px 0;
    color: #1f1f1f;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    color: #1f1f1f;
  }
  span {
    padding: 0 2px;
    background: ${color.highlightedColor};
  }
  @media (max-width: 990px) {
    margin-top: 30px;

    h1 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
    }
  }
  @media (max-width: 400px) {
    min-width: 200px;
  }
`;
const DescriptionSide = styled.div`
  min-width: 300px;

  h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
  }

  @media (max-width: 990px) {
    margin-top: 20px;

    h2 {
      font-size: 18px;
      line-height: 27px;
    }
  }
  @media (max-width: 400px) {
    min-width: 200px;
  }
`;
const TextWithBackground = styled.div`
  border-radius: 4px;
  background: #efefeb;
  padding: 20px 15px;
`;
const ButtonWrapper = styled.div`
  p {
    line-height: 21px;
    font-weight: 400;
    font-size: 16px;
  }
`;
