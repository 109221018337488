import { POST } from "../API";


export const sendContactEmail = (
    email,
    name,
    description,
    phone,
    budget,
    onSuccess,
    onError
) => {

    let data = {
        email: email,
        name: name,
        description: description,
        phone: phone,
        budget: budget
    }

    POST(`/actions/send_contact_email`, data, onSuccess, onError);
};
