import React from "react";
import styled from "styled-components";
import BookMeetingCard from "./BookMeetingCard";

const HowWork = () => {
  return (
    <Wrapper>
      <div className="container-1440 container-content border-bottom-faded">
        <Content>
          <TextWrapper>
            <h1>How does it work?</h1>
            <Item>
              <Number>
                <p>1</p>
              </Number>
              <Description>
                <h2>Introductory call</h2>
                <p>
                  Book a meeting to assess your needs and the viability of your
                  project
                </p>
              </Description>
            </Item>
            <Item>
              <Number>
                <p>2</p>
              </Number>
              <Description>
                <h2>Discovery</h2>
                <p>
                  Participate in a product definition/discovery workshop{" "}
                  <span>
                    {" "}
                    (discovery workshop - what to build, for who and how it
                    should look like)
                  </span>
                  <br></br>
                  <br></br>
                  We'll follow up with our vision - user experience and
                  technical approach together with a delivery timeline and costs
                </p>
              </Description>
            </Item>
            <Item>
              <Number>
                <p>3</p>
              </Number>
              <Description>
                <h2>Scope & Implementation</h2>
                <p>
                  You decide what needs to be built{" "}
                  <span> (priorities, timeline)</span>, we handle the rest
                </p>
              </Description>
            </Item>
          </TextWrapper>

          <Right className="showOnBig">
            <div className=" d-flex w-100 justify-content-end">
              <BookMeetingCard id={"1"} maxwidth={"500px"} />
            </div>
          </Right>
        </Content>

        <div className="showOnSmall">
          <CardWrapper>
            <BookMeetingCard id={"2"} maxwidth={"500px"} />
          </CardWrapper>
        </div>
      </div>
    </Wrapper>
  );
};

export default HowWork;
const Wrapper = styled.div`
  background: white;
`;
const Content = styled.div`
  display: flex;
  column-gap: 20px;
  border-radius: 8px;
  background: #606054;
  padding: 46px 38px;

  @media (max-width: 990px) {
    flex-direction: column;
    padding: 40px 20px;
  }
`;
const Item = styled.div`
  display: flex;
  column-gap: 15px;
  @media (max-width: 990px) {
    column-gap: 10px;
  }
`;
const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #78786a;
  border-radius: 10px;
  max-height: 52px;
  max-width: 52px;
  width: 100%;
  height: 100%;

  p {
    margin: 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0px;
    text-align: left;
    color: #ffffffb2 !important;
  }
`;
const Description = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  p {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }

  h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: -0.5px;
    margin: 0;
  }

  span {
    color: rgba(255, 255, 255, 0.3);
  }

  @media (max-width: 990px) {
    row-gap: 24px;
  }
  @media (max-width: 990px) {
    h2 {
      margin-top: 5px;
    }
    p {
      margin-left: -50px;
    }
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 40px;
  min-width: 350px;
  max-width: 440px;
  margin-left: 30px;
  width: 100%;

  @media (max-width: 990px) {
    padding: 0;
    margin-bottom: 40px;
    margin-left: 0;
    max-width: unset;
    margin-top: 0;
  }

  @media (max-width: 555px) {
    min-width: unset;
  }

  h1 {
    color: #fff;
    font-size: 48px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.495px;
    margin: 0;
  }

  @media (max-width: 990px) {
    h1 {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
`;
const Right = styled.div`
  display: flex;
  justify-content: end;
  margin-left: auto;
  width: 100%;
  @media (max-width: 990px) {
    justify-content: center;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
