import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import CardWithSquareDots from "./CardWithSquareDots";
import SupportIlustration from "../../assets/images/support-illustration.webp";

const ProcessPageNewSupportMaintenance = () => {
  const OutcomeCard = [
    {
      title: "Outcome",
      items: ["New functionalities", "Improvements to the product"],
    },
  ];

  return (
    <Content className="border-bottom-faded">
      <Title>
        <h1>4. Support & Maintenance</h1>
      </Title>

      <Section className="row">
        <LeftSide className="col">
          <TextWrapper>
            <p>
              <div className="showOnSmall">
                <RightSide className="col ">
                  <ImgWrapperSmall>
                    <img src={SupportIlustration} alt="discovery-workshop" />
                  </ImgWrapperSmall>
                </RightSide>
              </div>
              Once all the features in the SoW are delivered, a new SoW is
              required for any new features or changes. When the client requests
              any changes, or new functionality, just like in the case of a new
              product, we start with the discovery phase, build a solution
              overview, handle the commercial sides, and deliver it.
              <br></br>
              <br></br>
              In terms of bugs, anything that was introduced by our team, or if
              the app does not function properly, according to the specs, we're
              fixing it, free of charge, up to 1 year after the product was
              released.
              <br></br>
              <br></br>
              Anything that happened due to some 3rd party changes, libraries,
              or service updates is considered a change request / new feature,
              and it is not included in the free bug fixes.
            </p>
          </TextWrapper>

          <CardWrapper>
            <CardWithSquareDots
              data={OutcomeCard}
              itemsStyle={{ flexDirection: "column" }}
            />
          </CardWrapper>
        </LeftSide>

        <RightSide className="col showOnBig">
          <ImgWrapper>
            <img src={SupportIlustration} alt="discovery-workshop" />
          </ImgWrapper>
        </RightSide>
      </Section>
    </Content>
  );
};

export default ProcessPageNewSupportMaintenance;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  padding: 60px 0;
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  h1 {
    color: ${color.primaryDark};
    font-size: 48px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    color: ${color.primaryDark};
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    margin: 0;
  }
  @media (max-width: 990px) {
    row-gap: 20px;
    h1 {
      font-size: 30px;
      line-height: 33px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

const Section = styled.div`
  display: flex;
  gap:30px;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  min-width: 400px;

  @media (max-width: 650px) {
    min-width: unset;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    color: ${color.primaryDark};
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    margin: 0;
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
  }
  @media (max-width: 990px) {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;
const RightSide = styled.div`
  display: flex;
  row-gap: 60px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content:end;
  min-width: 400px;
  @media (max-width: 375px) {
    min-width: unset;
  }
  @media (max-width: 990px) {

    justify-content:center;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  justify-content: end;
  min-width: 400px;
  width: 100%;
  p {
    color: ${color.primaryLight};
  }

  @media (max-width: 990px) {
    min-width: unset;
  }
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content:end;
  width: 80%;

  img {
    object-fit: contain;
    max-height: 362px;
    min-width: 100px;
  }

  @media (max-width: 990px) {
    min-width: unset;
    justify-content:center;
  }

`;

const ImgWrapperSmall = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  img {
    object-fit: contain;
    max-height: 362px;
    min-width: 100px;
    width: 80%;
  }

`;
