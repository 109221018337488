import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import { ReactComponent as AppssembleLogo } from "../../assets/icons/appssemble-footer-logo.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/icons/social-media-likedin-icon.svg";
import { ReactComponent as GitIcon } from "../../assets/icons/social-media-git-icon.svg";
import { ReactComponent as XIcon } from "../../assets/icons/social-media-x-icon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/social-media-instagram-icon.svg";
import { ReactComponent as NetworkIcon } from "../../assets/icons/social-media-network-icon.svg";
import SocialMediaButton from "./SocialMediaButton";
import {
  CONTACT_EMAIL,
  GIT_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  X_LINK,
  DRIBBLE_LINNK,
} from "../../Helper/ConstantsValue";
import AnimatedTextButton from "../Generic/AnimatedTextButton";

const year = new Date().getFullYear();

const socialMedia = [
  {
    icon: <LinkedInIcon />,
    to: LINKEDIN_LINK,
  },
  {
    icon: <GitIcon />,
    to: GIT_LINK,
  },
  {
    icon: <NetworkIcon />,
    to: DRIBBLE_LINNK,
  },
  {
    icon: <XIcon />,
    to: X_LINK,
  },
  {
    icon: <InstagramIcon />,
    to: INSTAGRAM_LINK,
  },
];
const locations = [
  {
    city: "New York",
    adress: "1740 Broadway, 15th Floor, 10019",
  },
  {
    city: "London",
    adress: "Kemp House, 160 City, Road, EC1V 2NX",
  },
  {
    city: "Cluj-Napoca",
    adress: "Blvd. 21 Decembrie 1989, 95-97 400603",
  },
];

const Footer = () => {
  const handleContactClick = () => {
    window.location = `mailto:${CONTACT_EMAIL}`;
  };

  return (
    <FooterWrapper>
      <div className="container-1440 ">
        <div className="row m-0">
          <div className="col mb-4 p-0">
            <AppssembleLogo />
          </div>
        </div>

        <div className="row column-gap-5 m-0">
          <div className="col p-0 ">
            <ContactWrapper>
              <AnimatedTextButton
                text={CONTACT_EMAIL}
                onClick={() => handleContactClick()}
              />
            </ContactWrapper>
            <SocialMediaWrapper>
              {socialMedia.map((item, index) => (
                <SocialMediaButton
                  key={index}
                  icon={item.icon}
                  to={item.to}
                  id={item.to}
                />
              ))}
            </SocialMediaWrapper>
          </div>

          <div className="col p-0">
            <LocationWrapper>
              {locations.map((item, index) => (
                <LocationGroup key={index}>
                  <h1>{item.city}</h1>
                  <p>{item.adress}</p>
                </LocationGroup>
              ))}
            </LocationWrapper>
          </div>
        </div>

        <div className="showOnSmall">
          <div className="row m-0 mt-3">
            <div className="col p-0 ">
              <ContactWrapper style={{ borderTop: "1px solid #1f1f1f1f" }}>
                <div style={{ marginTop: "20px" }}>
                  <h1>Support</h1>
                  <SmallPolicyWrapper>
                    <PolicySmall>
                      <button id="jobs-btn" name="jobs-btn">
                        <a href="/jobs" target="blank">
                          Jobs
                        </a>
                      </button>
                    </PolicySmall>
                    <PolicySmall>
                      <button id="privacy-btn" name="privacy-btn">
                        <a href="/privacy-policy" target="blank">
                          Privacy Policy
                        </a>
                      </button>
                    </PolicySmall>
                    <PolicySmall style={{ border: "none" }}>
                      <button id="cookie-policy-btn" name="cookie-policy-btn">
                        <a href="/cookie-policy" target="blank">
                          Cookie Policy
                        </a>
                      </button>
                    </PolicySmall>
                  </SmallPolicyWrapper>
                </div>
              </ContactWrapper>
            </div>
          </div>
        </div>

        <BottomLine>
          <CopyRight>
            <p>
              Copyright © {year} <span>appssemble</span>. All rights reserved.
            </p>
          </CopyRight>
          <div className="showOnBig">
            <div className=" col mt-1 p-0  d-flex">
              <Policy>
                <button>
                  <a href="/jobs" target="blank">
                    Jobs
                  </a>
                </button>
              </Policy>
              <Policy>
                <button>
                  <a href="/privacy-policy" target="blank">
                    Privacy Policy
                  </a>
                </button>
              </Policy>
              <Policy>
                <button>
                  <a href="/cookie-policy" target="blank">
                    Cookie Policy
                  </a>
                </button>
              </Policy>
            </div>
          </div>
        </BottomLine>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
const FooterWrapper = styled.div`
  background: ${color.footerBg};
  padding: 50px 0 70px 0;
  overflow: hidden;
  h1 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
  @media (max-width: 990px) {
    padding: 30px 0 70px 0;
  }
  a {
    text-decoration: none;
    color: #1f1f1f;
  }
`;
const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-bottom: 15px;
  margin-top: 25px;

  h1 {
    margin: 0;
  }
  @media (max-width: 990px) {
    p {
      color: #1f1f1f;
    }
  }
`;
const SocialMediaWrapper = styled.div`
  display: flex;
  column-gap: 40px;
  padding-top: 23px;
  border-top: 1px solid #1f1f1f1f;
  max-width: 350px;

  @media (max-width: 990px) {
    max-width: unset;
  }

  @media (max-width: 375px) {
    column-gap: 30px;
  }
`;
const LocationGroup = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 185px;
  min-width: 120px;
  row-gap: 20px;
  h1 {
    width: 100%;
  }
  p {
    color: ${color.footerAdress};
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 990px) {
    margin-top: 50px;
    h1 {
      font-size: 20px;
    }
  }
  @media (max-width: 550px) {
    max-width: 150px;
  }

  @media (max-width: 375px) {
    min-width: 100px;
    max-width: 124px;
  }
`;
const CopyRight = styled.div`
  display: flex;
  align-items: center;
  p {
    color: ${color.primaryDark};
    font-weight: 350;
  }
  span {
    font-weight: 700;
  }
`;
const Policy = styled.div`
  display: flex;
  align-items: center;
  padding: 0 32px;
  border-left: 1px solid #1f1f1f1f;
  button {
    border: none;
    background: none;
  }
`;
const SmallPolicyWrapper = styled.div`
  display: flex;
  column-gap: 15px;
  margin-top: 30px;
`;
const PolicySmall = styled.div`
  button {
    border: none;
    background: none;
    color: #1f1f1fb2;
  }

  padding-right: 15px;
  border-right: 1px solid #1f1f1f1f;
`;
const LocationWrapper = styled.div`
  display: flex;
  column-gap: 30px;

  @media (max-width: 550px) {
    flex-wrap: wrap;
  }
`;
const BottomLine = styled.div`
  display: flex;
  column-gap: 32px;
  margin-top: 100px;
  @media (max-width: 990px) {
    margin-top: 20px;
  }
`;
