import React from "react";
import styled from "styled-components";
import "../../../assets/styles/style.css";
import NonTechComp from "./NonTechComp";
import TechScaleUps from "./TechScaleUps";
import Industries from "./Industries";

const Fold4 = () => {
    return (
        <Wrapper>
            <div className="container-1440 container-content border-bottom-faded">
                <Content>
                    <Title>
                        <h1>We work with</h1>
                    </Title>

                    <div className="row gap-30">

                        <div className="col ">
                            <TechScaleUps />
                        </div>
                        <div className="col ">
                            <NonTechComp />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col">
                            <Industries />

                        </div>
                    </div>
                </Content>
            </div>
        </Wrapper>
    );
};

export default Fold4;

const Wrapper = styled.div`
  background: white;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;
const Title = styled.div`
margin-bottom:30px;
  h1 {
    font-size: 48px;
    font-weight: 400;
    margin: 0;
  }
  @media(max-width:990px){
margin-bottom:20px;
    h1{
        font-size:30px;
        
    }
  }
`;
