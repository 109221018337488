export const CONTACT_EMAIL = "office@appssemble.com";
export const LINKEDIN_LINK = "https://ro.linkedin.com/company/appssemble";
export const FACEBOOK_LINK = "https://www.facebook.com/appssemble/";
export const GIT_LINK = "https://github.com/appssemble";
export const X_LINK = "https://twitter.com/appssemble";
export const INSTAGRAM_LINK = "https://www.instagram.com/appssemble/";
export const DRIBBLE_LINNK = "https://dribbble.com/appssemble";

export const mappings = [
    {
        "id": 16,
        "href": "what-are-great-food-delivery-apps-made-of.html"
    },
    {
        "id": 17,
        "href": "2020-mobile-year-in-review.html"
    },
    {
        "id": 18,
        "href": "how-to-build-a-live-streaming-mobile-app.html"
    },
    {
        "id": 19,
        "href": "why-should-you-spend-time-and-money-on-a-mobile-MVP.html"
    },
    {
        "id": 20,
        "href": "how-much-does-it-cost-to-develop-a-clubhouse-like-MVP.html"
    },
    {
        "id": 21,
        "href": "digital-transformation-using-mobile-apps.html"
    },
    {
        "id": 22,
        "href": "whats-the-difference-between-a-mobile-app-POC-Prototype-and-an-MVP.html"
    },
    {
        "id": 23,
        "href": "why-you-should-do-native-development-instead-of-cross-platform.html"
    },
    {
        "id": 11,
        "href": "how-we-built-a-million-dollars-mobile-app.html"
    },
    {
        "id": 12,
        "href": "how-going-mvp-first-can-save-you-money.html"
    },
    {
        "id": 13,
        "href": "augmented-and-virtual-reality-in-mobile-apps.html"
    },
    {
        "id": 14,
        "href": "what-is-possible-in-machine-learning-on-mobile.html"
    },
    {
        "id": 15,
        "href": "the-benefits-of-building-an-mvp-for-your-startup.html"
    },
    {
        "id": 24,
        "href": "ultimate-guide-to-developing-an-iOS-app-in-2021.html"
    },
    {
        "id": 25,
        "href": "how-much-does-it-cost-to-build-a-mobile-app-in-2021.html"
    },
    {
        "id": 26,
        "href": "ultimate-guide-to-developing-an-Android-app-in-2021.html"
    },
    {
        "id": 27,
        "href": "how-to-avoid-a-bad-mobile-development-agency-for-your-mobile-app.html"
    },
    {
        "id": 28,
        "href": "how-to-build-a-mobile-app-on-a-budget.html"
    },
    {
        "id": 29,
        "href": "how-much-of-your-tech-business-should-you-outsource.html"
    },
    {
        "id": 30,
        "href": "how-to-promote-your-mobile-app-for-free.html"
    },
    {
        "id": 31,
        "href": "how-to-build-user-personas-for-a-mobile-app.html"
    },
    {
        "id": 32,
        "href": "guide-to-ios-app-store-submission.html"
    },
    {
        "id": 33,
        "href": "innovative-apps-in-2021.html"
    },
    {
        "id": 34,
        "href": "a-conclusive-guide-for-making-your-android-google-play-submission-successful.html"
    },
    {
        "id": 36,
        "href": "covid-19-impact-on-startups.html"
    },
    {
        "id": 35,
        "href": "why-competition-is-bad-for-your-mobile-startup.html"
    },
    {
        "id": 37,
        "href": "essential-advice-for-startup-founders.html"
    },
    {
        "id": 38,
        "href": "why-update-your-mobile-apps.html"
    },
    {
        "id": 39,
        "href": "where-to-announce-your-startup-to-the-world.html"
    },
    {
        "id": 40,
        "href": "all-about-push-notifications.html"
    },
    {
        "id": 41,
        "href": "2021-mobile-year-in-review.html"
    },
    {
        "id": 42,
        "href": "design-trends-2022.html"
    },
    {
        "id": 43,
        "href": "mobile-apps-analytics.html"
    },
    {
        "id": 44,
        "href": "top-10-apps-millennials.html"
    },
    {
        "id": 45,
        "href": "environmental-awarness-apps.html"
    },
    {
        "id": 46,
        "href": "mobile-app-development-team-structure.html"
    },
    {
        "id": 47,
        "href": "car-play-ios.html"
    },
    {
        "id": 48,
        "href": "android-car.html"
    },
    {
        "id": 49,
        "href": "car-platforms.html"
    },
    {
        "id": 50,
        "href": "apps-for-cars.html"
    },
    {
        "id": 51,
        "href": "nft.html"
    },
    {
        "id": 52,
        "href": "design-for-startups.html"
    },
    {
        "id": 53,
        "href": "seed.html"
    },
    {
        "id": 54,
        "href": "ruby-on-rails.html"
    },
    {
        "id": 55,
        "href": "content-creator-economy.html"
    },
    {
        "id": 56,
        "href": "why-startups-fail.html"
    },
    {
        "id": 58,
        "href": "startup-ideas.html"
    },
    {
        "id": 57,
        "href": "types-of-authentication.html"
    },
    {
        "id": 59,
        "href": "saas.html"
    }
]