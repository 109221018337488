import React from "react";
import styled from "styled-components";

import CaseStudiesFold1 from "../components/Projects/CaseStudiesFold1";
import StudiesFold from "../components/Projects/StudiesFold";
import TrackingComponent from "../components/Tracking/TrackingComponent";

const ProjectsPage = () => {
  return (
    <Wrapper>
      <CaseStudiesFold1 />
      <StudiesFold />
      <TrackingComponent />
    </Wrapper>
  );
};

export default ProjectsPage;

const Wrapper = styled.div`
  background: white;
`;
