import React from "react";
import styled from "styled-components";
import { color } from "../assets/styles/colors";
import { ReactComponent as ContactLogo } from "../assets/icons/logo-contact-form.svg";
import AnimatedTextButton from "../components/Generic/AnimatedTextButton";
import { useNavigate } from "react-router-dom";
import BookMeetingCard from "../components/HowWork/BookMeetingCard";
const StepItem = ({ number, text, title }) => {
  return (
    <ChallengeItemWrapper>
      <NumberWrapper>
        <h1>{number}</h1>
      </NumberWrapper>
      <ItemDetailsWrapper>
        <h1>{title}</h1>
        <p>{text}</p>
      </ItemDetailsWrapper>
    </ChallengeItemWrapper>
  );
};
const steps = [
  {
    title: "Introductory call",
    nubmer: "1",
    description:
      "Book a meeting to assess your needs and the viability of your project.",
  },
  {
    title: "Discovery",
    nubmer: "2",
    description:
      "Participate in a product definition/discovery workshop (discovery workshop - what to build, for who and how it should look like).",
  },
  {
    title: "Scope & Implementation",
    nubmer: "3",
    description:
      "You decide what needs to be built (priorities, timeline), we handle the rest.",
  },
];
const ContactPage = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="container-1440 container-content">
        <Content>
          <DetailsWrapper>
            <Title>
              <h1>How does it work?</h1>
            </Title>
            <Steps>
              {steps.map((item, index) => (
                <StepItem
                  key={index}
                  number={item.nubmer}
                  title={item.title}
                  text={item.description}
                />
              ))}
            </Steps>
            <div className="showOnSmall">
              <FormWrapper>
                <BookMeetingCard id={"4"} />
              </FormWrapper>
            </div>

            <BigCard
              onClick={() => {
                navigate("/approach");
              }}
            >
              <BigCardDetails className="col">
                <h1>More about our processes and services</h1>
                <CardButtonContainer>
                  <AnimatedTextButton
                    text={"Read about our approach"}
                    light={"light"}
                  />
                </CardButtonContainer>
              </BigCardDetails>
              <BigCardLogo className="col">
                <ContactLogo />
              </BigCardLogo>
            </BigCard>
            <SmallCards>
              <SmallCard
                style={{ background: "#78786A" }}
                onClick={() => {
                  navigate("/services");
                }}
              >
                <BigCardDetails className="col">
                  <h1>Digital solutions that help you scale</h1>
                  <CardButtonContainer>
                    <AnimatedTextButton text={"Our services"} light={"light"} />
                  </CardButtonContainer>
                </BigCardDetails>
              </SmallCard>
              <SmallCard
                style={{ background: "#EFEFEB" }}
                onClick={() => navigate("/process")}
              >
                <BigCardDetails className="col">
                  <h1 style={{ color: color.primaryDark }}>
                    Fast and honest delivery
                  </h1>
                  <CardButtonContainer>
                    <AnimatedTextButton text={"Our process"} />
                  </CardButtonContainer>
                </BigCardDetails>
              </SmallCard>
            </SmallCards>
          </DetailsWrapper>
          <FormWrapper className="showOnBig">
            <BookMeetingCard id={"3"} />
          </FormWrapper>
        </Content>
      </div>
    </Wrapper>
  );
};

export default ContactPage;
const Wrapper = styled.div``;
const CardButtonContainer = styled.div`
  margin-top: 4px;
`;
const Content = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: 990px) {
    flex-direction: column;
  }
`;
const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;
const FormWrapper = styled.div`
  display: flex;
  width: 100%;
`;
const Title = styled.div``;
const Steps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
const BigCard = styled.div`
  display: flex;
  background: ${color.darkGreen};
  border-radius: 10px;
  padding: 25px 45px;
  margin-top: 20px;
  cursor: pointer;
`;
const BigCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    color: ${color.primaryLight};
  }
`;
const BigCardLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
const SmallCard = styled(BigCard)`
  background: unset;
`;
const SmallCards = styled.div`
  display: flex;
  column-gap: 20px;
  @media (max-width: 990px) {
    flex-direction: column;
  }
`;
const ChallengeItemWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
const NumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefeb;
  border-radius: 8px;
  height: 52px;
  width: 52px;
  min-height: 52px;
  min-width: 52px;

  h1 {
    margin: 0;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0px;
    text-align: left;
    color: #1f1f1f;
  }
`;

const ItemDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.5px;
    text-align: left;
    margin: 0 0 10px 0;
  }
  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: ${color.primaryDark};
  }
`;
