import { useEffect } from "react";

export const getTidio = (callback) => {
    const existingScript = document.getElementById('tidio');

    if (!existingScript) {

        const script = document.createElement('script');
        script.src = '//code.tidio.co/ypbnq6ldtsmtl8blb74rtzeb42rl7mol.js';
        script.id = 'tidio';
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) callback();
        };
    }


}


const LoadTidio = () => {

    useEffect(() => {
        const timer = setTimeout(() => {
            getTidio()
        }, 1000)
        return () => {
            clearTimeout(timer);
        }
    }, [])


}
export default LoadTidio
