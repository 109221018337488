import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import CardWithSquareDots from "./CardWithSquareDots";
import CommercialIlustration from "../../assets/images/commercial-illustration.webp";

const ProcessPageNewCommercial = () => {
  const OutcomeCard = [
    {
      title: "Outcome",
      items: ["Contract & Statements of Work"],
    },
  ];

  return (
    <Content className="border-bottom-faded">
      <Title>
        <h1>2. Commercial</h1>
      </Title>

      <Section className="row">
        <LeftSide className="col">
          <TextWrapper>
            <div className="showOnSmall">
              <RightSide className="col">
                <ImgWrapperSmall>
                  <img src={CommercialIlustration} alt="discovery-workshop" />
                </ImgWrapperSmall>
              </RightSide>
              <br></br>
            </div>
            <p>
              Based on the solution overview document and the client's goals, we
              split the workload into statements of work. A statement of work
              contains features that can be implemented and have to be delivered
              in a <span> 4 to 6 weeks cycle.</span>
              <br></br>
              <br></br>
              This creates flexibility for our clients, they can decide what
              features should be delivered first, but gives us enough stability
              and autonomy to schedule the work as we see fit, without any
              changes to the scope for the next 4 to 6 weeks cycle.
              <br></br>
              <br></br>
              In this way, the client gets what he wants, in a short time,
              without having to be hands-on and involved on a day-to-day basis
              in the project.
            </p>
          </TextWrapper>
          <CardWrapper>
            <CardWithSquareDots data={OutcomeCard} />
          </CardWrapper>
        </LeftSide>

        <RightSide className="col showOnBig">
          <ImgWrapper>
            <img src={CommercialIlustration} alt="discovery-workshop" />
          </ImgWrapper>
        </RightSide>
      </Section>
    </Content>
  );
};

export default ProcessPageNewCommercial;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  padding: 60px 0;
  @media (max-width: 990px) {
    row-gap: 40px;
  }
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  h1 {
    color: ${color.primaryDark};
    font-size: 48px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    margin: 0;
  }
  @media (max-width: 990px) {
    h1 {
      font-size: 30px;
      line-height: 33px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

const Section = styled.div`
  display: flex;
  gap: 30px;

`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  min-width: 400px;
  @media (max-width: 650px) {
    min-width: unset;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    color: ${color.primaryDark};
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    margin: 0;
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
  }

  span {
    background: ${color.highlightedColor};
    line-height: 36px;
    padding-left: 5px;
    padding-right: 5px;
  }
  @media (max-width: 990px) {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;
const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
 justify-content:end;
  row-gap: 60px;
  width: 100%;
  min-width: 400px;

  @media (max-width: 375px) {
    min-width: unset;
    padding: 30px 0px;
  }

  @media(max-width:990px){
    justify-content:center;
}
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content:end;
  img {
      width: 80%;
    object-fit: contain;
    max-height: 362px;
    min-width: 100px;
  }
  @media(max-width:990px){
      justify-content:center;
  }
`;

const ImgWrapperSmall = styled.div`
  display: flex;

  justify-content: center;
  margin: 20px;
  img {
      width: 80%;
    object-fit: contain;
    max-height: 362px;
    min-width: 50px;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 5%;
  width: 100%;
  @media (max-width: 375px) {
    margin-right: 0;
  }
  p {
    color: ${color.primaryLight};
  }
`;
