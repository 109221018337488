import { POST } from "../API";


export const getPostsByPage = (
    data,
    onSuccess,
    onError
) => {

    POST(`/blog/posts`, data, onSuccess, onError);
};
