import React from "react";
import styled from "styled-components";
import { ReactComponent as AppssembleLogo } from "../../assets/icons/appssemble-logo-big.svg";
import { ReactComponent as AppssembleLogoBg } from "../../assets/icons/logo-background.svg";
import AnimatedTextButton from "../Generic/AnimatedTextButton";
import { useNavigate } from "react-router-dom";

const WorkPageFold1 = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="container-1440 container-content position-relative">
        <Content>
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <Text>
                <h1>How do we deliver good products fast?</h1>
                <p>
                  We work in 4 to 6-week cycles, in small, self-sufficient
                  teams, and have a couple of rules.
                </p>
              </Text>
              <div>
                <AnimatedTextButton
                  onClick={(e) => navigate("/process")}
                  text="Read more about our process"
                />
              </div>
            </div>
            <LogoContainerBig className="showOnBig col">
              <div className="col d-flex justify-content-center ">
                <AppssembleLogo />
              </div>
            </LogoContainerBig>
            <div className="showOnSmall">
              <SmallScreenBg>
                <AppssembleLogoBg />
              </SmallScreenBg>
            </div>
          </div>
        </Content>
      </div>
    </Wrapper>
  );
};

export default WorkPageFold1;
const Wrapper = styled.div`
  background: white;
  margin-bottom: 70px;


`;
const Content = styled.div`
  min-height: 250px;
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 50px;
  z-index: 10;
  margin-bottom: 50px;

  h1 {
    margin: 0;
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    color: #1f1f1f;
  }
  p {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
    color: #1f1f1f;
  }

  @media (max-width: 990px) {
    max-width: unset;
    row-gap: 30px;
    h1 {
      font-size: 30px;
      line-height: 39px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

const SmallScreenBg = styled.div`
  position: absolute;
  right: 30px;
  top: 50px;
  bottom: 0;
  opacity: 0.2;
  magrgin-left: auto;
`;
const LogoContainerBig = styled.div`
  display: flex;
`;
