import React from "react";
import styled from "styled-components";
import AboutUsFold1 from "../components/AboutUsPageComponents/AboutUsFold1";
import AboutUsFold2 from "../components/AboutUsPageComponents/AboutUsFold2";

import TrackingComponent from "../components/Tracking/TrackingComponent";

const AboutUsPage = () => {

  return (
    <Wrapper>
      <AboutUsFold1 />
      <AboutUsFold2 />
      <TrackingComponent />
    </Wrapper>
  );
};

export default AboutUsPage;
const Wrapper = styled.div`
  background: #12251a;
`;
