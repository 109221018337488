import React from 'react'
import styled from 'styled-components'
import { color } from '../../assets/styles/colors'
import { Link, useNavigate } from 'react-router-dom'
const NavBarButton = ({ text, to, highlighted }) => {
    const navigate = useNavigate();
    const handleOnClick = (to) => {
        navigate(to)
    }
    return (

        highlighted ?
            (<HighlightedWrapper>
                <StyledLinkHighlighted onClick={() => handleOnClick(to)}>{text}</StyledLinkHighlighted>
            </HighlightedWrapper>)
            :

            (<ButtonWrapper  >
                <StyledLink darknav={window.location.pathname === "/about"}
                    to={to}>
                    {text}
                </StyledLink>
                <Link ></Link>
            </ButtonWrapper>)

    )
}

export default NavBarButton
const ButtonWrapper = styled.div`
display:flex;
justify-content:center;
align-items:center;
padding:0;
backgroundtransparent;
border-radius: 4px;
`
const StyledLink = styled(Link)`
cursor:pointer;
padding:12px 20px;
font-size:14px;
text-decoration:none;
border-radius:6px;
color:${color.primaryDark};
transition:400ms;
&:hover{
    background-color: #f6fab2;
    color: #1f1f1f !important;
}
`
const HighlightedWrapper = styled(ButtonWrapper)`
cursor:pointer;
height:42px;
background:${color.highlightedColor};
transition:400ms;
&:hover{
    background:#f6ff76;
}
`
const StyledLinkHighlighted = styled.div`
padding:12px 20px;
cursor:pointer;
font-size:14px;
text-decoration:none;
border-radius:6px;
color: ${color.primaryDark};
`