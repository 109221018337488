import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

const CheckIndexedLinks = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const checkLinks = () => {

        switch (location.pathname) {
            case "/mobile-app-development-portfolio.html":
                navigate("/projects", { replace: true })
                break;
            case "/mobile-app-development-services.html":
                navigate("/services", { replace: true })
                break;
            case "/process-for-startups.html":
                navigate("/process", { replace: true })
                break;
            case "/workshop-for-startups.html":
                navigate("/approach", { replace: true })
                break;
            case "/sniffer.html":
                navigate("/projects/sniffer", { replace: true })
                break;
            case "/dezigner.html":
                navigate("/projects/dezigner", { replace: true })
                break;
            case "/onesave.html":
                navigate("/projects/onesave", { replace: true })
                break;
            case "/voicecal.html":
                navigate("/projects/voicecal", { replace: true })
                break;
            case "/parenthing.html":
                navigate("/projects/parenthing", { replace: true })
                break;
            case "/fazan.html"://nu este proiect
                navigate("/projects/fazan", { replace: true })
                break;
            case "/aical.html":  //nu este proiect
                navigate("/projects/aical", { replace: true })
                break;
            case "/luggage.html":  //nu este proiect
                navigate("/projects/luggage", { replace: true })
                break;
            case "/deliverer.html":  //nu este proiect
                navigate("/projects/deliverer", { replace: true })
                break;
            case "/privacy.html":
                navigate("/privacy-policy", { replace: true })
                break;
            case "/contact.html":
                navigate("/contact", { replace: true })
                break;
            case "/about.html":
                navigate("/about", { replace: true })
                break;

            default:
                break;
        }

    }


    useEffect(() => {
        checkLinks();
        console.log(location, "location")
        console.log(params, "params")
    }, [location])

    return (
        <>
            <h1>Unexpected Application Error!
            </h1><h2>  404 Not Found</h2>
        </>
    )
}

export default CheckIndexedLinks