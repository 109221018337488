import { Outlet } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import styled from "styled-components";
import ScrollToAnchor from "./Helper/ScrollToAnchor";
import Footer from "./components/Footer/Footer";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./Helper/ScroolToTop";
import CookieConsent from "./components/Cookies/CookieConsent";
import MetaAsset from "./assets/images/appssemble-meta.webp";
import MetaTagsHandler from "./Helper/MetaTagHandler";
import LoadTidio from "./Helper/TidioRequest";
import CheckIndexedLinks from "./Helper/CheckIndexedLinks";

function App() {
  ScrollToAnchor();
  ScrollToTop();
  LoadTidio();
  CheckIndexedLinks();

  return (
    <HelmetProvider>
      {/* <MetaTagsHandler
        title={"appssemble"}
        description={
          "Mobile and web apps for scale-ups, small and medium-sized enterprises."
        }
        image={MetaAsset}
      /> */}
      <Wrapper>
        <NavBar />
        <Content>
          <Outlet />
        </Content>
        <Footer />
      </Wrapper>
      <CookieConsent />
    </HelmetProvider>
  );
}

export default App;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: none;
`;
const Content = styled.div``;

