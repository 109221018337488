import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon.svg";
import { ReactComponent as MenuIconDarkNav } from "../../assets/icons/menu-icon-dark-nav.svg";
import { links } from "./NavBar";
import { ReactComponent as AppssembleLogo } from "../../assets/icons/appssemble-logo.svg";
import { color } from "../../assets/styles/colors";
import { useNavigate } from "react-router-dom";

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();
  const [scrool, setScrool] = useState(0);
  const handleNavigate = (to) => {
    setIsOpen(false);
    navigate(to);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  useState(() => {
    const handleScroll = (event) => {
      setScrool(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Container>
      <MenuButton
        name="mobile-menu-btn"
        id="mobile-menu-btn"
        title="about"
        onClick={() => setIsOpen(!isOpen)}
      >
        {window.location.pathname === "/about" && scrool < 440 ? (
          <MenuIconDarkNav />
        ) : (
          <MenuIcon />
        )}
      </MenuButton>
      {isOpen && (
        <FullscreenContainer>
          <List ref={ref}>
            <FirstListItem>
              <SiteLogo
                onClick={() => handleNavigate("/")}
                darknav={window.location.pathname === "/about"}
              >
                <AppssembleLogo />
                <h3>appssemble</h3>
              </SiteLogo>
              <MenuButton
                name="mobile-menu-btn"
                id="mobile-menu-btn"
                title="menu"
                onClick={() => setIsOpen(!isOpen)}
              >
                <MenuIcon />
              </MenuButton>
            </FirstListItem>
            {links.map((link, index) => (
              <ListItem
                name={link + "-btn"}
                id={link + "-btn"}
                title={link + "-btn"}
                onClick={() => handleNavigate(link.to)}
              >
                <h2>{link.text}</h2>
              </ListItem>
            ))}
          </List>
        </FullscreenContainer>
      )}
    </Container>
  );
};

export default MobileMenu;
const Container = styled.div``;
const MenuButton = styled.button`
  border: none;
  background: none;
  height: 100%;
`;
const FullscreenContainer = styled.div`
  position: fixed;
  padding: 0 5px;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9000;
  width: 100%;
`;
const List = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;
const ListItem = styled.button`
  background: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  aling-items: center;
  padding: 18px 20px;
  border: none;
  border-bottom: 1px dashed rgba(31, 31, 31, 0.05);
  h2 {
    color: ${color.primaryDark};
    font-size: 14px;
    font-weight: 400;
  }
`;
const SiteLogo = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;

  h3 {
    color: ${color.primaryDark};
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
  }
`;
const FirstListItem = styled(ListItem)`
  align-items: center;
  padding: 16px;
`;
