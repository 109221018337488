import React from "react";
import styled from "styled-components";
import { ReactComponent as AppssembleLogo } from "../../assets/icons/appssemble-logo-big.svg";
import { ReactComponent as AppssembleLogoBg } from "../../assets/icons/logo-background.svg";
import TrustedByCompanies from "../Generic/TrustedByCompanies";
import BlackHorizontalScroller from "../Generic/BlackHorizontalScroller";
import { ReactComponent as AppStoreLogo } from "../../assets/icons/appStore-logo.svg";
import { ReactComponent as GooglePlayLogo } from "../../assets/icons/googlePlay-logo.svg";
import ClutchLogoComponent from "../Generic/ClutchLogoComponent";
import { useNavigate } from "react-router-dom";
import AnimatedTextButton from "../Generic/AnimatedTextButton";

const ServicesFold1 = () => {
  const navigate = useNavigate();

  const navigateToClutch = (e) => {
    window.open("https://clutch.co/profile/appssemble#reviews");
  };
  return (
    <Wrapper>
      <div className="container-1440 container-content position-relative">
        <Content>
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <Text>
                <h1>Services</h1>
                <p>
                  We work with scale-ups and small and medium-sized companies
                  that want, well-built digital products, in sync with their
                  product roadmap and goals, that are delivered fast with no
                  overhead or added complexity.
                </p>
              </Text>
              <div>
                <AnimatedTextButton
                  onClick={(e) => navigate("/approach")}
                  text="Read more about our approach"
                />
              </div>
            </div>
            <LogoContainerBig className="showOnBig col">
              <div className="col d-flex justify-content-center ">
                <AppssembleLogo />
              </div>
            </LogoContainerBig>

            <div className="showOnSmall">
              <SmallScreenBg>
                <AppssembleLogoBg />
              </SmallScreenBg>
            </div>
          </div>
          <div className="showOnBig ">
            <TrustedByCompanies />
          </div>
        </Content>
      </div>

      <div className="container-1440 showOnSmall">
        <SmallTopFeaturedWrapper>
          <TopFeaturedGroup>
            <TopCompany>
              <p>Top development company</p>
              <Group>
                <ClutchLogoComponent onClick={navigateToClutch} />
              </Group>
            </TopCompany>
            <Featured>
              <p>Featured</p>
              <Group>
                <AppStoreLogo />
                <GooglePlayLogo />
              </Group>
            </Featured>
          </TopFeaturedGroup>
        </SmallTopFeaturedWrapper>
      </div>

      <div className="showOnSmall">
        <BlackHorizontalScroller />
      </div>
    </Wrapper>
  );
};

export default ServicesFold1;
const Wrapper = styled.div`
  background: white;
`;
const Content = styled.div`
  margin-bottom: -50px;
  @media (max-width: 990px) {
    margin-bottom: 30px;
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 50px;
  margin-bottom: 50px;
  z-index: 10;

  h1 {
    margin: 0;
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
  }
  p {
    margin: 0;
    color: rgba(31, 31, 31, 0.9);
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
  }

  @media (max-width: 990px) {
    margin-bottom: 50px;
    max-width: unset;
    row-gap: 30px;
    h1 {
      font-size: 30px;
      line-height: 39px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

const SmallScreenBg = styled.div`
  position: absolute;
  right: 30px;
  top: 50px;
  bottom: 0;
  opacity: 0.2;
  magrgin-left: auto;
`;
const TrustedBy = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: space-between;
  border-right: 1px solid #3131310d;
  padding-right: 20px;
  p {
    font-size: 14px;
    color: #1e1e1eb2;
    margin: 0;
  }
`;
const Group = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  gap: 60px;
  align-items: center;

  @media (max-width: 990px) {
    gap: 20px;
    margin-top: 10px;
  }
  @media (max-width: 450px) {
    svg {
      width: 100%;
    }
  }
`;
const TopCompany = styled(TrustedBy)`
  min-width: 150px;
`;
const Featured = styled(TrustedBy)`
  border-right: none;
`;
const TopFeaturedGroup = styled.div`
  display: flex;
  gap: 20px;
`;
const SmallTopFeaturedWrapper = styled.div`
  padding: 20px 0;
`;
const LogoContainerBig = styled.div`
  display: flex;
`;
