import React, { Suspense, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import { ReactComponent as DownSvg } from "../../assets/icons/budget-menu-arrow.svg";
import { sendContactEmail } from "../../API/Actions/sendContactEmail";
import { ReactComponent as SentIcon } from "../../assets/icons/email-sent-icon.svg";
import SpinnerComponent from "../Generic/SpinnerComponent";
const ReCAPTCHA = React.lazy(() => import("react-google-recaptcha"));

const StateSet = () => {
  return (
    <SentCard>
      <SentIcon />
      <h1>Thanks for reaching out!</h1>
      <h2>
        We’ll be in touch in 24 hours and looking forward to the opportunity to
        meet your needs.
      </h2>
    </SentCard>
  );
};

const BookMeetingCard = ({ id, maxwidth }) => {
  const initialInputValues = {
    name: "",
    email: "",
    description: "",
    phone: "",
    budget: "",
  };
  const [inputValues, setInputvalues] = useState(initialInputValues);
  const [recaptchaNeeded, setRecaptchaNeeded] = useState(false);
  const [wName, setWName] = useState(false);
  const [wEmail, setWEmail] = useState(false);
  const [wDescription, setWDescription] = useState(false);
  const [wBudget, setWBudget] = useState(false);
  const [displayCaptcha, setDisplayChaptcha] = useState(false);
  const [chaptchaValue, setChaptchavvalue] = useState(null);
  const [sentState, setSentState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputvalues({
      ...inputValues,
      [name]: value,
    });

    setRecaptchaNeeded(true);
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const checkFields = () => {
    let name = false;
    let email = false;
    let description = false;
    let budget = false;

    if (inputValues.name === "") {
      name = true;
      setWName(true);
    } else {
      name = false;
      setWName(false);
    }

    if (inputValues.email === "" || !validateEmail(inputValues.email)) {
      email = true;
      setWEmail(true);
    } else {
      email = false;
      setWEmail(false);
    }

    if (inputValues.description === "") {
      description = true;
      setWDescription(true);
    } else {
      description = false;
      setWDescription(false);
    }

    if (inputValues.budget === "") {
      budget = true;
      setWBudget(true);
    } else {
      budget = false;
      setWBudget(false);
    }

    return !name && !email && !description && !budget;
  };

  const onReChaptchaChange = (value) => {
    setChaptchavvalue(value);
  };

  const bookMeeting = (email, name, description, phone, budget) => {
    setIsLoading(true);
    sendContactEmail(
      email,
      name,
      description,
      phone,
      budget,
      function (response) {
        setIsLoading(false);
        setSentState(true);
      },
      function (error) {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  const resetCard = () => {
    setInputvalues(initialInputValues);
    setWName(false);
    setWEmail(false);
    setWBudget(false);
    setWDescription(false);
    setDisplayChaptcha(false);
    setChaptchavvalue(null);
    setIsLoading(false);
  };

  const handleButtonClick = () => {
    let ready = checkFields();

    if (ready) {
      let captchaValue = ref.current.execute();
    }
  };

  useEffect(() => {
    if (chaptchaValue) {
      bookMeeting(
        inputValues.email,
        inputValues.name,
        inputValues.description,
        inputValues.phone,
        inputValues.budget
      );
    }
  }, [chaptchaValue]);

  useEffect(() => {
    if (sentState) {
      resetCard();
    }
  }, [sentState]);

  return (
    <Card maxwidth={maxwidth}>
      {sentState ? (
        <StateSet />
      ) : (
        <>
          {isLoading ? (
            <SpinnerWrapper>
              <SpinnerComponent />
            </SpinnerWrapper>

          ) : (
            <>
              <InputWithLabel>
                <label htmlFor={"book-card-name-input" + id}>Full Name</label>
                <input
                  autoComplete="false"
                  className={wName && "not-completed-input"}
                  placeholder="E.g. Mark Sullivan"
                  name="name"
                  id={"book-card-name-input" + id}
                  value={inputValues.name}
                  onChange={(e) => handleInputChange(e)}
                ></input>
              </InputWithLabel>
              <InputWithLabel>
                <label htmlFor={"book-card-email-input" + id}>
                  E-mail Address*
                </label>
                <input
                  autoComplete="false"
                  className={wEmail && "not-completed-input"}
                  placeholder="E.g. Mark@mycompany.com"
                  name="email"
                  id={"book-card-email-input" + id}
                  value={inputValues.email}
                  onChange={(e) => handleInputChange(e)}
                ></input>
              </InputWithLabel>
              <InputWithLabel>
                <label htmlFor={"book-card-phone-input" + id}>Phone</label>
                <input
                  autoComplete="false"
                  className={wEmail && "not-completed-input"}
                  placeholder="E.g. +33 793 826 947"
                  name="phone"
                  id={"book-card-phone-input" + id}
                  value={inputValues.phone}
                  onChange={(e) => handleInputChange(e)}
                ></input>
              </InputWithLabel>
              <InputWithLabel>
                <label htmlFor={"book-card-description-input" + id}>
                  Project Description
                </label>
                <textarea
                  className={wDescription && "not-completed-input"}
                  placeholder="E.g. Need refactoring for my mobile apps."
                  name="description"
                  id={"book-card-description-input" + id}
                  value={inputValues.description}
                  onChange={(e) => handleInputChange(e)}
                ></textarea>
              </InputWithLabel>
              <InputWithLabel>
                <label htmlFor={"book-card-budget-input" + id}>Budget</label>
                <MenuWrapper>
                  <select
                    className={wBudget && "not-completed-input"}
                    style={
                      !inputValues.budget
                        ? { color: "rgba(49, 49, 49, 0.5)" }
                        : {}
                    }
                    name="budget"
                    id={"book-card-budget-input" + id}
                    value={inputValues.budget}
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Select asdqweq"
                  >
                    <option value={""}>Select Range</option>
                    <option value={"$50-100k"}>$50-100k</option>
                    <option value={"$100-300k"}>$100-300k</option>
                    <option value={"$300k+"}>$300k+</option>
                  </select>
                  <DownSvg
                    style={{ position: "absolute", right: "20px", top: "18px" }}
                  />
                </MenuWrapper>
              </InputWithLabel>
              {(wName || wEmail || wDescription || wBudget) && (
                <WrongInputTips>
                  <p>*Complete all fields with valid data</p>
                </WrongInputTips>
              )}
              <BookMeetingBtn onClick={() => handleButtonClick()}>
                Book a meeting
              </BookMeetingBtn>
              {recaptchaNeeded && (
                <Suspense fallback={<SpinnerComponent />}>
                  <ReCAPTCHA
                    style={{ display: "none" }}
                    ref={ref}
                    size="invisible"
                    sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
                    onChange={onReChaptchaChange}
                  />
                </Suspense>
              )}
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default BookMeetingCard;
const Card = styled.div`
  border-radius: 8px;
  background: #fcfbfa;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 45px 50px;
  min-width: 400px;
  min-height: 490px;
  max-width: ${(props) => (props.maxwidth ? props.maxwidth : "100%")};
  width: 100%;

  @media (max-width: 990px) {
    margin-top: 40px;
    border: 1px solid rgba(49, 49, 49, 0.1);
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 600px) {
    min-width: 100%;
  }

  @media (max-width: 426px) {
    padding: 40px 20px;
    max-width: unset;
    min-width: unset;
    width: 400px;
  }
`;
const InputWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  label {
    color: ${color.primaryDark};
    font-size: 18px;
    line-height: 18px;
  }

  input {
    background: none;
    border-radius: 8px;
    border: 1px solid rgba(49, 49, 49, 0.1);
    padding: 15px 18px;
    color: ${color.primaryDark};
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 14px;

    :placeholder {
      font-size: 14px !important;
      color: rgba(49, 49, 49, 0.5);
      font-weight: 350;
    }
  }

  select {
    background: none;
    width: 100%;
    font-size: 14px;
    color: ${color.primaryDark};
    font-weight: 350;
    border-radius: 8px;
    border: 1px solid rgba(49, 49, 49, 0.1);
    padding: 15px 18px;
    font-style: normal;
    line-height: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:placeholder {
      color: rgba(49, 49, 49, 0.1);
    }
  }
  textarea {
    background: none;
    resize: none;
    border-radius: 8px;
    border: 1px solid rgba(49, 49, 49, 0.1);
    padding: 15px 18px;
    color: ${color.primaryDark};
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 14px;
    height: 120px;
    :placeholder {
      font-size: 14px !important;
      color: rgba(49, 49, 49, 0.5);
      font-weight: 350;
    }
  }
`;
const BookMeetingBtn = styled.button`
  border-radius: 8px;
  background: #e4f222;
  display: flex;
  height: 50.538px;
  padding: 11px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border: none;

  color: ${color.primaryDark};
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  &:disabled {
    opacity: 0.5;
  }
`;
const MenuWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const WrongInputTips = styled.div`
  p {
    font-size: 12px;
    line-height: 16px;
    color: red;
    margin: 0;
  }
`;
const SentCard = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
row-gap:30px;
padding:20px;
height:100%;
width:100%;
h1{
  color:${color.primaryDark};
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.5px;

  
}
h2{
color:#1F1F1F80;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 350;
  line-height: 27px;
  letter-spacing: -0.5;
  text-align: center;
  
}
svg{

   width:100%;
   margin-bottom:40px;
}

`;

const SpinnerWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
height:100%;
width:100%;
`