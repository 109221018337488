import React from "react";
import styled from "styled-components";
import AnimatedTextButton from "../Generic/AnimatedTextButton";
import { useNavigate } from "react-router-dom";

import { ReactComponent as AppssembleLogo } from "../../assets/icons/appssemble-logo-big.svg";
import { ReactComponent as AppssembleLogoBg } from "../../assets/icons/logo-background.svg";

const CareersFold1 = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <div className="container-1440 container-content border-bottom-faded position-relative">
        <Content>
          <div className="row">
            <div className="col-sm-12 col-md-8">
              <Text>
                <h1>Jobs</h1>
                <p>
                  We don’t place independent value on where you come from, where
                  you worked before, or where you went to school. We care about
                  who you are today, how you’ll be tomorrow, and what you’re
                  able to do now.
                </p>
              </Text>
            </div>
            <div className="col d-flex justify-content-center showOnBig">
              <AppssembleLogo />
            </div>
            <div>
              <AnimatedTextButton
                onClick={(e) => navigate("/about")}
                text={"Read more about us"}
              />
            </div>
            <div className="showOnSmall">
              <SmallScreenBg>
                <AppssembleLogoBg />
              </SmallScreenBg>
            </div>
          </div>
        </Content>
      </div>
    </Wrapper>
  );
};

export default CareersFold1;

const Wrapper = styled.div`
  background: white;
`;
const Content = styled.div`
  min-height: 250px;
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 50px;
  margin-bottom: 50px;
  z-index: 10;

  h1 {
    margin: 0;
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    color: #1f1f1f;
  }
  p {
    margin: 0;
    color: rgba(31, 31, 31, 0.9);
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
  }

  @media (max-width: 990px) {
    max-width: unset;
    row-gap: 30px;
    h1 {
      font-size: 30px;
      line-height: 39px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

const SmallScreenBg = styled.div`
  position: absolute;
  right: 30px;
  top: 50px;
  bottom: 0;
  opacity: 0.2;
  magrgin-left: auto;
`;
