import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CarouselArrow } from "../../assets/icons/carousel-arrow-right.svg";
import "swiper/css";
import "swiper/css/pagination";
import "../../assets/styles/style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import ProjectCard from "./ProjectCard";
import { SmallCardsProjectList } from "../../Helper/ProjectsList";
import AnimatedTextButton from "../Generic/AnimatedTextButton";
import { useNavigate } from "react-router-dom";

const NextProjects = ({ currentProjectId }) => {
  const [backDisabled, setBackDisabled] = useState(true);
  const [nextDisabled, setNextkDisabled] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slides, setSlides] = useState(2);
  const [randomProjects, setRandomProjects] = useState([]);
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  const navigate = useNavigate();
  const nagigateToProjects = () => navigate("/projects")

  const swipeNext = () => {
    let swiper = document.querySelector("#projects-swipper").swiper;
    swiper.slideNext();
  };

  const swipeBack = () => {
    let swiper = document.querySelector("#projects-swipper").swiper;
    swiper.slidePrev();
  };

  const swipeToZero = () => {
    let swiper = document.querySelector("#projects-swipper").swiper;
    swiper.slideTo(0);
  };

  const handleSlideChange = (event) => {
    const { isBeginning, isEnd } = event;
    if (isBeginning) {
      setBackDisabled(true);
    } else {
      setBackDisabled(false);
    }
    if (isEnd) {
      setNextkDisabled(true);
    } else {
      setNextkDisabled(false);
    }
  };

  const getMultipleRandom = (arr, num) => {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };

  const handleRandomProjects = (currentProjectId) => {
    let filteredProjects = SmallCardsProjectList.filter(
      (item) => item.projectId !== currentProjectId
    );
    let randomProjects = getMultipleRandom(filteredProjects, 4);
    setRandomProjects(randomProjects);
  };

  const handleCurrentProjectChange = () => {
    window.scrollTo(0, 0);
    handleRandomProjects(currentProjectId);
    swipeToZero();
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 990) {
      setSlides(2);
    }

    if (windowWidth < 990) {
      setSlides(2);
    }

    if (windowWidth < 750) {
      setSlides(1);
    }
  }, [windowWidth]);

  useEffect(() => {
    handleCurrentProjectChange();
  }, [currentProjectId]);

  return (
    <Wrapper>
      <div className="container-1440  container-content border-bottom-faded position-relative">
        <Content>
          <h1>More projects</h1>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <CarouselArrows>
              <button
                disabled={backDisabled}
                name="projectsBack-btn"
                id="projectsBack-btn"
                aria-label="projectsBack-btn"
                onClick={() => swipeBack()}
              >
                <CarouselArrow style={{ rotate: "180deg" }} />
              </button>
              <button
                disabled={nextDisabled}
                name="projectsNext-btn"
                id="projectsNext-btn"
                aria-label="projectsNext-btn"
                onClick={() => swipeNext()}
              >
                <CarouselArrow />
              </button>
            </CarouselArrows>
          </div>
          <ProjectsWrapper>
            <Swiper
              onSlideChange={(swiperCore) => handleSlideChange(swiperCore)}
              slidesPerView={slides}
              spaceBetween={30}
              centeredSlides={false}
              pagination={windowWidth < 990}
              modules={[Navigation, Pagination]}
              className="mySwiper pag"
              id="projects-swipper"
              style={{ overflow: "visible" }}
            >
              {randomProjects.map((item, index) => (
                <SwiperSlide key={index}>
                  <ProjectCard
                    img={item.img}
                    title={item.title}
                    description={item.smallDescription}
                    navigateTo={item.navigateTo}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </ProjectsWrapper>
          <AnimatedTextButton
            text={"See more"}
            onClick={nagigateToProjects}
          />
        </Content>
      </div>
    </Wrapper>
  );
};

export default NextProjects;
const Wrapper = styled.div``;
const Content = styled.div``;

const CarouselArrows = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    width: 25px;
    height: 25px;

    svg {
      stroke: black;
    }
    &:disabled {
      opacity: 0.2;
    }
  }
  @media (max-width: 990px) {
    display: none;
  }
`;

const ProjectsWrapper = styled.div`
  margin-top: 25px;
  margin-left: -25px;
  overflow: hidden;
  padding: 25px;

  @media (max-width: 990px) {
    margin-top: 0px;
  }
`;
