import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import CardWithSquareDots from "./CardWithSquareDots";
import UxPerspectiveImg from "../../assets/images/ux-perspective.webp";
import solution1b from "../../assets/images/solutionb-ilustration.webp";
import solution1bMobile from "../../assets/images/solution-ilustration-mobile.webp";

const ProcessOngoingFeaturePitch = () => {
  const Questionscard = [
    {
      title: "Questions",
      items: [
        "How much time do we want to spend on this?",
        "Narrow down the problem -- what do we want to solve?",
      ],
    },
  ];
  const DrawingsCard = [
    {
      title: "Drawings",
      items: [
        "Rough sketch of how the feature should function (UX perspective)",
      ],
    },
  ];
  const DrawingsCardb = [
    {
      title: "Drawings",
      items: [
        "Places - Things you can navigate to / dialogs, pop-ups",
        "Actions - Things the user can act on buttons, fields",
        "Connection lines – how actions take a user from one place to another.",
      ],
    },
  ];
  const Questions1b = [
    {
      title: "Questions",
      items: [
        "Where in the current system does this fit?",
        "How do you get there?",
        "What are the key components or interactions",
        "Where does it take you?",
      ],
    },
  ];
  const RabbitHolesQuestions = [
    {
      title: "Questions",
      items: [
        "Does this require some new technical work we haven’t done previously?",
        "Are we making any assumptions about how the parts fit together?",
        "Are we assuming a design solution exists / it's not something we need to come up with?",
        "Is it possible in the given timeframe?",
        "What we're not doing for this (write everything we're not doing for this feature)?",
      ],
    },
  ];


  return (
    <Content className="border-bottom-faded" >
      <Title>
        <p>
          There are 3 things involved in every digital project, budget, time,
          and scope.
          <span>
            {" "}
            To launch on time and budget, the scope has to be flexible.
          </span>{" "}
          Don’t throw more time, money, or people at a problem, just scale back
          the scope.
          <br></br>
          <br></br>
          <b>
            We apply the shape-up process for ongoing projects that continuously
            deliver new functionalities and capabilities to their customers.
          </b>
          <br></br>
          <br></br>
          There are 2 main workflows involved in the shape-up process, one is
          <span> shaping up work for the next iteration,</span> and the other is
          the actual <span>implementation.</span> Both of them happen in
          parallel.
          <br></br>
          <br></br>
          We work in{" "}
          <span> 4 to 6-week cycles, followed by a 1-week cool-down</span> (bug
          fixes and small tweaks/changes).
          <br></br>
          <br></br>
        </p>
        <h1>1. Feature pitch shaping</h1>
        <p>
          The purpose of the shaping process is to identify what work needs to
          be done, based on the project's goal and the user feedback, and to
          assess whether this is feasible.
        </p>
      </Title>

      <Section className="row">
        <LeftSide className="col">
          <TextWrapper>
            <h2>1.a Ideas and boundaries</h2>
            <br></br>
            <p>
              To deliver on time, the amount of time needs to remain fixed,
              while the scope can be flexible. Instead of starting with a
              definition of the feature and trying to work out the costs, we
              start with an appetite (the number of how many days we want to
              spend on it) and finish with a design and a feature set.
            </p>
          </TextWrapper>
        </LeftSide>
        <RightSide className="col showOnBig">
          <IlustrationContainer>
            <img src={UxPerspectiveImg} alt="discovery-workshop" />
          </IlustrationContainer>
        </RightSide>
      </Section>

      <Section style={{ marginTop: "-50px" }}>
        <CardsWrapper>
          <CardWithSquareDots data={Questionscard} />
          <CardWithSquareDots
            data={DrawingsCard}
            cardSstyle={{ background: "#12251A" }}
          />
        </CardsWrapper>
      </Section>
      <div className="showOnSmall">
        <Section style={{ justifyContent: "center" }}>
          <IlustrationContainer>
            <img src={UxPerspectiveImg} alt="discovery-workshop" />
          </IlustrationContainer>
        </Section>
      </div>

      <Section className="row" style={{ marginBottom: "0" }}>
        <LeftSide className="col">
          <TextWrapper>
            <h2>1.b Solution</h2>
            <br></br>
            <p>
              Based on the problem definition, and the amount of time we want to
              spend on it, we build an overview of the solution, without full
              details.
            </p>
          </TextWrapper>
          <CardWithSquareDots data={Questions1b} />
          <CardWithSquareDots
            data={DrawingsCardb}
            cardSstyle={{ background: "#12251A" }}
          />
        </LeftSide>
        <RightSide className="col showOnBig">
          <IlustrationContainer>
            <img src={solution1b} alt="solution1b"></img>
          </IlustrationContainer>
        </RightSide>
      </Section>

      <div className="showOnSmall">
        <Section className="row d-flex align-items-center justify-content-center">
          <BigIlustrationContainer className="col ">
            <img src={solution1bMobile} alt="solution1b"></img>
          </BigIlustrationContainer>
        </Section>
      </div>
      <Section className="row">
        <LeftSide className="col">
          <TextWrapper>
            <h2>1.c Rabbit holes</h2>
            <br></br>
            <p>
              In this part of the process, we try to assess the viability of the
              feature, how can it be done in the agreed timeframe, whether it
              presents any risks, and if so, how can we overcome them (from a
              high-level perspective).
            </p>
          </TextWrapper>
        </LeftSide>

        <RightSide className="col">
          <CardWithSquareDots data={RabbitHolesQuestions} />
        </RightSide>
      </Section>
    </Content>
  );
};

export default ProcessOngoingFeaturePitch;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  padding: 60px 0;
  @media (max-width: 990px) {
    padding: 0;
  }
`;
const Title = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  h1 {
    color: ${color.primaryDark};
    font-size: 48px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    max-width: 700px;
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    margin: 0;
  }
  span {
    // background: ${color.highlightedColor};
    // line-height: 36px;
  }
  @media (max-width: 990px) {
    row-gap: 20px;
    h1 {
      font-size: 30px;
      line-height: 33px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;
const Section = styled.div`
  display: flex;
  column-gap: 30px;
`;
const LeftSide = styled.div`
display:flex;
flex-direction:column;
row-gap:30px;
min-width:400px;
justify-content:center;
@media(max-width:500px){
    min-width:unset;
}
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    color: ${color.primaryDark};
    font-size: 30px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: -0.5px;
    margin: 0;
  }
  p {
    margin: 0;
    color: ${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
  }
  @media (max-width: 990px) {
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;
const RightSide = styled.div`
  display: flex;
  width: 100%;
  min-width: 400px;

  @media (max-width: 990px) {
    margin-top: 30px;
  }

  @media (max-width: 375px) {
    min-width: unset;
  }
`;
const IlustrationContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  position: relative;
  img {
    position: relative;
    width: 80%;
    max-height: 500px;
    max-width: 500px;
  }

  @media (max-width: 990px) {
    max-width: 500px;
      justify-content: center;
  }
`;
const BigIlustrationContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 250px;
  }
  @media (max-width: 990px) {
    max-width: 500px;
  }
`;
const CardsWrapper = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
  @media (max-width: 990px) {
    flex-direction: column;
  }
`;
