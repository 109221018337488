import React from "react";
import styled from "styled-components";

const Card = ({ text, color, background }) => {
    return (
        <CardWrapper color={color} background={background}>
            <p>{text}</p>
        </CardWrapper>
    );
};

const Domains = () => {
    let obj = [
        {
            text: "Mobile apps",
            color: "#1F1F1F",
            background: "#EFEFEB",
        },
        {
            text: "AI & ML",
            color: "#1F1F1F",
            background: "#E4F222",
        },
        {
            text: "Apple Car / Car Play apps",
            color: "white",
            background: "#12251A",
        },
        {
            text: "Web Apps",
            color: "#1F1F1F",
            background: "#EFEFEB",
        },
        {
            text: "UX / UI Design",
            color: "white",
            background: "#606054",
        },
    ];

    return (
        <Wrapper>
            <Group>
                <Card
                    text={obj[0].text}
                    color={obj[0].color}
                    background={obj[0].background}
                />
                <Card
                    text={obj[1].text}
                    color={obj[1].color}
                    background={obj[1].background}
                />
            </Group>
            <Group>
                <Card
                    text={obj[2].text}
                    color={obj[2].color}
                    background={obj[2].background}
                />
            </Group>
            <Group>
                <Card
                    text={obj[3].text}
                    color={obj[3].color}
                    background={obj[3].background}
                />
                <Card
                    text={obj[4].text}
                    color={obj[4].color}
                    background={obj[4].background}
                />
            </Group>


        </Wrapper>
    );
};

export default Domains;
const Wrapper = styled.div`
  display: flex;

    gap:60px;

  @media (max-width: 990px) {
    gap: 30px;
    flex-wrap:wrap;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;
  width: 100%;
  background: ${(props) => props.background};
  padding: 15px 30px;
  border-radius: 4px;
  min-width:30%;
  p {
    margin: 0;
    text-align: center;
    color: ${(props) => props.color};
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
  }
  @media (max-width:990px){
    p{
        font-size: 16px;
    }
   
  }
`;

const Group = styled.div`
  display: flex;
  column-gap: 60px;
  width:100%;
  @media(max-width:990px){
    column-gap: 30px;
  }
`;
