/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import "../../assets/styles/style.css";
import { color } from "../../assets/styles/colors";
import { getRandomPostsForPost } from "../../API/Blog/randomPostsForPost";
import ArticleCard from "../Articles/ArticleCard";
import { mappings } from "../../Helper/ConstantsValue";
import { ReactComponent as LinkedInIcon } from "../../assets/icons/blog-social-media-linkedin.svg";
import { ReactComponent as XIcon } from "../../assets/icons/blog-social-media-x.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/blog-social-media-mail.svg";
import { ReactComponent as FaceBookIcon } from "../../assets/icons/blog-social-media-facebook.svg";
import { getPostForId } from "../../API/Blog/getPostForId";
import moment from "moment";
import SpinnerComponent from "../Generic/SpinnerComponent";
import MetaTagsHandler from "../../Helper/MetaTagHandler";
import TrackingComponent from "../Tracking/TrackingComponent";

const BlogPostReader = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [randomPosts, setRandomPosts] = useState([]);
  const [currentPost, setCurrentPost] = useState(location.state);

  const handleGetRandomPosts = () => {
    if (!currentPost?.id) {
      return;
    }

    getRandomPostsForPost(
      currentPost?.id,
      function (response) {
        setRandomPosts(response.data.posts);
      },
      function (error) {
        console.log(error);
      }
    );
  };

  const checkMappedBlogId = () => {
    let foundId = mappings.find((item) => item.href === params.id);
    if (foundId) {
      return foundId.id;
    } else {
      return parseInt(params.id);
    }
  };

  const handleGetCurrentPost = () => {
    getPostForId(
      checkMappedBlogId(),
      function (response) {
        handleResponse(response.data.post);
      },
      function (error) {
        console.log(error);
      }
    );
  };

  const handleNavigateToPost = (id, data) => {
    navigate(`/blog/${id}`, { state: data });
    setCurrentPost(data);
  };

  const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html");
    handleImages(html);
    addTableOfContentsIDs(html);

    return html.body;
  };

  const handleImages = (html) => {
    var images = html.querySelectorAll("action-text-attachment");
    images.forEach((image) => {
      const link = image.getAttribute("href");
      const caption = image.getAttribute("caption");

      const divElement = document.createElement("div");
      divElement.className = "image-block";

      const captionElement = document.createElement("div");
      captionElement.className = "image-caption";
      captionElement.innerHTML = caption;

      const imageElement = document.createElement("img");
      imageElement.className = "blog-image";
      imageElement.setAttribute("src", link);

      divElement.appendChild(imageElement);
      divElement.appendChild(captionElement);

      image.parentNode.replaceChild(divElement, image);
    });
  };

  const addTableOfContentsIDs = (html) => {
    var headings = html.querySelectorAll("h2");

    headings.forEach((heading, index) => {
      heading.setAttribute("id", "heading-" + index.toString());
    });
  };

  const generateTableOfContents = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html");

    var headings = html.querySelectorAll("h2");

    const returnDiv = document.createElement("div");
    const divElement = document.createElement("div");
    divElement.className = "table-of-contents border-bottom-faded";

    returnDiv.appendChild(divElement);

    const divTitleElement = document.createElement("div");
    divTitleElement.className = "table-of-contents-title";
    divTitleElement.innerText = "CONTENTS";

    divElement.appendChild(divTitleElement);

    const ulElement = document.createElement("ul");
    ulElement.className = "table-of-contents-ul";
    divElement.appendChild(ulElement);

    headings.forEach((heading, index) => {
      heading.setAttribute("id", "heading-" + index.toString());

      if (heading.textContent != null) {
        const li = document.createElement("li");
        li.className = "table-of-contents-li";

        const a = document.createElement("a");
        a.className = "table-of-contents-a";
        a.setAttribute("href", "#heading-" + index.toString());
        a.innerText = heading.textContent;

        li.appendChild(a);
        ulElement.appendChild(li);
      }
    });

    return returnDiv;
  };

  const handleResponse = (response) => {
    if (response == null) {
      navigate("/blog");
    } else {
      setCurrentPost(response);
    }
  };

  useEffect(() => {
    if (!location.state) {
      handleGetCurrentPost();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetRandomPosts();
  }, [currentPost]);

  return (
    <>
      {currentPost ? (
        <Wrapper>
          <TrackingComponent />
          <MetaTagsHandler
            title={currentPost.title}
            description={currentPost.description}
            type={"article"}
            image={currentPost.cover}
          />

          <TopBg>
            <div className="container-1440 container-content">
              <Top>
                <TitleContainer>
                  <h2>
                    {moment(currentPost?.created_at).format("MMMM DD, YYYY")}
                  </h2>
                  <h1>{currentPost?.title}</h1>
                  <p>
                    {" "}
                    {currentPost?.category
                      ? currentPost?.category.toUpperCase()
                      : ""}{" "}
                    - {currentPost?.reading_time} MINUTES READ
                  </p>
                </TitleContainer>
                <ImgWrapper>
                  <img src={currentPost?.cover} alt="cover"></img>
                </ImgWrapper>
              </Top>
            </div>
          </TopBg>

          <div className="container-1440 container-content border-bottom-faded">
            <ContentWrapper>
              <LeftSide>
                <StickyWrapper>
                  <Content
                    dangerouslySetInnerHTML={{
                      __html: generateTableOfContents(currentPost?.content)
                        .innerHTML,
                    }}
                  ></Content>

                  <SocialMediaWrapper>
                    <ShareButton
                      name="x-share"
                      href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
                      target="_blank"
                    >
                      <XIcon />
                    </ShareButton>
                    <ShareButton
                      name="linkedin-share"
                      href={`http://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}/&title=&summary=&source=`}
                      target="_blank"
                    >
                      <LinkedInIcon />
                    </ShareButton>

                    <ShareButton
                      name="facebook-share"
                      href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                      target="_blank"
                    >
                      <FaceBookIcon />
                    </ShareButton>
                    <ShareButton
                      name="mail-share"
                      href={`mailto:?subject=&body=${window.location.href}`}
                      target="_blank"
                    >
                      <MailIcon />
                    </ShareButton>
                  </SocialMediaWrapper>
                </StickyWrapper>
              </LeftSide>

              <RightSide>
                <Content
                  dangerouslySetInnerHTML={{
                    __html: convertStringToHTML(currentPost?.content).innerHTML,
                  }}
                ></Content>
              </RightSide>
            </ContentWrapper>
          </div>

          <div className="container-1440 container-content position-relative">
            <ReadMore>
              <h2>Don’t miss these</h2>

              <ArticleWrapper>
                {randomPosts?.map((item, index) => (
                  <ArticleCard
                    key={index}
                    img={item.cover}
                    title={item.title}
                    readingTime={item.reading_time}
                    category={item.category}
                    onClick={() => handleNavigateToPost(item.id, item)}
                  />
                ))}
              </ArticleWrapper>
            </ReadMore>
          </div>
        </Wrapper>
      ) : (
        <SpinnerWrapper>
          <SpinnerComponent />
        </SpinnerWrapper>

      )}
    </>
  );
};

export default BlogPostReader;

const Wrapper = styled.div`
  img {
    width: 100%;
  }
`;
const Top = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 990px) {
    flex-direction: column;
    align-items: start;
    row-gap: 20px;
  }
`;
const Content = styled.div`
  div {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
  h1 {
    font-size: 40px;
    font-weight: 400;
    line-height: 60px;
  }
  h2 {
    margin-top: 20px;
    font-size: 40px;
    line-height: 60px;
    font-weight: 400;
  }
  h3 {
    margin-top: 15px;
    font-size: 30px;
    font-weight: 400;
  }
  li {
    font-size: 20px;
    line-height: 30px;
    margin-top: 25px;
  }
  strong {
    font-weight: 700;
  }
  blockquote {
    border-left: 4px solid #ccc;
    margin-left: 10px;
    padding-left: 20px;
  }

  .table-of-contents {
    padding-bottom: 40px !important;
  }

  .table-of-contents-title {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 20px;
    color: rgba(31, 31, 31, 0.6);
  }

  .table-of-contents-li {
    margin-top: 0px;
    margin-bottom: 8px;
  }

  .table-of-contents-a {
    text-decoration: none;
    color: rgba(31, 31, 31, 1);
  }
`;
const ContentWrapper = styled.div`
  display: flex;
  postition: relative;
  width: 100%;
    min-height:490px;
  @media (max-width: 990px) {
    flex-direction: column-reverse;
  }
`;

const ReadMore = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 42px;
  }
`;

const ArticleWrapper = styled.div`
  display: flex;
  gap: 1%;
  margin: 0 -20px 0 -20px;
  width: 100%;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

const TitleContainer = styled.div`
  diplay: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 600px;
  width: 100%;

  h1 {
    font-size: 48px;
    font-weight: 400;
    color: ${color.primaryDark};
  }

  p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #4f4c4c;
  }

  h2 {
    color: rgba(31, 31, 31, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  h2 {
    color: rgba(31, 31, 31, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  @media (max-width: 990px) {
    h1 {
      font-size: 36px;
      line-height: 36px;
    }
  }
`;
const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  img {
    border-radius: 8px;
  }
`;
const TopBg = styled.div`
  background: #f4f3ef;
  min-height:490px;
`;
const LeftSide = styled.div`
  width: 40%;
  margin-right: 5%;

  @media (max-width: 990px) {
    margin-top: 20px;
    width: unset;
  }
`;
const RightSide = styled.div`
  display: flex;
  width: 100%;
`;

const SocialMediaWrapper = styled.div`
  display: flex;
  column-gap: 30px;
  margin-top: 30px;
`;
const ShareButton = styled.a`
  display: flex;
  align-items: center;
`;

const StickyWrapper = styled.div`
  position: sticky;
  top: 75px;
`;

const SpinnerWrapper = styled.div`
display:flex;
align-items:center;
min-height:50vh;
`