import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";
import { useNavigate } from "react-router-dom";

const ProcessPageSelector = () => {
  const [selectedOption, setSelectedOption] = useState("/process");
  const navigate = useNavigate();

  const scrollRef = useRef();
  const scrollToView = (ref) => {
    ref.current.scrollIntoView();
  }

  const options = [
    {
      link: "/process",
      text: "New Products",
    },
    {
      link: "/process/existing",
      text: "Existing Products",
    },
    {
      link: "/process/ongoing",
      text: "Ongoing product development",
    },
  ];
  const handleSelectOption = (option) => {
    setSelectedOption(option);
    navigate(option);
    scrollToView(scrollRef);
  };

  useEffect(() => {
    setSelectedOption(window.location.pathname);
  }, []);


  return (
    <Container >
      {options.map((option, index) => (
        <OptionContainer
          ref={scrollRef}
          key={index}
          selected={option.link === selectedOption}
          onClick={(e) => handleSelectOption(option.link)}
        >
          <p>{option.text}</p>
        </OptionContainer>
      ))}
    </Container>
  );
};

export default ProcessPageSelector;

const Container = styled.div`
  background: #1f1f1f;
  border-radius: 8px;
  width: fit-content;
  padding: 5px;
  display: flex;
  column-gap: 40px;
  margin-bottom: 20px;
`;
const OptionContainer = styled.div`
  display: flex;
  background: ${(props) => props.selected && "white"};
  color: black;
  padding: 12px 21px;
  border-radius: 6px;
  cursor: pointer;
  transition: 200ms ease-in-out;

  p {
    width: 100%;

    margin: 0;
    color: ${(props) =>
    props.selected ? color.primaryDark : color.primaryLight};
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.5px;
  }
`;
