import React from "react";
import styled from "styled-components";
import WorkPageFold1 from "../components/WorkPageComponents/WorkPageFold1";
import { WorkPageFold2 } from "../components/WorkPageComponents/WorkPageFold2";
import { WorkPageFold3 } from "../components/WorkPageComponents/WorkPageFold3";
import RulesFold from "../components/WorkPageComponents/RulesFold";
import TrackingComponent from "../components/Tracking/TrackingComponent";

const ApproachPage = () => {
  return (
    <WorkPageWrapper>
      <WorkPageFold1 />
      <WorkPageFold2 />
      <WorkPageFold3 />
      <RulesFold />
      <TrackingComponent />
    </WorkPageWrapper>
  );
};

export default ApproachPage;
const WorkPageWrapper = styled.div`
  min-height: 100vh;
`;
