import React, { useState } from "react";
import styled from "styled-components";
import TrackingComponent from "../components/Tracking/TrackingComponent";

const PrivacyPolicyPage = () => {
  useState(() => {});
  return (
    <Wrapper>
      <Wrapper id="about-fold1">
        <div className="container-1440 container-content position-relative">
          <Content>
            <div className="row">
              <Text>
                <h1>Privacy Policy</h1>
                <p>
                  For general questions, drop us a line at{" "}
                  <a href="mailto: office@appssemble.com">
                    office@appssemble.com
                  </a>
                  .
                </p>

                <p>Effective date: October 06, 2018</p>

                <p>
                  APPSSEMBLE-SOFT SRL ("us", "we", or "our") operates the
                  website and the appssemble mobile application (the "Service").
                  This page informs you of our policies regarding the
                  collection, use, and disclosure of personal data when you use
                  our Service and the choices you have associated with that
                  data. We use your data to provide and improve the Service. By
                  using the Service, you agree to the collection and use of
                  information in accordance with this policy. Unless otherwise
                  defined in this Privacy Policy, terms used in this Privacy
                  Policy have the same meanings as in our Terms and Conditions.
                </p>

                <h2>Information Collection And Use</h2>

                <p>
                  We collect several different types of information for various
                  purposes to provide and improve our Service to you.
                </p>

                <h3>Types of Data Collected</h3>

                <h4>Personal Data</h4>

                <p>
                  While using our Service, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you ("Personal Data"). Personally
                  identifiable information is limited to: Email address.
                </p>

                <h4>Usage Data</h4>

                <p>
                  We do not collect any other data from your device sensors
                  (such as camera, microphone, screens). None of the actions in
                  our mobile application are tracked, recorded or transmited
                  over the internet.{" "}
                </p>

                <h4>Use of Data</h4>

                <p>
                  APPSSEMBLE-SOFT SRL uses the collected data for various
                  purposes:
                  <ul>
                    <li>To provide customer care and support</li>
                    <li>To be able to answer to customers inquiries </li>
                  </ul>
                </p>

                <h4>Transfer Of Data</h4>

                <p>
                  Your information, including Personal Data, may be transferred
                  to and maintained on computers located outside of your state,
                  province, country or other governmental jurisdiction where the
                  data protection laws may differ than those from your
                  jurisdiction. If you are located outside Romania and choose to
                  provide information to us, please note that we transfer the
                  data, including Personal Data, to Romania and process it
                  there. Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer. APPSSEMBLE-SOFT SRL will take all steps
                  reasonably necessary to ensure that your data is treated
                  securely and in accordance with this Privacy Policy and no
                  transfer of your Personal Data will take place to an
                  organization or a country unless there are adequate controls
                  in place including the security of your data and other
                  personal information.
                </p>

                <h2>Disclosure Of Data</h2>

                <h3>Legal Requirementsd</h3>

                <p>
                  APPSSEMBLE-SOFT SRL may disclose your Personal Data in the
                  good faith belief that such action is necessary to: To comply
                  with a legal obligation To protect and defend the rights or
                  property of APPSSEMBLE-SOFT SRL To prevent or investigate
                  possible wrongdoing in connection with the Service To protect
                  the personal safety of users of the Service or the public To
                  protect against legal liability.
                </p>

                <h3>Security Of Data</h3>

                <p>
                  The security of your data is important to us, but remember
                  that no method of transmission over the Internet, or method of
                  electronic storage is 100% secure. While we strive to use
                  commercially acceptable means to protect your Personal Data,
                  we cannot guarantee its absolute security.
                </p>

                <h3>Service Providers</h3>

                <p>
                  We may employ third party companies and individuals to
                  facilitate our Service ("Service Providers"), to provide the
                  Service on our behalf, to perform Service-related services or
                  to assist us in analyzing how our Service is used. These third
                  parties have access to your Personal Data only to perform
                  these tasks on our behalf and are obligated not to disclose or
                  use it for any other purpose.
                </p>

                <h3>Links To Other Sites</h3>

                <p>
                  Our Service may contain links to other sites that are not
                  operated by us. If you click on a third party link, you will
                  be directed to that third party's site. We strongly advise you
                  to review the Privacy Policy of every site you visit. We have
                  no control over and assume no responsibility for the content,
                  privacy policies or practices of any third party sites or
                  services.{" "}
                </p>

                <h2>Children's Privacy</h2>

                <p>
                  Our Service does not address anyone under the age of 18
                  ("Children"). We do not knowingly collect personally
                  identifiable information from anyone under the age of 18. If
                  you are a parent or guardian and you are aware that your
                  Children has provided us with Personal Data, please contact
                  us. If we become aware that we have collected Personal Data
                  from children without verification of parental consent, we
                  take steps to remove that information from our servers.
                </p>

                <h2>Changes To This Privacy Policy</h2>

                <p>
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page. We will let you know via email and/or a prominent
                  notice on our Service, prior to the change becoming effective
                  and update the "effective date" at the top of this Privacy
                  Policy. You are advised to review this Privacy Policy
                  periodically for any changes. Changes to this Privacy Policy
                  are effective when they are posted on this page.
                </p>
              </Text>
            </div>
          </Content>
        </div>
      </Wrapper>
      <TrackingComponent />
    </Wrapper>
  );
};

export default PrivacyPolicyPage;
const Wrapper = styled.div``;
const Content = styled.div`
  min-height: 250px;
  @media (max-width: 600px) {
    min-height: unset;
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 50px;
  z-index: 10;

  h1 {
    color: rgba(31, 31, 31, 0.9);
    margin: 0;
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
  }

  h2 {
    color: rgba(31, 31, 31, 0.9);
    margin: 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
  }
  h3 {
    color: rgba(31, 31, 31, 0.9);
    margin: 0;
    font-size: 26px;
    font-weight: 700;
    line-height: 35px;
  }
  h4 {
    color: rgba(31, 31, 31, 0.9);
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }
  li {
    margin-top: 12px;
  }
  p {
    color: rgba(31, 31, 31, 0.9);
    margin: 0;

    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  @media (max-width: 990px) {
    max-width: unset;
    row-gap: 30px;
    h1 {
      font-size: 30px;
      line-height: 39px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;
