import React from "react";
import styled from "styled-components";
import ServicesFold1 from "../components/ServicesPageComponents/ServicesFold1";
import ServicesWeWorkWith from "../components/ServicesPageComponents/ServicesWeWorkWith";
import OurProcess from "../components/ServicesPageComponents/OurProcess";
import Reviews from "../components/Reviews/Reviews";
import TrackingComponent from "../components/Tracking/TrackingComponent";

const ServicesPage = () => {
  return (
    <Wrapper>
      <ServicesFold1 />
      <ServicesWeWorkWith />
      <OurProcess />
      <Reviews />
      <TrackingComponent />
    </Wrapper>
  );
};

export default ServicesPage;
const Wrapper = styled.div`
  min-height: 100vh;
`;
