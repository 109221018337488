import React from "react";
import styled from "styled-components";
import CareersFold1 from "../components/Careers/CareersFold1";
import CareersOpenPosition from "../components/Careers/CareersOpenPosition";
import TrackingComponent from "../components/Tracking/TrackingComponent";

const JobsPage = () => {
  return (
    <Wrapper>
      <CareersFold1 />
      <CareersOpenPosition />
      <TrackingComponent />
    </Wrapper>
  );
};

export default JobsPage;
const Wrapper = styled.div`
  min-height: 100vh;
`;
