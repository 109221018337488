import React from "react";
import styled from "styled-components";
import { ReactComponent as BoschLogoWhite } from "../../assets/icons/trusted-by-bosch-logo-white.svg";
import { ReactComponent as HondaLogoWhite } from "../../assets/icons/trusted-by-honda-log-white.svg";
import { ReactComponent as WikiLogoWhite } from "../../assets/icons/trusted-by-wikipedia-logo-white.svg";
import { ReactComponent as DeloitteLogoWhite } from "../../assets/icons/trusted-by-deloitte-logo-white.svg";
import { animated, useSpring } from "@react-spring/web";
const Element = () => {
  const infiniteScroll = useSpring({
    from: {
      transform:
        "translate3d(0%,0px,0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
      transformStyle: "preserve-3d",
      willChange: "transform",
    },
    to: {
      transform:
        "translate3d(-100%,0px,0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
      transformStyle: "preserve-3d",
      willChange: "transform",
    },
    loop: true,
    config: {
      duration: "10000",
    },
  });

  return (
    <ElementWrapper as={animated.div} style={infiniteScroll}>
      <Logo>
        <BoschLogoWhite />
      </Logo>
      <Logo>
        <HondaLogoWhite />
      </Logo>
      <Logo>
        <WikiLogoWhite />
      </Logo>
      <Logo>
        <DeloitteLogoWhite />
      </Logo>
    </ElementWrapper>
  );
};

const BlackHorizontalScroller = () => {
  return (
    <TrustedBy>
      <p>Trusted by</p>
      <BlackWrapper>
        <InnerScroller>
          <Element />
          <Element />
        </InnerScroller>
      </BlackWrapper>
    </TrustedBy>
  );
};

export default BlackHorizontalScroller;

const TrustedBy = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-right: 1px solid #3131310d;
  margin-top: 30px;
  p {
    font-size: 14px !important;
    color: #1e1e1eb2;
    margin: 0;
    padding: 0 20px;
  }
`;

const BlackWrapper = styled.div`
  background: #12251a;
  max-width: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
`;

const InnerScroller = styled.div`
  display: flex;
  width: 300%;
`;

const ElementWrapper = styled.div`
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-right: 0;
  display: flex;
`;

const Logo = styled.div`
  height: auto;
  max-width: none;
  margin: 0 0vw;
  display: inline-block;
`;
