import React from "react";
import styled from "styled-components";
import "../../../assets/styles/style.css";

import AnimatedTextButton from "../../Generic/AnimatedTextButton";

import "swiper/css";
import "swiper/css/pagination";
import "../../../assets/styles/style.css";

import { color } from "../../../assets/styles/colors";
import { useNavigate } from "react-router-dom";
import TrustedByCompanies from "../../Generic/TrustedByCompanies";
import BlackHorizontalScroller from "../../Generic/BlackHorizontalScroller";

import { ReactComponent as AppStoreLogo } from "../../../assets/icons/appStore-logo.svg";
import { ReactComponent as GooglePlayLogo } from "../../../assets/icons/googlePlay-logo.svg";
import ClutchLogoComponent from "../../Generic/ClutchLogoComponent";

const Fold1 = () => {
  const navigate = useNavigate();

  const navigateToClutch = (e) => {
    window.open("https://clutch.co/profile/appssemble#reviews");
  };

  return (
    <Wrapper id="fold1">
      <div className="container-1440 container-content">
        <Top>
          <h1>
            Mobile and web apps for scale-ups, small and medium-sized
            enterprises.
          </h1>
          <h2>
            <span>We sell digital solutions, not headcounts.</span> Our team
            works in <span>small, self-sufficient teams</span> with companies
            that prioritize speed, helping them deliver digital products{" "}
            <span>fast.</span>
          </h2>
          <AnimatedTextButton
            onClick={(e) => navigate("/approach")}
            text="More about our approach"
          />
        </Top>
        <div className="showOnBig">
          <TrustedByCompanies />
        </div>
      </div>

      <div className="container-1440 showOnSmall">
        <SmallTopFeaturedWrapper>
          <TopFeaturedGroup>
            <TopCompany>
              <p>Top development company</p>
              <Group>
                <ClutchLogoComponent onClick={navigateToClutch} />
              </Group>
            </TopCompany>
            <Featured>
              <p>Featured</p>
              <Group>
                <AppStoreLogo />
                <GooglePlayLogo />
              </Group>
            </Featured>
          </TopFeaturedGroup>
        </SmallTopFeaturedWrapper>
      </div>

      <div className="showOnSmall">
        <BlackHorizontalScroller />
      </div>
    </Wrapper>
  );
};

export default Fold1;

const Wrapper = styled.div`
  background: white;
  overflow: hidden;
  h1 {
    font-size: 50px;
    line-height: 65px;
    font-weight: 700;
  }
  h2 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 400;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
  }
  span {
    background: ${color.highlightedColor};
    padding: 0 2px;
  }
  @media (max-width: 990px) {
    h1 {
      font-size: 30px;
      line-height: 39px;
    }
    h2 {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  max-width: 940px;

  @media (max-width: 990px) {
    row-gap: 20px;
  }
`;
const TrustedBy = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: space-between;
  border-right: 1px solid #3131310d;
  padding-right: 20px;
  p {
    font-size: 14px;
    color: #1e1e1eb2;
    margin: 0;
  }
`;
const Group = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  gap: 60px;
  align-items: center;

  @media (max-width: 990px) {
    gap: 20px;
    margin-top: 10px;
  }
  @media (max-width: 450px) {
    svg {
      width: 100%;
    }
  }
`;
const TopCompany = styled(TrustedBy)`
  min-width: 150px !important;
`;
const Featured = styled(TrustedBy)`
  border-right: none;
`;
const TopFeaturedGroup = styled.div`
  display: flex;
  gap: 20px;
`;

const SmallTopFeaturedWrapper = styled.div`
  padding: 0 0px;
`;
