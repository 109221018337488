import React from "react";
import styled from "styled-components";

import Fold4 from "../components/LandingPageComponents/WeWorkWith/WeWorkWith";
import Fold5 from "../components/LandingPageComponents/Fold5/Fold5";
import Reviews from "../components/Reviews/Reviews";
import HowWork from "../components/HowWork/HowWork";
import Articles from "../components/Articles/Articles";
import Fold1 from "../components/LandingPageComponents/Fold1/Fold1";
import Fold2 from "../components/LandingPageComponents/Fold2/Fold2";
import Fold3 from "../components/LandingPageComponents/Fold3/Fold3";
import TrackingComponent from "../components/Tracking/TrackingComponent";

const LandingPage = () => {
  return (
    <Wrapper>
      <Fold1 />
      <Fold2 />
      <Fold3 />
      <Fold4 />
      <Fold5 />
      <Reviews />
      <HowWork />
      <Articles />
      <TrackingComponent />
    </Wrapper>
  );
};

export default LandingPage;

const Wrapper = styled.div`
  min-height: 100vh;
`;
