import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';
import "../../assets/styles/style.css"
function SpinnerComponent({ children }) {
    return (
        <Wrapper>
            <Spinner animation="border" role="status" className='custom-spinner'>
            </Spinner>
        </Wrapper>
    );
}

export default SpinnerComponent;

const Wrapper = styled.div`
postition:absolute;
top:0;
right:0;
left:0;
bottom:0;
width:100%;
display:flex;
align-items:center;
justify-content:center;
z-index:9999;
`