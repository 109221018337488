import React from "react";
import styled from "styled-components";
import { ReactComponent as AppssembleLogo } from "../../assets/icons/appssemble-logo-white-big.svg";
import { ReactComponent as AppssembleLogoBg } from "../../assets/icons/logo-white-background.svg";
const AboutUsFold1 = () => {
  return (
    <Wrapper id="about-fold1">
      <div className="container-1440 container-content border-green-bottom position-relative">
        <Content>
          <div className="row">
            <div className="col-sm-12 col-md-9 ">
              <Text>
                <h1>Where we came from, where we're at.</h1>
                <p>
                  Born out of a desire to create better software, faster, with
                  less hassle and more time for what we love - building digital
                  products.
                </p>
              </Text>
            </div>
            <LogoContainerBig className="showOnBig col-3">
              <div className="col d-flex justify-content-center ">
                <AppssembleLogo />
              </div>
            </LogoContainerBig>
            <div className="showOnSmall">
              <SmallScreenBg>
                <AppssembleLogoBg />
              </SmallScreenBg>
            </div>
          </div>
        </Content>
      </div>
    </Wrapper>
  );
};

export default AboutUsFold1;
const Wrapper = styled.div``;
const Content = styled.div`
  min-height: 250px;
  @media (max-width: 600px) {
    min-height: unset;
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 50px;
  z-index: 10;

  h1 {
    color: #ffffff;
    margin: 0;
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
  }
  p {
    color: #ffffff;
    margin: 0;

    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
  }

  @media (max-width: 990px) {
    max-width: unset;
    row-gap: 30px;
    h1 {
      font-size: 30px;
      line-height: 39px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
`;

const SmallScreenBg = styled.div`
  position: absolute;
  right: 30px;
  top: 50px;
  bottom: 0;
  opacity: 0.5;
  magrgin-left: auto;
`;

const LogoContainerBig = styled.div`
  display: flex;
`;
