import React from 'react'
import styled from 'styled-components'
import { color } from '../../assets/styles/colors'

const ProjectCard = ({ img, title, description, navigateTo }) => {

    return (
        <CardContainer href={navigateTo} >
            <Card>
                <ImgContainer>
                    <img src={img} alt="st" />
                </ImgContainer>
                <TextContainer>
                    <h3>{title}</h3> <p>-</p> <p>{description}</p>
                </TextContainer>
            </Card>

        </CardContainer>
    )
}

export default ProjectCard
const CardContainer = styled.a
    `
text-decoration:none;
`
const Card = styled.div`
display:flex;
cursor:pointer;
flex-direction:column;
row-gap:20px;
padding:10px;
transition: 400ms all ease-out;
border-radius:8px;
&:hover {
    transition: 400ms all ease-out;
    -webkit-box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    -moz-box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    box-shadow: 0px 0px 20px 10px rgba(31, 31, 31, 0.1);
    img {
        
      transform: scale(1.07);
    }
  }
`
const ImgContainer = styled.div`
display:flex;
border-radius:8px;
position:relative;
justify-content:center;
overflow:hidden;
max-height:368px;
min-height:368px;
img{
object-fit:cover;
width:100%;
transition: 400ms all ease-out;
}
@media(max-width:700px){
    min-height:unset;
}
`
const TextContainer = styled.div`
display:flex;
flex-wrap:wrap;
align-items:start;
column-gap:5px;
h3{
corlor:${color.primaryDark};
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    margin:0;
}

p{
    color:${color.primaryDark};
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    margin:0;
}
@media(max-width:990px){
    h3{
 font-size: 18px;
 line-height: 27px;
    }
    p{
 font-size: 18px;
 line-height: 27px;
    }
}
`