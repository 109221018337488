import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownArrow } from "../../assets/icons/down-arrow-rules-dropdown.svg";

const RuleDropdown = ({ rule, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownElement>
      <RuleElement onClick={() => setIsOpen(!isOpen)}>
        <h3>{rule}</h3>
        <DownArrow
          style={
            isOpen
              ? { transform: "scaleY(-1)", transition: "200ms ease" }
              : { transition: "200ms ease" }
          }
        />
      </RuleElement>
      {isOpen && <RuleDetails>{description}</RuleDetails>}
    </DropdownElement>
  );
};

export default RuleDropdown;
const DropdownElement = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-bottom: 1px solid rgba(49, 49, 49, 0.05);
  margin-top: 20px;
  padding-bottom: 20px;
`;
const RuleElement = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
  }
`;
const RuleDetails = styled.div`
  transition: 200ms ease;
  p {
    color: rgba(31, 31, 31, 0.7);
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
`;
