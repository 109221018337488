import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/pagination";
import "../../assets/styles/style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import ReviewCard from "./ReviewCard";
import { ReactComponent as CarouselArrow } from "../../assets/icons/carousel-arrow-right.svg";
import { color } from "../../assets/styles/colors.js";
import AnimatedTextButton from "../Generic/AnimatedTextButton";
const reviews = [
  {
    review: "The team at appssemble met our expectations and deadlines.",
    author: "Jimmy Wales",
    business: " CEO, Wikipedia & Wikitribune ",
  },
  {
    review:
      "They stand out for their response rate, close collaboration, and flexibility.",
    author: "Maxime Leroux",
    business: "Founder, OneSave/Day",
  },
  {
    review:
      "I commend their consistency and the fact that their budget estimations are always accurate.",
    author: "Mihai Motocu",
    business: "CTO, Streamaxia",
  },
  {
    review: "The quality of the work was exactly what I paid for.",
    author: "Pete Christianson",
    business: "Managing Partner, Smalk Works",
  },
  {
    review: "We’re particularly impressed with appssemble’s integrity.",
    author: "Clifford Midega",
    business: "Director, Fitness Company",
  },
  {
    review: "They work very fast and have many ideas.",
    author: "Manager, Dent Gold Care SRL",
    business: "Director, Andrei Petrut",
  },
  {
    review:
      "... can develop what they claim from a technical perspective, and they reply quickly to any concern or request. ",
    author: "Founder & CEO, TIME Platforms Ltd.",
    business: "Pantelis Grigoriou",
  },
  {
    review: "Their team was accessible, engaged, and devoted to their work.",
    author: "Anonymous",
    business: "Co-Founder, Private Medical Practice",
  },
  {
    review:
      "They’ve been great at communicating progress and understanding what I need.",
    author: "Anonymous",
    business: "Director, Car Mats Retailer",
  },
  {
    review:
      "They have amazing communication skills and understood our vision for the project very well.",
    author: "Anonymous",
    business: "Director, Crowdfunding Platform",
  },
];

const Reviews = () => {
  let initialClass = "container-1440 container-content";

  if (window.location.pathname !== "/services") {
    initialClass += " border-bottom-faded";
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slides, setSlides] = useState(3);
  const [backDisabled, setBackDisabled] = useState(true);
  const [nextDisabled, setNextkDisabled] = useState(false);

  const swipeNext = () => {
    let swiper = document.querySelector("#review-swiper").swiper;
    swiper.slideNext();
  };
  const swipeBack = () => {
    let swiper = document.querySelector("#review-swiper").swiper;
    swiper.slidePrev();
  };
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleNavigate = () => {
    window.open("https://clutch.co/profile/appssemble#reviews ", "_blank");
  };

  const handleSlideChange = (event) => {
    const { isBeginning, isEnd } = event;
    if (isBeginning) {
      setBackDisabled(true);
    } else {
      setBackDisabled(false);
    }
    if (isEnd) {
      setNextkDisabled(true);
    } else {
      setNextkDisabled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 990) {
      setSlides(3);
    }
    if (windowWidth < 990) {
      setSlides(2);
    }
    if (windowWidth < 650) {
      setSlides(1);
    }
  }, [windowWidth]);

  return (
    <Wrapper>
      <div className={initialClass}>
        <Content>
          <Top>
            <h1>Reviews</h1>
            <CarouselArrows>
              <button
                disabled={backDisabled}
                name="reveiws-back-btn"
                id="reveiws-back-btn"
                aria-label="reveiws-back-btn"
                onClick={() => swipeBack()}
              >
                <CarouselArrow style={{ rotate: "180deg" }} />
              </button>

              <button
                disabled={nextDisabled}
                name="reveiws-next-btn"
                id="reveiws-next-btn"
                aria-label="reveiws-next-btn"
                onClick={() => swipeNext()}
              >
                <CarouselArrow />
              </button>
            </CarouselArrows>
          </Top>
          <ReviewsWrapper>
            <Swiper
              onSlideChange={(swiperCore) => handleSlideChange(swiperCore)}
              slidesPerView={slides}
              spaceBetween={40}
              centeredSlides={false}
              pagination={windowWidth < 990}
              modules={[Pagination, Navigation]}
              className="mySwiper pag"
              id="review-swiper"
            >
              {reviews.map((item, index) => (
                <SwiperSlide key={item.author}>
                  <ReviewCard
                    key={index + item.author}
                    review={item.review}
                    author={item.author}
                    business={item.business}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </ReviewsWrapper>
          <ActionsContainer>
            <AnimatedButtonWrapper>
              <AnimatedTextButton
                text={"Read more verified reviews"}
                onClick={() => handleNavigate()}
              />
            </AnimatedButtonWrapper>
            {window.location.href.indexOf("services") > -1 && (
              <div className="showOnBig">
                <GetInTouchButton>Get in touch</GetInTouchButton>
              </div>
            )}
          </ActionsContainer>
        </Content>
      </div>
    </Wrapper>
  );
};

export default Reviews;

const ActionsContainer = styled.div`
  background: white;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  justify-content: space-between;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    font-size: 48px;
    font-weight: 400;
    letter-spacing: -0.5px;
    margin: 0;
  }
  @media (max-width: 990px) {
    h1 {
      font-size: 30px;
    }
  }
`;
const ReviewsWrapper = styled.div`
  margin-top: 40px;
`;
const CarouselArrows = styled.div`
  display: flex;
  align-items: center;
  column-gap: 40px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    width: 21px;
    height: 16px;
    &:disabled {
      opacity: 0.2;
    }
  }
  svg {
    stroke: ${color.primaryDark};
  }

  @media (max-width: 990px) {
    display: none;
  }
`;
const GetInTouchButton = styled.button`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 4px;
  background: #e4f222;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 15px 105px;
  margin-top: 50px;
`;
const AnimatedButtonWrapper = styled.div`
  margin-top: 40px;
`;
