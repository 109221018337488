import React from "react";
import styled from "styled-components";
import { color } from "../../assets/styles/colors";

const ReviewCard = ({ review, author, business }) => {
  return (
    <Card>
      <Description>
        <p>{review}</p>
      </Description>
      <Separator />
      <Author>
        <h2>{author}</h2>
        <h3>{business}</h3>
      </Author>
    </Card>
  );
};

export default ReviewCard;

const Card = styled.div`
  display: flex;
  padding: 60px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border-radius: 4px;
  background: #fcfbfa;
`;
const Description = styled.div`
  margin-bottom: 20px;

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin: 0;
    color: ${color.primaryDark};
  }
`;
const Author = styled.div`
  margin-top: 20px;
  h2 {
    color: rgba(49, 49, 49, 0.7);
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
  }
  h3 {
    color: rgba(49, 49, 49, 0.7);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
`;
const Separator = styled.div`
  height: 3px;
  background: #e4f222;
  width: 200px;
`;
