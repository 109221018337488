import React from "react";
import styled from "styled-components";

export const WorkPageFold2 = () => {
  return (
    <Wrapper>
      <div className="container-1440 container-content border-bottom-faded position-relative">
        <Content>
          <Cycles>
            <h1>Cycles, not sprints</h1>
            <h2>
              Agile sprints are too short to deliver anything meaningful, 2
              weeks is not enough to ramp up, design, implement, and deliver a
              new feature. If we add the Scrum ceremonies into the mix, and sync
              meetings, we're looking at around 5 days of implementation time.
            </h2>
          </Cycles>
          <Text>
            <p>
              Instead,{" "}
              <span>
                we're using 4 to 6-week cycles, followed by a 1-week cool-down.
              </span>{" "}
              This allows us to focus on the things that truly matter, building
              good products, fast.
              <br></br>
              <br></br>
              The cool-down cycle is used for design, development and testing,
              while the cool-down periods are used for client demos, scope
              hammering, POC or bug fixes, and change requests received from the
              clients after we showcase the work.
            </p>
          </Text>
        </Content>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #12251a;
`;
const Content = styled.div`
  display: flex;
  column-gap: 60px;
  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    color: #fff;
  }
  h2 {
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
  }
  p {
    text-wrap: wrap;
    margin: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: #fff;
  }
  @media (max-width: 990px) {
    flex-direction: column;
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 18px;
    }
    p {
      margin-top: 30px;
      font-size: 18px;
    }
  }
`;
const Cycles = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  max-width: 400px;
  row-gap: 40px;

  @media (max-width: 990px) {
    row-gap: 30px;
    min-width: unset;
    max-width: unset;
  }
  @media (max-width: 500px) {
  }
`;
const Text = styled.div`
  span {
    padding-left: 5px;
    padding-right: 5px;
    background: #e4f222;
  }
`;
