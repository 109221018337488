
import styled from 'styled-components'
import { ReactComponent as Rectangle } from "../../../assets/icons/rectangle-green.svg"


const NonTechComp = () => {


    return (
        <Card>
            <h1>Non-tech companies</h1>
            <ElementWrapper>
                <RectangleWrapper>
                    <Rectangle />
                </RectangleWrapper>
                <p>Need a digital transformation</p>
            </ElementWrapper>
            <ElementWrapper>
                <RectangleWrapper>
                    <Rectangle />
                </RectangleWrapper>
                <p>Don't have in-house experts for developing tech-products</p>
            </ElementWrapper>
            <ElementWrapper>
                <RectangleWrapper>
                    <Rectangle />
                </RectangleWrapper>
                <p>Want to improve their internal workflows or offer customers new digital capabilities</p>
            </ElementWrapper>



        </Card>
    )
}

export default NonTechComp
const Card = styled.div`
display:flex;
flex-direction:column;
row-gap:20px;
border-radius: 8px;
background: #12251A;
padding: 50px 45px;
min-width:390px;

min-height:412px;
height:100%;
h1{
    color:white;
    font-size: 24px;
font-weight: 700;
line-height: normal;
letter-spacing: -0.5px;
margin-bottom:15px;
}
p{
    color: rgba(255, 255, 255, 0.90);
   font-size: 20px;
font-style: normal;
line-height:26px;
font-weight: 400;
margin:0;
}
@media(max-width:990px){
    padding: 33px 35px;
    min-height:357px;
    min-width:300px;
    h1{
        font-size: 16px;
    }
    p{
        font-size: 16px;
        line-height:21px;
    }
   
}
@media(max-width:375px){
    min-width:unset;
    padding: 25px;
}
`
const ElementWrapper = styled.div`
display:flex;
column-gap:16px;
align-items:start;
padding-left:10px;
max-width:450px;
svg{
    min-height:12px;
    min-width:12px;
}
span{
    color:rgba(255, 255, 255, 0.40);
}
`
const RectangleWrapper = styled.div`
margin-top:2px;
`